@import 'assets/styles/_colors.scss';
@import 'assets/styles/_metrics.scss';

.error-message {
  color: $red-soft !important;
}

.ui.form.auth-form .validation-message.success {
  color: $green;
}
