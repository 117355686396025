.table-action-menu {
  .ui.button .iconify {
    height: 1.25rem;
    width: 1.25rem;

    path {
      height: inherit;
      width: inherit;
      font-size: inherit;
    }
  }
}

.ui.popup.tooltip.user-tooltip {
  padding: 1rem;
  .content {
    padding-top: 0;
    min-width: 14rem;
    .person {
      position: relative;
      left: -0.5rem;
      .person-icon {
        position: relative;
        top: 0.2rem;
        font-size: 2.2rem;
      }
      .bold-person {
        position: relative;
        top: -1rem;
        left: 0.1rem;
        font-size: 1rem;
        font-weight: 600;
      }
      .date {
        position: absolute;
        left: 3.6rem;
        top: 1.4rem;
        font-size: 0.8rem;
        font-weight: 600;
      }
    }
  }
}
