.mcq-choices {
  display: flex;
  .mcq-choices-left {
    width: 50%;
  }
}

.parameters-questions-clear-data.mcq {
  margin-top: 0;
}
