@import 'assets/styles/_colors.scss';
@import 'assets/styles/_metrics.scss';

.ui.form.auth-form {
  width: 31.429rem;
  border: 0.0714rem solid $grayish-magenta-light;
  border-radius: 1.1429rem;

  .required {
    top: 0;
    left: 0.1429rem;
  }

  .ui.input.input-error-field input:focus {
    background-color: $red-very-pale;
    border: 0.0714rem solid $red-soft;
  }

  .validation-message {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 0.714rem;
    line-height: 140%;
    display: flex;
    align-items: center;
    color: $orange;
    position: relative;
    top: 0.286rem;
    svg {
      position: relative;
      top: -0.0714rem;
      margin-right: 0.286rem;
    }
    &[success='true'] {
      color: $green;
    }
  }

  .triangle-check {
    position: relative;
    top: -0.0714rem;
    margin-right: 0.286rem;
  }

  .create-password {
    margin-top: -0.0714rem;
  }

  .info-icon {
    color: $orange;
    margin-left: 0.2407rem;
    position: relative;
    top: -0.0714rem;
    width: 1.1429rem;
    height: 1.1429rem;
  }

  .content {
    padding-left: 2.857rem;
    padding-top: 2.2857rem;
    padding-bottom: 2.2857rem;
    padding-right: 2.857rem;
  }
  .header {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 2.857rem;
    line-height: 130%;
    display: flex;
    align-items: center;
    letter-spacing: -0.01em;
    color: $blue-very-dark;
    margin-bottom: 1.429rem;

    &[step='continue'] {
      svg {
        top: 1.857rem;
      }
    }

    svg {
      color: $orange;
      position: relative;
      transform: rotateZ(320deg);
      margin-left: 1rem;
    }
    .success-check-icon {
      color: $green;
      transform: rotateZ(0deg);
    }
  }

  .reset-text {
    font-weight: 400;
    font-size: 1rem;
    line-height: 140%;
    color: $blue-very-dark;
    margin-top: -0.8571rem;
    margin-bottom: 2.2857rem;
  }

  .email-text {
    margin-bottom: 1.714rem;
  }

  .field.reset-email {
    margin-bottom: 2.2857rem;
  }

  .field {
    margin-bottom: 1.714rem;
  }

  .field > label {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 0.8571rem;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: $blue-very-dark;
    margin-bottom: 0.429rem;
  }
  .ui.input {
    width: 25.7143rem;
    height: 2.714rem;
    border: 0.0714rem solid $grayish-magenta-light;
    border-radius: 0.429rem;
    input {
      font-family: 'Urbanist';
      font-style: normal;
      font-weight: 400;
      font-size: 0.8571rem;
      line-height: 140%;
      align-items: center;
      color: $blue-very-dark;

      &:focus {
        background: $orange-very-light-grayish;
        border: 0.0714rem solid $orange;
        color: $blue-very-dark;
      }
    }
    svg {
      position: absolute;
      top: 0.714rem;
      width: 1.1429rem;
      height: 1.1429rem;
      color: $gray;
      right: 0.9707rem;
    }
  }

  i {
    color: $blue-dark-grayish !important;
  }
  .input-bottom {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    display: inline-block;
    font-size: 1rem;
    line-height: 120%;
    text-align: left;
    color: $orange;
    cursor: pointer;
    margin-top: 1.5rem;
  }

  .another-email {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 120%;
    text-align: left;
    color: $blue-very-dark;
    text-align: center;
    margin-top: -0.3em;
    margin-bottom: 2em;
    cursor: pointer;

    span {
      font-weight: 600;
      color: $orange;
      padding-left: 0.286rem;
    }
  }

  .login-btn {
    width: 25.714rem;
    border-radius: 0.286rem;
    font-size: 0.8571rem;
    text-transform: uppercase;
    background: $orange;
    color: $white;

    svg {
      position: relative;
      top: 0.286rem;
      left: 0.3364rem;
    }

    &:disabled {
      background: $grayish-magenta-light;
      color: $red-very-dark-grayish;
    }

    &[step='3'] {
      margin-top: 0.571rem;
    }

    &[moveup='true'] {
      margin-top: -0.4286rem;
    }

    &[toggleup='true'] {
      margin-top: -0.714rem;
    }
  }
}
