@import 'assets/styles/_colors.scss';

.parameter-input {
  display: flex;
  flex: 1;
  align-items: center;
  border: 0.05rem solid $grayish-magenta-light;
  border-radius: 0.25rem;

  background-color: $white;

  & > input,
  & > div {
    display: flex;
    flex: 1;
    border: none;
    align-items: center;

    &::placeholder {
      color: $gray;
    }
  }

  & > input#id-multi-level {
    border: none;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0.2rem;

    & > span {
      display: flex;
      gap: 0.5rem;

      text-transform: capitalize;
    }
  }

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.25rem;

    background-color: $white;
    color: $gray;

    &:hover {
      cursor: pointer;
    }
  }

  .input-override {
    & > input#id-single-input {
      border: none;
      width: 100%;
    }
  }
}
