@import 'assets/styles/_colors.scss';

.ai-chatbot-container {
  position: fixed;
  bottom: 5rem;
  right: 3.2rem;
  z-index: 1000;
}

.ai-chatbot-trigger {
  &__btn {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    background-color: $orange;
    color: $white;
    box-shadow:
      0 1px 5px 1px rgb(0 0 0 / 0.1),
      0 1px 5px -1px rgb(0 0 0 / 0.1);
    transition: all 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: $white;
      color: $orange;
    }
  }
}
