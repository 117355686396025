@import 'assets/styles/_colors.scss';
@import 'assets/styles/_metrics.scss';

.ui.button.transaction-link-btn {
  background: $orange;
  color: white;
  left: 1rem;
  top: -0.2rem;
  font-size: 0.8rem;
  height: 2.05rem;
  position: relative;
  &.gray {
    background-color: $gray;
  }
}

.ui.visible.popup.special-rights-box {
  min-width: 35rem;
}
