@import 'assets/styles/_colors.scss';

.dash-outlined.upload-area {
  position: relative;
  border: 0.0714rem dashed $gray;
  box-sizing: border-box;
  border-radius: 0.8571rem;
  height: 14.5174rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 1rem;

  &.small {
    height: 8.2rem;
    overflow: hidden;
  }

  &.hidden {
    visibility: hidden;
  }

  &.inactive {
    opacity: 0.6;
    cursor: default;
  }

  &.active:hover {
    border: 0.0714rem solid $orange;
    box-shadow: 0 0.286rem 1.1429rem $blue-greyish;
    cursor: pointer;
  }

  .img-left {
    position: absolute;
    left: 0.0714rem;
  }

  .img-right {
    position: absolute;
    right: 0.0714rem;
  }

  .upload-file-area-title {
    margin-left: 0.643rem;
    font-weight: 600;
    font-size: 1rem;
    line-height: 120%;
    color: $blue-very-dark-grayish;
  }

  .text {
    margin-top: 0.643rem;
    font-weight: 400;
    font-size: 0.8571rem;
    line-height: 140%;
    display: flex;
    align-items: center;
    color: $blue-very-dark-grayish;
  }
}

.upload-list-container {
  width: 101.5%;
  max-height: 14.5174rem;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.4rem;
  }
  &::-webkit-scrollbar-thumb {
    background: $orange;
    border-radius: 0.429rem;
    height: 5.214rem;
  }

  .btn-radius {
    border-radius: 0.429rem;
  }

  li {
    list-style: none;
    display: flex;
    align-items: center;
    color: $blue-very-dark;

    &:hover:not(:only-of-type) {
      background: $white-smoke;
    }

    a {
      &:first-of-type {
        overflow: hidden;
        flex-grow: 1;

        .text {
          font-weight: 700;
          font-size: 1rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 90%;
        }
      }
    }

    .cursor {
      cursor: pointer;
      color: $blue-very-dark;
    }

    .btn-hover:hover {
      box-shadow: 0px 1px 4px $blue-greyish;
    }
  }
}

.p-datatable-wrapper-overflow-visible {
  .p-datatable-wrapper {
    overflow: visible;
  }

  .listing-text-area textarea {
    width: 100%;
    height: 6rem;
    padding: 0.5rem;
  }
}
