@import 'assets/styles/_colors.scss';

.ui.grid.pareto-graph-without-line {
  width: 100%;
  margin: 0;
  padding: 1.714rem;
  box-sizing: border-box;
  border-radius: 0.8571rem;

  .row {
    min-height: 15rem;
  }

  .ui.active.loader.graph-loader {
    z-index: 1;
    &::after {
      border-color: $orange transparent transparent;
    }
  }

  .pareto-graph-container-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .graph-title {
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2.3571rem;
    }

    .no-data {
      flex-basis: 100%;
      margin-top: 6rem;
      align-self: center;
      font-size: 1rem;
      font-weight: 400;
      text-align: center;
    }

    .pareto-entry-count {
      margin-bottom: 0.5rem;
      margin-left: 1rem;
    }

    .ui.dropdown {
      margin-left: auto;
      width: 8rem;
      height: 2rem;
    }

    .pareto-graph-box {
      flex-basis: 100%;
      width: 100%;
      height: 100%;
      height: 13.9286rem;

      canvas {
        width: 100% !important;
      }
    }

    .legends.multi-graph {
      display: flex;
      margin-top: 3rem;

      div {
        &:first-of-type {
          margin-right: 1.1429rem;
        }
      }

      .circle {
        display: inline-block;

        &.gray {
          background: $gray-very-dark;
        }

        &.magenta {
          background: $magenta-desaturated;
        }
      }
    }
  }
}

#pareto-graph {
  min-width: 20rem;
  min-height: 1rem;
  background: $white;
  box-shadow: 0 0.286rem 1.1429rem $blue-greyish;
  border-radius: 0.571rem;
  font-family: 'Urbanist';
  font-style: normal;
  padding: 1.1428rem;
  z-index: 2;

  .inner-html {
    padding: 1rem;
    width: 100%;
    color: $blue-very-dark;

    h2 {
      font-weight: 600;
      font-size: 0.8571rem;
      line-height: 1rem;
      color: $blue-very-dark;
    }
    .second {
      font-weight: 400;
      color: $blue-very-dark;
      span {
        font-weight: 400;
        color: $blue-desaturated-dark;
      }
    }
  }
}
