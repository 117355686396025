@import 'assets/styles/_colors.scss';

.ui.grid.graph-without-line.revenue-performance-vs-forecast {
  margin-top: 1.7142rem;
}

.graph-tooltip-container {
  .inner-html.revenue-performance-vs-forecast {
    width: 100%;
    color: $blue-very-dark;

    .title {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.4285rem;
      padding-bottom: 0.5rem;
      border-bottom: 0.071rem solid $gray-light;
    }

    p {
      font-weight: 400;
      font-size: 0.857rem;
      line-height: 140%;
      padding: 0;
      display: flex;
      align-items: center;

      span {
        &.orange {
          background-color: $orange;
        }

        &.green {
          background-color: $green;
        }

        &.blue {
          background-color: $blue;
        }
      }
    }

    .table-container {
      max-height: 16rem;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 0.429rem;
      }

      &::-webkit-scrollbar-track {
        background: $orange-light-grayish;
      }

      &::-webkit-scrollbar-thumb {
        background: $orange;
        border-radius: 0.429rem;
        height: 5.214rem;
      }

      table.ui.table.dashboard-table.revenue-performance-vs-forecast {
        border-bottom: none;
        border-right: none;
        border-left: none;
        margin: 0 !important;

        thead {
          th {
            position: sticky;
            top: 0;
            z-index: 2;
            background-color: $gray-very-light-v3;
          }
        }

        th,
        td {
          color: $blue-very-dark;
          font-size: 0.8571rem !important;
          line-height: 1rem;
        }

        th {
          border: none;
        }

        tr {
          &:not(:last-of-type) {
            td {
              border: none;

              a {
                text-decoration: underline;
                font-style: italic;
              }
            }
          }

          &.total-row {
            td {
              position: sticky;
              bottom: 0;
              background-color: $white;
              z-index: 1;
            }

            td:first-of-type {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}
