@import 'assets/styles/_colors.scss';

.dropdown-property-details {
  cursor: default;

  span {
    font-weight: 700;
    color: $blue-very-dark-grayish;
  }
}
