@import 'assets/styles/_colors.scss';

.history-tab {
  .ui.secondary.pointing.menu .item {
    font-size: 1rem !important;
  }
  .ui.accordion.approval-accordian {
    width: 40.286rem;
    background: $gray-very-light-v4;
    border-radius: 0.857rem;
    height: fit-content;
    &.upload-acc-docs {
      min-width: fit-content;
    }
    &.doc-approval {
      height: 10.5rem;
      min-width: fit-content;
    }
    .title {
      font-family: 'Urbanist' !important;
      font-style: normal;
      cursor: default;
    }
    .open-box {
      cursor: pointer;
    }
    .date-title {
      font-weight: 600;
      font-size: 0.929rem;
      line-height: 1.143rem;
      letter-spacing: 0.02em;
      display: inline;
    }
    .content-title {
      font-weight: 600;
      color: $blue-very-dark;
      font-size: 1.143rem;
      display: flex;
      align-items: center;
    }
    .person {
      font-size: 0.857rem;
      color: $blue-very-dark-grayish;
      line-height: 2.5rem;
      text-align: initial;
      .bold-person {
        font-weight: 600;
      }
      .avatar-image,
      .avatar-image-icon {
        vertical-align: top;
      }
    }
    &.right-draft {
      width: 30rem;
    }
    &.approve-reject {
      height: fit-content;
    }
    &.right-draft-box {
      width: 32rem;
    }
  }
}

.ui.button.approval-right-btn {
  text-align: center;
  text-transform: uppercase;
  border-radius: 0.286rem;
  font-size: 0.857rem;
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 600;
  float: right;
  position: absolute;
  right: 0;
  top: 2.5rem;

  &.request {
    .right-btn-text {
      top: -0.6rem;
    }
  }
  &.signature-btn {
    position: relative;
    left: -14rem;
  }
  .plus-add {
    font-size: 1.5rem !important;
    left: -0.5rem;
    top: -0.2rem;
    position: relative;
  }
}

.box-flex {
  display: flex;
  .uploaded-docs {
    position: relative;
    top: 5.5rem;
    left: 2.5rem;
    height: 4rem;
    .inner-text {
      top: 0.4rem;
      width: max-content;
      position: absolute;
      left: 4.3rem;
    }
    svg.download-icon {
      position: absolute;
      top: 2rem;
      left: 4.3rem;
    }
  }
}
