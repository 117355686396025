@import 'assets/styles/_colors.scss';

.editor-box {
  .tox:not(.tox-tinymce-inline) .tox-editor-header {
    background-color: $gray-very-light-v4;
  }

  .tox-toolbar__primary {
    background-color: $gray-very-light-v4 !important;
  }

  .tox .tox-tbtn {
    background: $gray-very-light;
    border-radius: 0.286rem;
    margin-right: 0.571rem;
  }

  .tox .tox-tbtn--enabled,
  .tox .tox-tbtn--enabled:hover {
    background: $blue-very-soft;
  }

  .tox.tox-tinymce {
    border: 2px solid $grayish-magenta-light !important;
    max-height: 35.7142rem;

    &.focused {
      border: 2px solid $orange !important;

      iframe {
        background-color: $orange-very-light-grayish;
      }
    }
  }

  .tox-tinymce--disabled {
    .tox-edit-area__iframe {
      background-color: $gray-whitish-light;
    }
  }
}

.tox .tox-dialog__footer {
  position: relative;
  top: -0.6rem;
}

.tox .tox-button[title='Save'] {
  width: 8.286rem !important;
  height: 2.5rem !important;
  background-color: $blue-very-dark-grayish !important;
  border-radius: 0.286rem !important;
  font-family: Urbanist !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 0.8571rem !important;
  text-transform: uppercase !important;
  color: $white !important;
}

.tox .tox-button[title='Cancel'] {
  width: 8.286rem !important;
  height: 2.5rem !important;
  border: 0.107rem solid $blue-very-dark-grayish;
  border-radius: 0.286rem !important;
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 600 !important;
  font-size: 0.857rem;
  text-transform: uppercase;
  color: $blue-very-dark-grayish;
  background: transparent;
  margin-right: 0.5rem;

  &:hover {
    background: transparent !important;
    border: 0.107rem solid $blue-very-dark-grayish !important;
  }
}
