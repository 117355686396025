.forecasting-tab-top-section {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 90%;
}

.forecasting-tab-view-section {
  top: -1.5rem;
  position: relative;
}
