@import 'assets/styles/_colors.scss';

.policy-provisions-tab {
  .section-provisions {
    border-radius: 0.857rem;
  }
}

.section-provisions {
  display: block;

  .section-provisions-label {
    font-weight: 700;
    font-size: 1.714rem;
    line-height: 2.214rem;
    display: flex;
    align-items: center;
  }

  .component-card-container {
    margin-top: -1.5rem;

    &:first-of-type {
      margin-top: 0;
    }
  }

  .catalogue-close-button {
    margin-left: auto;
    margin-right: 3rem;
    border-radius: 50%;
    padding: 0.6rem 1rem;
  }
}

.card-header-menu-add-button {
  cursor: pointer;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
}

.card-header-menu {
  text-align: right;
  float: right;
  width: 20%;
  margin-right: 0.5rem;
}

.policy-provisions-section {
  overflow: hidden;
  margin-right: 0;
  margin-left: 0;
  width: 100%;
  height: calc(85vh - 3.571rem - 3.571rem - 3.571rem - 3.429rem - 10rem);
  min-height: 0;

  .component-card-container {
    margin-top: -0.7rem;

    &:first-of-type {
      margin-top: 0;
    }
  }

  .card-body {
    min-height: 3rem;
    padding: 0.4rem 0.4rem;
  }

  .component-card-body.ui.grid {
    padding: 0.4rem 0.4rem;
    min-height: 3rem;
    font-size: 1rem;

    .row {
      .column {
        &.five {
          text-wrap: balance;
        }

        &.eleven {
          font-weight: bolder;
          white-space: break-spaces;
        }
      }
    }
  }

  .card-component {
    height: inherit;
    margin-top: 0;
    overflow: scroll;
  }

  .card-header .card-header-template {
    width: calc(100% - 1rem);

    .title-template {
      width: inherit;
      overflow: visible;

      .column-custom-template {
        display: inline-flex;
        width: 99%;

        .name {
          font-size: 1.143rem !important;
          text-transform: capitalize;
          font-weight: 600;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: initial;
        }

        .card-header-icon-mandatory {
          width: 1.8rem;
        }
      }
    }
  }
}
