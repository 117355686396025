@import 'assets/styles/_colors.scss';

.badge-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  padding: 0.143rem 0.429rem;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.786rem;
  min-height: 1.429rem;
  height: fit-content;
  letter-spacing: 0.02em;
  background: $gray-very-light;
  color: $blue-very-dark-grayish;
  text-align: center;

  &.blue {
    background: $blue-light-grayish;
    color: $blue;
  }

  &.green {
    background-color: $green-light-grayish-cyan-lime;
    color: $green;
  }

  &.orange {
    background-color: $orange-light-grayish;
    color: $orange;
  }

  &.red {
    background-color: $red-very-pale;
    color: $red-soft;
  }

  &.gray {
    background-color: $gray-very-light;
    color: $blue-very-dark-grayish;
  }

  &.light-gray {
    color: $gray-very-darker;
    background: $white-smoke;
  }

  &.purple {
    color: $purple;
    background: $pink-soft;
  }

  &.dark-orange {
    background: $orange;
    color: $white;
  }
}
