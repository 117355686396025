@import 'assets/styles/_colors.scss';

.ui.dropdown.import-dropdown {
  .import-dropdown-button {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.429rem;
    cursor: pointer;

    &:hover {
      background: $white-smoke;
      border-radius: 0.571rem;
    }

    svg.import-icon {
      position: relative;
      top: 0.214rem;
      color: inherit;
      margin-left: 0.1429rem;
    }
  }

  .simulation-select .field {
    margin-bottom: 0;
  }

  .visible.menu.transition {
    border: none;
    min-width: 15.143rem;
    margin-top: 0.286rem;
    box-shadow: 0 0.286rem 1.1429rem $blue-greyish;
    border-radius: 0.571rem;

    .item {
      font-size: 0.8571rem;
      line-height: 140%;
      padding: 0.75rem 0 0.5rem 1.14285714rem !important;

      &.action-btn {
        cursor: default;

        &:hover {
          background: $white !important;
        }
      }
    }
  }
}
