@import 'assets/styles/_colors.scss';
@import 'assets/styles/_metrics.scss';

.ui.modal.modal-template {
  border-radius: 0.8571rem;
  background: $white;
  box-sizing: border-box;

  // React semantic UI class
  .header {
    background: $gray-blackish;
    border-radius: 0.8571rem 0.8571rem 0 0;
    height: 4.429rem;
    padding: 1.071rem 2.2857rem 1.1429rem 2.2857rem;
    position: relative;

    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 1.714rem;
      line-height: 2.2286rem;
      color: $white;
      position: relative;
      z-index: 3;
    }

    .exit-btn {
      position: absolute;
      top: 1.071rem;
      right: 2.2857rem;
      padding: 0.429rem 0.714rem;
      width: auto;
      height: 2.143rem;
      background: $gray-blackish;
      border: 0.107rem solid $orange;
      box-sizing: border-box;
      border-radius: 0.286rem;
      color: $orange;
      z-index: 10000;

      svg {
        position: relative;
        left: 0.3571rem;
        width: 0.8571rem;
        height: 0.929rem;
        top: 0.0714rem;
      }
    }

    .header-image {
      position: absolute;
      top: 0;
      display: inline-block;
      left: 22.714rem;
    }
  }

  .content {
    padding: 1.429rem 2.2857rem;
    border-radius: 0.8571rem;
    .ui.button {
      margin: 0 auto;
      width: 22.857rem;
      height: 2.857rem;
      border-radius: 0.286rem;
      font-weight: 700;
    }

    .p-datatable .ui.button {
      margin: initial;
      height: initial;
      width: initial;
      border-radius: initial;
      font-weight: initial;
    }
  }

  & > .content {
    overflow-y: auto;
    height: inherit;
    &::-webkit-scrollbar {
      width: 0.6rem;
    }
  }

  .ui.button.cancel-btn {
    width: 18.286rem !important;
    height: 2.857rem !important;
    border: 0.107rem solid $blue-very-dark-grayish;
    border-radius: 0.286rem !important;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 0.857rem;
    line-height: 1.357rem;
    text-transform: uppercase;
    color: $blue-very-dark-grayish;
    background: transparent;
  }

  .editor-loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .ui.active.loader {
      &::after {
        border-color: $orange transparent transparent;
      }
    }
  }
}
