@import 'assets/styles/_colors.scss';

.ui.form.message-to-tenant {
  label {
    font-size: 1rem !important;
    color: $red-soft !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
