@import 'assets/styles/_metrics.scss';
@import 'assets/styles/_colors.scss';

.ui.grid.section-box {
  height: 48rem;
}

.left-content {
  &.section-left {
    position: relative;
    top: 10%;
  }

  .ui.grid > .row.section-row {
    position: relative;
    font-family: 'Urbanist';
    font-style: normal;
  }
  .section-selector {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 2.857rem;
    display: flex;
    align-items: center;
    letter-spacing: -0.01em;
    padding-left: 0;
  }
}

@media (min-width: $big-monitors-min-width) {
  .section-left {
    top: 20% !important;
  }
}

@media (max-width: $xl-device-min-width-misc-3) {
  .ui.grid > .row > .column {
    &.landing-page-left {
      width: 60% !important;
    }
    &.landing-page-right {
      width: 40% !important;
    }
  }
}

@media (max-width: $xl-device-min-width) {
  .ui.grid > .row > .column {
    &.landing-page-left {
      width: 100% !important;
    }
    &.landing-page-right {
      display: none;
    }
  }
}
