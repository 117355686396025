@import 'assets/styles/_colors.scss';

.privacy-and-terms {
  .header-privacy {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    align-items: center;
  }

  .right-header {
    font-weight: normal;
    display: inline-flex;
  }
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 1.4rem;
  }
  p {
    font-size: 1rem;
    text-align: justify;
  }
  .contact-box {
    height: 7rem;
  }
  .privacy-link {
    text-decoration: underline;
  }
}
