@import 'assets/styles/_colors.scss';
@import 'assets/styles/_metrics.scss';

.stacking-plan-dashboard {
  .stacking-plan-sections {
    border-radius: 0.75rem;

    .stacking-plan-range-filter {
      display: flex;
      justify-content: space-between;
      line-height: 2rem;
    }
  }

  .apexcharts-tooltip {
    padding: 1.143rem;
    width: 22.143rem;
    white-space: normal;
  }
  .tooltip-row {
    width: 100%;
    display: flex;
  }
  .tooltip-row .left {
    display: inline-block;
    width: 40%;
    font-style: normal;
    font-weight: 700;
    font-size: 0.857rem;
    line-height: 1.5rem;
    text-transform: capitalize;
    color: $blue-desaturated-dark;
  }
  .tooltip-row .right {
    display: inline-block;
    width: 60%;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $blue-very-dark;
  }

  .y-axis-label {
    font-style: normal;
    font-weight: 700;
    font-size: 0.857rem;
    line-height: 1.214;
    text-align: right;
    text-transform: uppercase;
  }

  .x-axis-label {
    font-style: normal;
    font-weight: 700;
    font-size: 0.857rem;
    line-height: 1.214;
    text-align: left;
    text-transform: uppercase;
  }

  .graph-scroll {
    height: calc(100vh - 28rem);
    overflow-y: scroll;
    margin-top: 1rem;
    width: 100%;
  }

  .side-menu-scroll {
    height: calc(100vh - 20.5rem);
    overflow-y: auto;
  }

  .stacking-plan-filters {
    display: contents;
    line-height: 2.429rem;
  }

  .stacking-table-file-actions {
    margin-left: auto;
    line-height: 2.429rem;
    span {
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      color: $blue-very-dark-grayish;
      margin-left: 1rem;
      svg {
        margin: 0 0.3rem;
      }
    }
  }

  .apexcharts-canvas .apexcharts-series {
    cursor: pointer;
  }
}
