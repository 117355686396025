@import 'assets/styles/_colors.scss';

.component-card-view {
  display: flex;
  flex-direction: column;
  flex: 1;

  .empty-category-card {
    height: 9.286rem;
    box-shadow: inset 0rem 0rem 1.714rem $gray-very-light-v4;
    border-radius: 0.857rem;
    margin-top: 1.5rem;
    width: 100%;
    text-align: center;

    .plus-position {
      top: 0.129rem;
    }
  }

  .card-body {
    min-height: 3rem;
  }

  .component-card-body.ui.grid {
    padding: 0.4rem 0.4rem;
    min-height: 3rem;
    font-size: 1rem;

    .row {
      .column {
        &.five {
          text-wrap: balance;
        }
        &.eleven {
          font-weight: bolder;
          white-space: break-spaces;
        }
      }
    }
  }

  .card-header .card-header-template {
    width: calc(100% - 1rem);
    .title-template {
      width: inherit;
      overflow: visible;

      .column-custom-template {
        display: inline-flex;
        align-items: baseline;
        width: 99%;
        .name {
          font-size: 1.143rem !important;
          text-transform: capitalize;
          font-weight: 600;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: block;
        }
      }
    }
  }
  .duplicate-icon {
    top: 0.15rem;
  }
}
