@import 'assets/styles/_colors.scss';

.tox-tinymce {
  border: none !important;
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

.tox:not(.tox-tinymce-inline) .tox-editor-header {
  padding: 0 !important;
  box-shadow: none !important;
}

.tox .tox-statusbar {
  display: none !important;
}

.tox .tox-pop {
  max-width: 35.7143rem !important;
}

.tox-pop .tox-pop__dialog .tox-toolbar:nth-child(n + 2) {
  width: 100%;
  flex: 1;
  background-color: red;
}

.tox-pop .tox-pop__dialog .tox-toolbar:first-child {
  /* border: 0.3571rem solid gold; */
  display: block;
}

.tox-pop .tox-pop__dialog .tox-toolbar > :nth-child(2)::after {
  content: ' ';
  display: block;
}

.tox-pop .tox-pop__dialog .tox-toolbar > :nth-child(n + 3) {
  /* background-color: red; */
  width: 100%;
  flex: 1;
}
.tox .tox-toolbar__primary {
  background-color: $orange-light-grayish !important ;
}
