@import 'assets/styles/_colors.scss';

.parameter-multi-level-select-dropdown {
  display: none;
  position: absolute;
  top: 100%;
  z-index: 50;
  overflow: auto;
  padding: 0.25rem 0;
  border-radius: 0.5rem;
  width: 25rem;
  max-height: 25rem;
  margin-top: 0.2rem;

  & > ul {
    display: flex;
    flex: 1;
    flex-shrink: 0;
    flex-direction: column;

    margin-top: 0.5rem;
    margin-bottom: 0.25rem;

    list-style: none;
    width: 20rem;

    & > li {
      display: flex;
      flex-shrink: 0;

      height: 2.4rem;
      width: 100%;
      align-items: center;

      padding: 0 1.55rem !important;

      &.go-back {
        color: $blue-very-dark;
        margin: 0.25rem 0;

        & > span {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 2rem;
          margin-right: 1rem;
        }

        &:hover {
          background-color: $orange-very-light-grayish;
          cursor: pointer;
        }
      }
    }
  }

  .title {
    color: $blue-dark-grayish !important;
    text-transform: uppercase;
  }

  .item {
    display: flex;
    justify-content: space-between;
    color: $blue-very-dark;
    text-transform: capitalize;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & > span {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      background-color: $orange-very-light-grayish;
      cursor: pointer;
    }

    & .hide {
      display: none;
    }
  }
}
