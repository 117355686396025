@import 'assets/styles/_colors.scss';

.select-color-options {
  .select-color-icon {
    background: $orange;
    display: inline-block;

    &[color='gray'] {
      background: $gray;
    }
    &[color='green'] {
      background: $green-soft;
    }
    &[color='yellow'] {
      background: $yellow;
    }
    &[color='blue'] {
      background: $blue;
    }
    &[color='violet'] {
      background: $violet-vivid;
    }
  }

  .select-color-text {
    position: relative;
    top: -0.536rem;
    left: 0.571rem;
  }
}
