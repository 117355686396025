@import 'assets/styles/_colors.scss';

.card-parameters-main {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.card-parameters {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  flex-direction: column;
  flex: 1;

  .ui.secondary.pointing.menu {
    width: fit-content;
  }

  .card-component {
    margin-top: 0;

    .card-header {
      width: 100%;
    }

    .column-custom-template {
      display: inline-flex;
      width: 99%;

      .name {
        font-size: 1.143rem !important;
        text-transform: capitalize;
        font-weight: 600;
        width: inherit;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: initial;
      }

      .version {
        margin: 0;
        margin-left: 0.5rem;
      }

      .card-header-icon-mandatory {
        width: 1.8rem;
      }
    }
  }

  // check if works for other browsers
  &::-webkit-scrollbar {
    height: 0.614rem;
  }

  .progress-bar-container {
    left: -0.8rem;
    position: relative;
    width: 110%;
  }

  &.transaction-cards {
    .progress-bar-card-parameters {
      padding-top: 0.6rem;
    }

    .progress-bar-container {
      position: relative;
      padding-top: 0.2rem;
      margin-bottom: -0.4rem;
      top: -0.3rem;
    }

    .ui.grid > .row {
      padding: 0;
      padding-top: 0;
      padding-bottom: 0;
    }

    .card-component .column-custom-template {
      width: 100%;

      .name {
        width: 100%;
      }
    }

    .card-header {
      width: 100%;
    }

    .card-body .card-body-template {
      padding: 0.4rem 0.4rem;
    }

    .policy-card-parameters {
      left: -0.8rem;
      position: relative;
      text-align: start;
      display: flex;

      & > svg {
        vertical-align: baseline;
        margin: 0 0.4rem;
        font-size: 1.2rem;
      }
    }
  }

  &.policies-cards {
    .progress-bar-card-parameters {
      padding-top: 1.5rem;
    }

    .progress-bar-container {
      margin-top: -1.8rem;
      width: 110%;
    }

    .card-body {
      padding: 0.5rem 1rem;
    }
  }

  .tabs .ui.secondary.pointing.menu {
    overflow-x: visible !important;
  }
}

.filter-section-part {
  display: flex;
  align-items: center;

  .filter-parameter-types {
    height: 2.429rem;
    align-items: center;
  }

  .filter-conditional {
    display: flex;
    height: 100%;
    align-items: center;
    background: transparent;
    width: 10rem;
  }

  .ui.selection.dropdown-atom-select-filter {
    line-height: 100%;
    i.dropdown.icon {
      top: 0.85em;
    }
  }
}

.parameters-tab-container {
  .filter-section-part {
    display: contents;
  }

  .tab-container-header .tab-container-header-toolbar {
    justify-content: flex-start;
  }
}
