@import 'assets/styles/_colors.scss';

$padding-button-top-bottom: 0.125rem;
$padding-button-right-left: 0.5rem;
$large-monitor-misc: 1735px;

.hidden-menu-divider {
  display: flex;
  border-bottom: 0.1rem solid $gray-very-light-v2;
}

.add-new-line {
  display: flex;
  align-items: center;

  span {
    display: flex;
    background-color: $orange;
    justify-content: center;
    align-items: center;
    height: 2.5714rem;
    width: 2.5714rem;
    border-radius: 50%;
  }

  p {
    margin: 0 0.5rem;
  }
}

.param-container {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  min-width: 20rem;

  .helper-text {
    height: 1.4286rem;
    color: $red;
    margin-bottom: 0.5rem;
  }

  .param-container-form {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    justify-content: center;

    // Rule for margin-top on the Save and Delete button
    & > :last-child {
      margin-top: 1rem;
    }

    .formatter-flex {
      display: flex;
      flex-direction: column;

      & > div {
        padding-left: 1.5rem;
      }
    }

    .formatter-none {
      display: none;
    }

    .grid-row {
      display: flex;
      align-items: center;
      justify-content: center;

      & > :nth-child(2) {
        cursor: pointer;
        margin: 0 1rem;
      }

      .parameter-delete {
        text-align: center;
        cursor: pointer;
      }
    }

    .input-border > input {
      border: none;
    }

    .formatter-function-section {
      & > div {
        display: flex;
        flex: 1;
        align-items: center;
        gap: 0.5rem;

        & > div:first-child {
          margin: 0;
          width: 100%;
        }

        & > button {
          height: 2.7rem;
        }
      }
    }
  }
}

// This is needed to inspect the HTML element in you Browser to obtain the var-name
.w-tc-editor-var > div {
  --color-prettylights-syntax-entity-tag: $orange;
  --color-prettylights-syntax-comment: $blue-very-dark;
  --color-fg-default: $red-very-dark-grayish;
}

.ui.button.parameters-bar {
  min-width: 7rem;
  margin-right: 1rem;
}
