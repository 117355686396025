.ui.modal.signed-document-modal {
  height: 30rem;
  width: 70% !important;
  top: -10rem;

  > .content {
    max-height: 50rem;
    overflow-y: auto;
  }
}

.signed-document-btn {
  position: absolute;
  right: 0;
}
