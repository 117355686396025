@import 'assets/styles/_colors.scss';

.ui.grid.welcome-page {
  .row {
    position: absolute;
    top: 0;
    bottom: -3.4286rem;
  }

  .left {
    display: flex;
    justify-content: center;
    align-items: center;

    .left-content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 70%;

      h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 2.857rem;
        line-height: 3.7143rem;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: $blue-very-dark;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
        color: $blue-very-dark;
      }

      .steps {
        margin-top: 2.857rem;
        margin-bottom: 3.429rem;

        .step {
          display: flex;

          .progress {
            width: 2.357rem;
            display: flex;
            justify-content: flex-end;
            position: relative;
            top: -0.286rem;

            .progress-line {
              position: absolute;
              left: 0;
              right: 1.429rem;
            }
          }

          .inner {
            flex-grow: 1;
            margin-left: 0.286rem;

            .step-header {
              font-style: normal;
              font-weight: 500;
              font-size: 0.8571rem;
              line-height: 120%;
              display: flex;
              align-items: center;
              text-align: left;
              text-transform: uppercase;
              color: $blue-very-dark-grayish;
            }

            .container {
              display: flex;
              align-items: center;
              flex-direction: row;
              justify-content: space-between;
              margin-top: 0.286rem;

              .step-title {
                font-style: normal;
                font-weight: 600;
                font-size: 1.1429rem;
                line-height: 1.714rem;
                color: $blue-very-dark;
                display: inline-block;
              }

              .empty-circle-icon {
                width: 1.429rem;
                height: 1.429rem;
                border: 0.0714rem solid $blue-dark-grayish;
                border-radius: 0.714rem;
              }
            }
          }

          .step-selected {
            width: 1.429rem;
            height: 1.429rem;
            background: $green;
            border-radius: 0.714rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            font-weight: 700;
          }

          &:first-of-type {
            .progress-line {
              border-top: 0.1429rem solid $green;
              border-left: 0.1429rem solid $green;
              border-top-left-radius: 0.714rem;
              top: 0.714rem;
              bottom: 0.714rem;
            }
          }

          &:nth-of-type(2) {
            .progress-line {
              border-bottom: 0.1429rem solid $green;
              border-left: 0.1429rem solid $green;
              border-bottom-left-radius: 0.714rem;
              top: -1.4286rem;
              bottom: 5.714rem;
              z-index: 2;
            }
          }

          &:nth-of-type(3) {
            .progress {
              .progress-line {
                border-bottom: 0.1429rem solid $green-very-soft-cyan-lime;
                border-left: 0.1429rem solid $green-very-soft-cyan-lime;
                border-bottom-left-radius: 0.714rem;
                top: -6.143rem;
                bottom: 2.143rem;
              }

              .checked {
                background: $green-very-soft-cyan-lime;
              }
            }

            .step-header,
            .step-title {
              color: $blue-dark-grayish !important;
            }
          }

          &:not(:last-of-type) {
            .container {
              margin-bottom: 3.6429rem;
            }
          }
        }
      }

      .ui.button {
        height: 2.857rem;
        font-weight: 700;
      }
    }
  }

  .right {
    position: relative;

    div {
      height: 100%;
      width: 113.5%;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}
