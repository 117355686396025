@import 'assets/styles/_colors.scss';
.ui.grid.guidelines {
  width: 100% !important;
  margin: 0 !important;
  border: 0.0714rem solid $grayish-magenta-light;
  box-sizing: border-box;
  border-radius: 0.857rem;

  .row.header-row {
    border: 0.0714rem solid $grayish-magenta-light;
    border-radius: 0.571rem 0.571rem 0 0;
    height: 2.857rem;
    padding-left: 1rem;
    padding-top: 0.714rem;
    font-style: normal;
    font-weight: 700;
    font-size: 0.857rem;
  }
  .row.text-boxes {
    height: 20rem;

    .inner-content {
      max-height: 100%;
      overflow-y: auto;
      padding: 0.5rem 0.9rem 0.5rem 1.429rem !important;
      width: calc(25% - 0.3rem) !important;
      overflow-wrap: anywhere;

      &::-webkit-scrollbar {
        width: 0.55rem;
      }

      &::-webkit-scrollbar-track {
        background: $orange-light-grayish;
      }

      &::-webkit-scrollbar-thumb {
        background: $orange;
        border-radius: 0.429rem;
        height: 3rem;
      }

      &:first-of-type {
        width: calc(25% - 0.7rem) !important;
      }

      &:last-of-type {
        width: calc(25% + 1.3rem) !important;
      }
    }
  }
  .row.footer-row {
    border-radius: 0 0 0.571rem 0.571rem;
    height: 2.857rem;
  }
  .right-line {
    border-right: 0.0714rem solid $grayish-magenta-light;
    height: 23rem;
    position: relative;
    top: -2.143rem;
    right: 1.429rem;
  }
}

.guidelines-modal {
  .tox:not(.tox-tinymce-inline) .tox-editor-header {
    background-color: $gray-very-light-v4;
  }

  .tox-toolbar__primary {
    background-color: $gray-very-light-v4 !important;
  }

  .tox .tox-tbtn {
    background: $gray-very-light;
    border-radius: 0.286rem;
    margin-right: 0.571rem;
  }

  .tox .tox-tbtn--enabled,
  .tox .tox-tbtn--enabled:hover {
    background: $blue-very-soft;
  }

  .tox.tox-tinymce {
    border: 2px solid $grayish-magenta-light !important;
    max-height: 35.7142rem;

    &.focused {
      border: 2px solid $orange !important;

      iframe {
        background-color: $orange-very-light-grayish;
      }
    }
  }
}

.ui.modal.modal-template.guidelines-modal {
  width: 50rem;
}
