@import 'assets/styles/_colors.scss';
@import 'assets/styles/_metrics.scss';

.floor-plans-section {
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
  white-space: nowrap;
  width: 100%;

  &::-webkit-scrollbar {
    width: 1em;
    height: 0.571rem;
  }

  .ui.grid.outlined.container.plan-content {
    position: relative;
    width: 26rem !important;
    min-width: 26rem !important;
    height: 33.4rem;

    &:hover:not([disabled]) {
      filter: drop-shadow(0px 4px 8px $blue-greyish);
    }

    @media (min-width: $xl-device-min-width) {
      margin-right: 1rem !important;
      margin-left: 0 !important;
    }

    a.plan-name {
      color: inherit;
      text-decoration: none;
      cursor: pointer;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &:hover {
        color: $blue !important;
      }
    }

    .area-sub-component {
      .ui.input {
        width: 7rem;
      }
    }
  }
}
