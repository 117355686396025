@import 'assets/styles/_colors.scss';

.footer {
  position: relative;
  align-items: center;
  padding: 1rem 0;
  border-top: 0.2rem solid $grayish-magenta-light;
  bottom: 0;

  .footer-version {
    font-style: normal;
    font-weight: 700;
    font-size: 0.8571rem;
  }

  .copyright {
    font-weight: 400;
    font-size: 0.8571rem;
    align-items: center;
  }

  .terms {
    font-weight: 600;
    font-size: 1rem;
    margin-left: auto;
  }
}
