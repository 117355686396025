.ui.modal.modal-template.cross-reference-modal {
  height: 29rem;
  width: 40rem;
  z-index: 10000;
  .field.field-style label {
    font-size: 1.2rem !important;
    font-weight: 600;
    .required-indicator {
      top: 0.5rem;
    }
  }
  .header .header-image {
    left: 16.714rem;
  }
  .content {
    padding-top: 0.5rem;
  }

  .cross-ref {
    position: relative;
    top: 2rem;
  }

  .ui.selection.dropdown.clause-number .menu {
    max-height: 10rem;
  }
}
