@import 'assets/styles/_colors.scss';

.import-report-statistics {
  .red-text {
    color: $red !important;
  }

  .bold-text {
    font-weight: 600;
  }
}
