@import 'assets/styles/_colors.scss';

.import-report {
  border-radius: 0.8571rem;
  min-height: 1rem;
  width: 100%;
  padding: 1.7142rem 2.2857rem;
  display: block;
  animation-duration: 0.3s;
  animation-name: animate-fade;
  animation-fill-mode: backwards;

  @keyframes animate-fade {
    0% {
      opacity: 0;
      transform: translate(0, -1.5rem);
    }
    100% {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-weight: 600;
      font-size: 1.5714rem;
      color: $blue-very-dark;
      margin: 0;
      padding: 0;
      font-family: 'Urbanist';
      font-style: normal;
    }

    .btns {
      display: inline-flex;
      padding: 0;
      margin: 0;
    }
  }

  .alert {
    font-weight: 600;
    font-size: 0.9285rem;
    line-height: 1.1428rem;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin-top: 1rem;
    padding: 0.4285rem 1rem;
    display: flex;
    border-radius: 0.4285rem;

    &.amber {
      color: $orange;
      background: $orange-light-grayish;
    }

    &.error {
      color: $red-light-soft;
      background: $red-very-pale;
    }

    svg {
      margin-right: 1rem;
    }
  }

  .details {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 3rem;

    .report-section {
      .item {
        margin-bottom: 1.1428rem;

        &:last-of-type {
          margin-bottom: 0;
        }

        .title {
          font-weight: 700;
          font-size: 0.8571rem;
          line-height: 1rem;
          color: $blue-very-dark;
        }

        ul {
          margin-top: 0.5714rem;
          padding-left: 1.5rem;

          li {
            font-weight: 400;
            font-size: 0.8571rem;
            line-height: 140%;
            color: $blue-very-dark-grayish;

            .success,
            .error,
            .amber {
              font-weight: 600;
              font-size: 0.8571rem;
              line-height: 1rem;
            }

            .success {
              color: $green;
            }

            .amber {
              color: $orange;
            }

            .error {
              color: $red-soft;
            }

            &::marker {
              font-size: 0.5rem;
            }
          }
        }
      }
    }
  }
}
