@import 'assets/styles/_colors.scss';
.ui.modal.amendment-creator-modal {
  .content {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 0;
    overflow: hidden;

    .btn-row {
      .ui.btn {
        width: 10rem !important;
        min-width: 1rem;
        font-size: 0.875rem;
        padding: 0.65625rem 1.09375rem;
        margin: 0;
        display: inline-flex;
        justify-content: center;
        cursor: pointer;
        user-select: none;
        align-items: center;
        vertical-align: bottom;
        text-align: center;
        overflow: hidden;
        position: relative;
        font-weight: bolder;
      }
    }

    .tox.tox-tinymce {
      height: 40rem !important;
    }
  }
}
