@import 'assets/styles/_colors.scss';

.contact-tabs {
  .ui.secondary.pointing.menu {
    font-weight: 600;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: $blue-very-dark !important;
    border-bottom: 0.0714rem solid $grayish-magenta-light;

    a.item,
    a.active.item {
      font-size: 1rem !important;
      padding-bottom: 0 !important;
    }
  }
}
