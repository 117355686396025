@import 'assets/styles/_colors.scss';

$container-y-padding: 1rem;
$circle-height: 1.6rem;

.step-wrapper {
  cursor: pointer;

  &.active {
    border-right: 0.2857rem solid $green;
    background: $gray-very-light-v4;
  }

  &.disabled {
    cursor: default;

    .title,
    .label {
      color: $gray !important;
    }

    .line,
    .start,
    .middle,
    .end,
    .border-left {
      border-color: $gray !important;
    }

    .circle {
      background: $gray !important;
    }
  }

  &:hover:not(.disabled) {
    background: $gray-very-light-v4;
  }

  .border-left {
    border-left: 0.1428rem solid $green;
  }

  .line {
    width: calc($circle-height / 2);

    &.start {
      margin-top: calc(($circle-height / 2) + $container-y-padding);
      border-left: 0.1428rem solid $green;
      border-top: 0.1428rem solid $green;
      border-top-left-radius: 0.5rem;
    }

    &.middle,
    &.end {
      height: calc(($circle-height / 1.7) + $container-y-padding);
      border-bottom: 0.1428rem solid $green;
      border-bottom-left-radius: 0.5rem;
    }

    &.end {
      border-left: 0.1428rem solid $green;
    }
  }

  .details {
    padding: $container-y-padding 0;

    .circle {
      background: $green;
      color: $white;
      width: $circle-height;
      height: $circle-height;
      border-radius: calc($circle-height / 2);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      font-size: 0.8571rem;
      font-weight: 600;
    }

    .title {
      font-size: 0.8571rem;
      font-weight: 500;
    }

    .label {
      font-size: 1.1429rem;
      font-weight: 600;
    }
  }
}
