@import 'assets/styles/_colors.scss';

.provisions-page {
  .general-tab {
    .title-row {
      display: flex;
      align-items: flex-end;
      padding: 0 2rem;
      margin: 2rem 0 1rem 0;

      & > * {
        margin-right: 1.0714rem;
      }
    }

    .general-content {
      display: flex;
      flex-direction: row;
      padding: 0 2rem;

      .internal-card {
        width: 85%;
        margin-bottom: 2rem;
      }

      .main-content {
        width: 66%;
      }

      .side-content {
        width: 34%;

        .btns-list {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
        }
      }
    }
  }

  .general-tab-title {
    margin: 2rem 0;
  }
}
