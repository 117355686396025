@import 'assets/styles/_colors.scss';
@import 'assets/styles/_metrics.scss';

// semantic-ui-react Search component css
.ui.search.search-bar {
  height: 2.429rem;
  width: 21.429rem;
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;

  .ui.disabled {
    opacity: 1;
  }
  .search-icon {
    position: absolute;
    top: 0.6429rem;
    left: 0.714rem;
    color: $blue-dark-grayish;
  }

  // part of semantic-ui-react search bar element
  input {
    width: 21.429rem;
    height: 2.429rem;
    top: 10.7143rem;
    font-weight: 400;
    font-size: 0.8571rem;
    line-height: 120%;
    color: $gray-very-darker;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 0.0714rem solid $grayish-magenta-light;
    box-sizing: border-box;
    border-radius: 0.429rem !important;
  }
  input::placeholder {
    color: $blue-dark-grayish;
  }
  input:focus {
    border-color: $orange;
    caret-color: $orange;
  }
  img {
    position: absolute;
    top: 0.714rem;
    right: 0.714rem;
  }
  .ui.active.loader {
    left: 1.14rem;

    &::after {
      border-color: $orange transparent transparent;
    }
  }
  > .results {
    display: none !important;
  }
}
