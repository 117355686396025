@import 'assets/styles/_colors.scss';
@import 'assets/styles/_metrics.scss';
@import 'layouts/Navbar/Navbar.scss';

.page-layout {
  padding: $nav-height 3.2em 0 3.2em;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  // border: solid 10px green; // <- PLEASE LEAVE THESE HERE UNTIL THIS THE PAGE HEADER CHANGES ARE COMPLETE

  // Padding top to give room for navbar
  @media (min-width: $big-monitors-min-width) and (max-width: $big-monitors-max-width) {
    padding-top: $nav-height-2;
  }

  // Padding top to give room for navbar
  @media (min-width: $tv-device-min-width) {
    padding-top: $nav-height-3;
  }

  .page-layout-header {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    // border: solid 10px pink; // <- PLEASE LEAVE THESE HERE UNTIL THIS THE PAGE HEADER CHANGES ARE COMPLETE

    .page-layout-header-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // border: solid 10px red; // <- PLEASE LEAVE THESE HERE UNTIL THIS THE PAGE HEADER CHANGES ARE COMPLETE

      .page-layout-header-title {
        display: flex;
        gap: 0.5rem;
        // border: solid 10px green; // <- PLEASE LEAVE THESE HERE UNTIL THIS THE PAGE HEADER CHANGES ARE COMPLETE

        .badge-container {
          align-self: center;
        }
      }

      .page-layout-header-toolbar {
        display: flex;
        align-items: center;
        gap: 1rem;
        // border: solid 10px blue; // <- PLEASE LEAVE THESE HERE UNTIL THIS THE PAGE HEADER CHANGES ARE COMPLETE
      }
    }

    .page-layout-header-filter {
      margin-top: 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      // border: solid 10px orange; // <- PLEASE LEAVE THESE HERE UNTIL THIS THE PAGE HEADER CHANGES ARE COMPLETE
    }

    .ui.button.layout-create-btn {
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      justify-content: center;
      padding: 0.929rem 1.643rem;
      width: 17.5rem;
      height: 2.429rem;
      border-radius: 0.286rem;
      font-weight: 700;
      font-size: 0.8571rem;
      line-height: 120%;
      text-transform: uppercase;
    }
  }

  & > main {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    &.flex-height {
      // used when you want children to not exceed screen height and have overflow-y
      height: 1rem;
    }
  }

  /*
   * page related layout definition
   *  -> page container
   *  -> page title
   *  -> page with tab title
   */
  .page-details-container {
    width: 100% !important;
    margin: 0 auto !important;

    .ui.header.title {
      font-weight: 600;
      font-size: 2.571rem;
      line-height: 3.357rem;
      color: $blue-very-dark;
    }
  }

  .page-with-tab-title {
    font-size: 1.571rem !important;
    line-height: 2.071rem !important;
  }

  .page-with-tab-title {
    font-size: 1rem !important;
    line-height: 2.071rem !important;
  }
}
