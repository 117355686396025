@import 'assets/styles/_colors.scss';

.p-multiselect {
  &:not(.p-disabled) {
    &:hover {
      border-color: $orange !important;
    }
    &.p-focus {
      border-color: $orange !important;
      box-shadow: 0 0 0 0.2rem $orange-soft !important;
    }
  }
}
