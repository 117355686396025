.editor-to-html {
  text-align: left;
  margin-left: 0.714rem;
  margin-bottom: 1.071rem;
  height: 100%;
  overflow-y: auto;
  max-height: 15rem;
  overflow-wrap: anywhere;
  ul,
  ol {
    position: relative;
    margin-left: 2.5rem;
  }
  ol {
    li:first-of-type {
      position: relative;
      left: -0.143rem;
    }
  }

  .modification-notes {
    height: 14.857rem;
    width: 100%;
    padding-right: 0.5rem;

    .title {
      font-size: 1.2rem;
      margin-top: 0rem;
      margin-bottom: 1rem;
      &.others {
        margin-top: 2rem;
      }
    }
    .inner-title {
      margin-top: 1rem;
    }
    .list-of-changes {
      margin-top: -1rem;
      margin-left: 1.7rem;
    }
  }
}
