@import 'assets/styles/_colors.scss';

.parameters-tables-listing {
  .constraint {
    .name {
      &[type='tables'] {
        color: $orange;
      }
    }
    .link {
      color: $blue-dark;
    }
  }
}
