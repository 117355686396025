@import 'assets/styles/_colors.scss';

.ui.modal.modal-template.progress-modal {
  border-radius: 0.571rem;
  width: 35rem;
  .content {
    border-radius: 0.571rem;
    background: $white;
    box-shadow: 0 0.286rem 0.571rem $blue-greyish;
    border-radius: 0.571rem;

    .title {
      margin: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 1.286rem;
      line-height: 1.643rem;
      color: $blue-very-dark;
    }

    .ui.active.progress.small {
      height: 0.286rem;
      padding: 0;
      margin: 0;
      margin-top: 2.857rem;
      margin-bottom: 2.643rem;

      .bar {
        height: 0.286rem;
        background: $orange;
      }
    }

    .doc-cancel-btn {
      width: 100%;
      height: 2.857rem !important;
      border-radius: 0.286rem !important;
      font-family: 'Urbanist';
      font-style: normal;
      font-weight: 600 !important;
      font-size: 0.857rem;
      line-height: 1.357rem;
      text-transform: uppercase;
      color: $blue-very-dark-grayish;
      background: transparent;
      margin: 0;
    }
  }
}
