@import 'assets/styles/_colors.scss';

.choices-list {
  .choices-option {
    position: relative;
    &.first {
      margin-left: 0.143rem;
    }
  }
}

.choices-highlighted {
  color: $orange;
  position: relative;
  left: 0.714rem;
}
