@import 'assets/styles/_colors.scss';

.graph-legend {
  height: 100%;
  border-left: 0.1428rem solid $gray-lighter;

  .graph-legend-container {
    display: flex;
    align-items: center;

    &:not(:last-of-type) {
      margin-bottom: 0.7rem;
    }

    .field.field-style {
      margin-bottom: -0.5rem;
    }
  }
}
