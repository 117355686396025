@import 'assets/styles/_colors.scss';

.transaction-setup-content {
  .grid {
    margin: 0;
  }

  & > .grid {
    &:not(:last-of-type) {
      padding-bottom: 0.5rem;
      border-bottom: 0.07142rem solid $grayish-magenta-light;
    }

    .row-label {
      padding-top: 1.2rem;
      color: $blue-very-dark;
      font-size: 1.2857rem;
      font-style: normal;
      font-weight: 600;
    }
  }
}
