.ui.grid > .row.transaction-link {
  margin-top: -1.7rem;
  padding-bottom: 0;
  position: relative;
  left: 0.8rem;
  .transaction-name {
    font-size: 1rem;
    .link {
      text-decoration: underline;
    }
  }
}
