@import 'assets/styles/_colors.scss';
@import 'assets/styles/_metrics.scss';

.review-form {
  min-width: 12rem;
}

.review-box-template {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .review-checkbox {
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 600;
    font-size: 1.1428rem;
    line-height: 1.7142rem;
  }

  .review-text {
    font-style: normal;
    font-weight: 400;
    font-size: 0.8571rem;
    line-height: 140%;
  }
}
