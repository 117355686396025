.ui.modal.modal-template {
  &.node-integrity-modal {
    width: 55rem;
    height: 11rem;
    top: 6rem;
    .issue-title {
      font-size: 1rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .change-title {
      font-size: 1.4rem;
    }
    .editor-to-html {
      display: block;
      max-height: 25rem;
    }
    u {
      text-decoration: underline;
    }
  }
}
