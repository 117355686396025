@import 'assets/styles/_colors.scss';

.ui.page.dimmer.modals {
  background: $blue-saturated-dark;
  backdrop-filter: blur(0.1071rem);

  .ui.modal.confirmation-modal {
    border-radius: 0.571rem;
    width: 31.429rem;

    .content {
      border-radius: 0.571rem;
      background: $white;
      box-shadow: 0 0.286rem 0.571rem $blue-greyish;
      border-radius: 0.571rem;

      .title {
        font-weight: 600;
        font-size: 1.1rem;
        line-height: 1.643rem;
        color: $blue-very-dark;
      }
    }

    .btn-container {
      margin-top: 2.2857rem;
      display: flex;
      justify-content: space-between;
    }

    .template-row {
      width: 98.8%;
      margin-top: 1rem;

      textarea {
        padding: 0.78571429em 1em;
        width: 100%;
      }
    }

    .btn-container {
      margin-top: 2.2857rem;
      display: flex;
      justify-content: space-between;
    }
  }
}
