@import 'assets/styles/_colors.scss';

.portfolio-form {
  .portfolio-detail {
    border-radius: 0.857rem;
    &.empty {
      text-align: center;
    }
  }

  .portfolio-actions {
    text-align: end;
    align-self: center;
  }
}
