@import 'assets/styles/_colors.scss';

/*
 Every chart type needs to have a unique id 
 for referencing and setting individual styles
*/
#chartjs-tooltip-or {
  min-width: 10.3rem;
  height: 2.571rem;
  background: $white;
  box-shadow: 0 0.286rem 1.1429rem $blue-greyish;
  border-radius: 0.571rem;
  font-family: 'Urbanist';
  font-style: normal;
  font-size: 0.8571rem;
  display: flex;
  justify-content: center;

  .inner-html {
    padding: 0.714rem;

    .first {
      font-weight: 600;
      color: $blue-very-dark;
      margin-bottom: 0.571rem;
      &[index='2'] {
        margin-top: 0.1429rem;
      }
      .circle {
        display: inline-flex;
        position: relative;
        top: -0.0714rem;
        margin-right: 0.286rem;
      }
      .text-after {
        color: $blue-desaturated-dark;
        font-weight: 400;
      }
    }
  }
}
