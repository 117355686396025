@import 'assets/styles/_metrics.scss';

.under-construction-template {
  .under-construction-header {
    font-size: 2.4rem !important;
  }

  .back-btn {
    svg {
      position: relative;
      top: -0.02rem;
      left: -0.4rem;
    }
  }
}

@media (max-width: $xl-mid-device-min-width) {
  .under-construction-template {
    .under-construction-header {
      font-size: 2.2rem !important;
    }
  }
}
