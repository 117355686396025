@import 'assets/styles/_colors.scss';

/* **********************************************************
 * Definition of the classes related to the information icons
 * **********************************************************/
.info-bubble {
  color: $orange;
  margin-left: 0.1429rem;
  position: relative;
  top: 0.286rem;
  width: 1.1429rem;
  height: 1.1429rem;
}
