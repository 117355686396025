@import 'assets/styles/_metrics.scss';

.ui.grid > .row > .column.structure-provision {
  .special-checkbox {
    position: relative;
    left: 0.95rem;
    width: 93.5%;
    margin-top: auto;
  }
}

@media (min-width: $big-monitors-min-width) {
  .ui.grid > .row > .column.structure-provision {
    .special-checkbox {
      width: 95.6%;
    }
  }
}

@media (max-width: $xl-device-min-width) {
  .ui.grid > .row > .column.structure-provision {
    .special-checkbox {
      width: 88.6%;
    }
  }
}

.provisions-create-view {
  .column.outlined.container.guidelines-box {
    height: 32.5rem;
  }
}
