@import 'assets/styles/_colors.scss';

.ui.modal.modal-template {
  &.history-modal {
    width: 69.714rem;
    height: 40.643rem;

    .content {
      padding-bottom: 0.5rem;
      .description {
        max-height: 40rem;
        overflow-y: auto;
      }

      .btn-row {
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 0.0714rem solid $grayish-magenta-light;
        padding-top: 1.5rem;

        .ui.button.history-cancel-btn {
          height: 2.857rem;
          width: 22.857rem;
        }
      }
    }

    .left-header {
      font-size: 1rem;
      &.answer-history {
        position: fixed;
      }
    }

    .question-text {
      font-size: 1.143rem;
      position: relative;
      top: -0.2rem;
      left: -1.6rem;
    }

    .question-note-text {
      font-size: 1rem;
      font-weight: 400;
      position: relative;
      margin-top: -0.5rem;
      left: -1.6rem;
    }

    .ui.grid {
      > .row.question-row {
        margin-top: 1rem;
      }

      > .answer-row {
        max-height: 21rem;
        height: 21rem;
        overflow-y: auto;

        &.mcq-type {
          height: 16.2rem;
        }
        .user-time-details {
          color: $blue-desaturated-dark;
          font-size: 0.857rem;
          &[first='true'] {
            position: relative;
            top: -1rem;
          }
        }
        .answer-value {
          font-size: 0.857rem;
          margin-top: 0.5rem;
          &[first='true'] {
            position: relative;
            top: -1rem;
          }
          .cleared-answer {
            color: $orange;
            font-weight: 600;
            cursor: default;
          }

          a {
            color: $black;

            &:hover {
              font-weight: 600;
            }
          }
        }
        .answers-list {
          margin-top: 1rem;
          .answer-row-details {
            &:last-of-type {
              margin-bottom: -2rem;
            }
            &[single-value='true'] {
              margin-bottom: -3rem;
              &[has-inventory='true'] {
                margin-bottom: -5rem;
              }
            }
          }
          .answer-columns {
            border-left: 0.071rem solid $grayish-magenta-light;
            margin-top: -0.2rem;
          }
        }
      }
    }

    .options-parameter-mcq {
      margin-top: -1rem;
      margin-left: -1.4rem;
      .ui.checkbox input[disabled] ~ label {
        opacity: 1;
      }
    }

    .radio-options {
      margin-bottom: 0.4rem;
    }

    .parameter-history-header-badge {
      position: relative;
      top: -2.1rem;
      left: 16rem;
      margin-right: 1rem;
    }

    .flex-header {
      display: flex;
    }
  }
  .none-option {
    position: absolute;
    top: -1rem;
    left: 20rem;
  }
}

// class definition for the dots in the history line
//  -> blue by default
//  -> orange for the initial position
.history-dots {
  display: inline-block;
  position: relative;
  left: -0.27rem;
  top: 1rem;

  &[first-dot='true'] {
    top: 0;
    &[has-inventory='true'] {
      top: 0rem;
    }
  }

  &[last-dot='true'] {
    background: $orange;
  }

  &[has-inventory='true'] {
    top: 2.2rem;
  }
}
