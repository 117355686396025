@import 'assets/styles/_colors.scss';

.entity-creator-header {
  position: relative;
  overflow: hidden;
  border-bottom: 0.14rem solid $blue-very-dark-grayish;

  .header-img {
    position: absolute;
    top: 0.3rem;
    opacity: 0.4 !important;

    &.right {
      right: 0;
    }
  }

  .z-index-1 {
    z-index: 1;
  }
}
