@import 'assets/styles/_colors.scss';

.ui.grid.graph-without-line.occupancy-rate {
  margin-top: 3rem;
}

.graph-tooltip-container {
  .inner-html.occupancy-rate {
    width: 100%;
    color: $blue-very-dark;

    .title {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.4285rem;
      border-bottom: 0.071rem solid $gray-light;
      padding-bottom: 1rem;
    }

    h3 {
      font-weight: 400;
      font-size: 0.8571rem;
      line-height: 140%;
      color: $blue-very-dark;
      padding: 0;
      margin: 0;
      padding-bottom: 1rem;
    }

    .table-container {
      max-height: 16rem;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 0.429rem;
      }

      &::-webkit-scrollbar-track {
        background: $orange-light-grayish;
      }

      &::-webkit-scrollbar-thumb {
        background: $orange;
        border-radius: 0.429rem;
        height: 5.214rem;
      }

      table.ui.table.dashboard-table.occupancy-rate {
        border-bottom: none;
        border-right: none;
        border-left: none;
        margin: 0 !important;

        thead {
          th {
            position: sticky;
            top: 0;
            z-index: 2;
            background-color: $gray-very-light-v3;
          }
        }

        th,
        td {
          color: $blue-very-dark;
          font-size: 0.8571rem !important;
          line-height: 1rem;
          border: none;

          a {
            text-decoration: underline;
            font-style: italic;
          }

          &.align-right {
            text-align: right;
          }
        }

        .green {
          color: $green;
        }
      }
    }
  }
}
