@import 'assets/styles/_colors.scss';

.notification-listing-section {
  padding-left: 0.5714rem;

  & > .text {
    text-align: center;
    font-size: 1rem;
    color: $blue-very-dark-grayish;
  }
}
