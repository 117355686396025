@import 'assets/styles/_colors.scss';

.ui.checkbox.check {
  &.toggle {
    input:checked ~ label:before {
      background-color: $orange !important;
    }

    input:disabled:not(:checked) ~ label:hover:before {
      background-color: $gray-very-light-v2;
    }
  }

  &.box input ~ label:before {
    border: 0.0714rem solid $blue-dark-grayish;
    border-radius: 0;
  }

  &.radio input ~ label:after {
    background-color: $orange;
    position: absolute;
    top: 0;
  }

  input:checked ~ label:after {
    margin-top: 0.0714rem;
  }
}
