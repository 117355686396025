@import 'assets/styles/_colors.scss';

.card-view-container {
  display: flex;
  flex-direction: column;
  flex: 1;

  .empty-category-card {
    cursor: pointer;
    height: 9.286rem;
    box-shadow: inset 0rem 0rem 1.714rem $gray-very-light-v4;
    border-radius: 0.857rem;
    margin-top: 1.5rem;
    width: 100%;
    text-align: center;

    .plus-position {
      top: 0.129rem;
    }
  }

  .card-body {
    .card-body-template {
      padding: 0.4rem 0.4rem;
    }
  }

  .card-header .card-header-template {
    display: inline-flex;
    width: 100%;
    .title-template {
      width: calc(100% - 1rem);
      overflow: visible;
      .name {
        width: 100%;
        padding: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
      }
    }
  }
}
