@import 'assets/styles/_colors.scss';

.stacking-plan-sections {
  .area-head {
    font-style: normal;
    font-weight: 600;
    font-size: 1.286rem;
    line-height: 1.643rem;
  }

  .empty-area-title {
    border-radius: 0.571rem 0.571rem 0 0;
    div {
      font-weight: 700;
      font-size: 0.857rem;
      line-height: 1rem;
    }
  }

  .empty-area-description div {
    font-style: normal;
    font-weight: 400;
    font-size: 0.857rem;
    line-height: 1.357rem;
  }

  .w-100 {
    width: 100%;
  }
  .current-lease {
    .row > .column {
      padding-top: 0.25rem;
    }
  }
}
