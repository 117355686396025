@import 'assets/styles/_colors.scss';

.field.field-style {
  // CSS for library react-phone-input-2
  // Phone input field and country code
  .react-tel-input {
    input {
      font-size: 0.8571rem !important;
      color: $blue-very-dark !important;
      font-weight: 400 !important;
      line-height: 140% !important;
    }
    input.form-control {
      margin-left: 3.571rem;
      width: calc(100% - 3.571rem);
    }
  }

  &.phone-input.custom-disabled {
    background: inherit !important;
  }
}
