@import 'assets/styles/_colors.scss';

.time-content-wrapper {
  display: flex;

  & > div:not(:last-of-type) {
    margin-right: 0.5rem;
  }

  .time-content-label {
    display: block;
    font-size: 0.8571rem;
    line-height: 1rem;
    padding-bottom: 0.429rem;
    padding-left: 0.1rem;
  }

  div:last-of-type {
    align-items: baseline;
    .ui.dropdown.time-format-dropdown {
      margin-top: auto;
      display: inline-flex;
      width: 5.714rem;
    }
  }
}
