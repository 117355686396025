@import 'assets/styles/_colors.scss';

// CSS for react date picker overriding the global reactDatePicker css
.field.field-style .pos-rel {
  // React Date picker wrapper class
  .react-datepicker-wrapper {
    width: 100%;
    input {
      line-height: 140% !important;
      height: 2.714rem;
      cursor: pointer;
      padding-left: 1rem;
      width: 100%;
      &:focus {
        outline: none;
      }
    }

    .react-datepicker-ignore-onclickoutside {
      border: 0.0714rem solid $orange !important;
    }
  }

  .react-datepicker__tab-loop + svg {
    color: $orange;
  }

  // Pop up calender class
  .react-datepicker {
    border: none !important;
    background: $white;
    box-shadow: 0 0.286rem 1.1429rem $blue-greyish;
    border-radius: 0.571rem;
  }

  // Calender icon in date input field
  .date-icon {
    position: absolute;
    right: 0.8571rem;
    top: 0.8571rem;
    color: $gray;
    cursor: pointer;
  }

  .react-datepicker__close-icon {
    &::after {
      background-color: $gray;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    margin-right: 2rem !important;
  }
}

.field.field-style {
  &.error-date-field,
  .error-date-field {
    input {
      border: 0.0714rem solid $red-soft !important;
    }

    .date-icon {
      color: $red-soft;
    }
  }
}
