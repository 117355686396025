@import 'assets/styles/_colors.scss';

.card-column-container {
  display: flex;
  flex-direction: column;
  height: inherit;

  & > span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
  }

  .card-column-body {
    display: flex;
    flex-direction: column;
    width: inherit;
  }

  .column-agroup {
    & > :not(:first-child) {
      margin-top: -3.2rem;
    }
  }
}
