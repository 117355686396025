.ui.modal.modal-template.discussion-modal {
  border-radius: 0.8571rem;
  width: 35rem;
  .field.field-style label {
    width: 100%;
    font-size: 1.2rem !important;
  }
  .ui.input {
    width: 100%;
  }
  .header-image {
    left: 11.714rem;
  }
  .header h2 {
    z-index: 100;
    position: relative;
  }
}
