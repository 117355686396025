@import 'assets/styles/_colors.scss';

.contract-ongoing-transaction {
  border-radius: 0.857rem;
  padding: 1.143rem;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 1rem;

  h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 0.857rem;
    line-height: 1.5rem;
    text-transform: capitalize;
    color: $blue-desaturated-dark;
  }
  .ui.grid > .row {
    padding-top: 0;
    padding-bottom: 0;
  }
  .ui.grid > .row > .column {
    padding: 0.25rem;
  }
  .status {
    padding: 0.143rem 0.429rem;
    border-radius: 0.286rem;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.929rem;
    line-height: 1.143rem;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
  a {
    font-style: italic;
    cursor: pointer;
    font-weight: 400;
    font-size: 0.857rem;
    line-height: 1.357rem;
    text-decoration-line: underline;
    color: $blue-royal;
    svg {
      margin-left: 0.4rem;
    }
  }
}
