.ui.modal.modal-template.link-to-parameters-modal {
  height: 48rem;
  .field.field-style label {
    font-size: 1.2rem !important;
    font-weight: 600;
  }
  .title {
    font-size: 1.2rem;
  }
  .tab-container-header {
    display: none;
  }
  .ui.multiple.search.dropdown > input.search {
    height: auto;
  }
  table tbody {
    display: block;
  }

  table thead,
  table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  .save-btn {
    right: 8.5rem;
  }

  &.with-temporary-table {
    .card-parameters {
      min-height: 23.5rem;
    }
  }
  .card-parameters {
    min-height: 35.5rem;
  }
}

@media only screen and (min-width: 1200px) {
  .ui.modal.modal-template.link-to-parameters-modal {
    width: 95rem;
  }
}

@media only screen and (max-height: 920px) {
  .ui.modal.modal-template.link-to-parameters-modal {
    height: 42rem;
  }
}
