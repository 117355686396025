@import 'assets/styles/_colors.scss';

.tabs-with-add-btn {
  position: relative;

  .delete-contact-btn {
    display: inline-block;
    cursor: pointer;
    border-radius: 0.5rem;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.0714rem;

      svg {
        transform: scale(1.2);
      }

      .text {
        display: inline-block;
        margin-left: 0.6429rem;
        margin-top: 0.0714rem;
        font-weight: 600;
        font-size: 1rem;
        line-height: 120%;
        display: flex;
        align-items: center;
      }
    }

    &:hover {
      background: $white-smoke;
    }
  }
}
