@import 'assets/styles/_colors.scss';

.final-tenant-send-and-receive {
  position: relative;
  top: 4rem;
  margin-bottom: 4rem;
  .move-up {
    position: relative;
    top: -2.3rem;
  }
}

.withdraw-request {
  float: right;
  font-size: 1.3rem;
  color: $red;
  cursor: pointer;
}

.version-number {
  top: 0rem;
  position: relative;
  &.inside-tenant {
    position: relative;
    top: 0.5rem;
  }

  .badge-container {
    height: 1.5rem;
  }
}

.deviation-btn {
  top: 0.95rem;
  position: relative;
  z-index: 1;
}
