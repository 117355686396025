@import 'assets/styles/_colors.scss';

.comparison-type-select-ball {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  position: relative;
}

.comparison-type-check-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.parameter-row {
  display: flex;
  align-items: center;

  gap: 1rem;
}
