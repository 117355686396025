@import 'assets/styles/_colors.scss';
@import 'assets/styles/_metrics.scss';

.discussion-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 1rem;

  .discussion-header {
    border-bottom: 0.143rem solid rgba(34, 36, 38, 0.15);

    .edit-btn {
      font-size: 1.4rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        border-radius: 0.2857rem;
        background: $gray-lighter;
      }
    }

    .title {
      font-size: 1rem;
    }

    .status {
      border-radius: 0.5rem;
      min-width: 6rem;
      width: fit-content;

      &.status-resolved {
        background-color: $green-soft;
        color: $white;
      }
    }
  }

  .discussion-tabs-wrapper {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 30rem;
    .tabs.discussion-tab {
      .ui.secondary.menu {
        a.item {
          font-size: 1.06rem !important;
          padding-bottom: 0 !important;
          &.external-correspondence-tab {
            color: $red-soft !important;
          }
        }
      }

      .ui.segment.active.tab {
        padding: 0 !important;
      }
    }
  }

  .date {
    display: flex;
    flex-direction: row;
    color: $gray-dark;
  }
  .date:before,
  .date:after {
    content: '';
    flex: 1 1;
    border-bottom: 0.071rem solid $gray-light;
    margin: auto;
  }
  .date:before {
    margin-right: 0.714rem;
  }
  .date:after {
    margin-left: 0.714rem;
  }
  .message-type-selector {
    display: inline-flex;
    width: 50%;
    span {
      background: $gray-very-light-v4;
      border: 0.143rem solid rgba(34, 36, 38, 0.15);
      display: flex;
      border-radius: 0.285rem;
      width: 100%;
      span {
        width: 100%;
        color: red;
        border: none;
      }
      .ui.button.type-selector-btn {
        background: transparent;
        height: 1rem;
        width: 8rem !important;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0;

        &.active {
          border-bottom: 2px solid $orange;
        }
      }
    }
  }
  .discussion-list-row {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: $gray-lighter;
    }

    .general-discussion-indicator {
      background: $white;
      height: 1.6rem;
      width: 1.6rem;
      border-radius: 0.8rem;
      border: 0.0714rem solid $black;
      font-weight: 900;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
  .message-box-editor {
    height: 14.7857rem;

    .tox .tox-tbtn {
      transform: scale(0.8) !important; /* you can set the scale that you want */
      height: unset !important;
      width: unset !important;
    }
  }
  .message-box {
    height: 6rem;
    border-left: 0;
    border-right: 0;
    border-top: 0.143rem solid rgba(34, 36, 38, 0.15);
    border-bottom: 0.143rem solid rgba(34, 36, 38, 0.15);
    .text-box {
      width: 88%;
      height: 100%;
      border: 0;
      resize: none;
    }
    textarea {
      height: 6rem !important;
      width: 100% !important;
      padding: 0.5rem;
    }
  }
  .message-icon {
    float: right;
    width: 2.8rem;
    height: 2.8rem;
    background: $gray-lighter;
    color: $gray-very-dark;
    border-radius: 15%;
    position: absolute;
    right: 0.5rem;
    &.amendment {
      position: relative;
      top: -3.6rem;
      right: -0.55rem;
    }
    &.cursor {
      cursor: pointer;
    }
  }
}

.ui.popup.popup-title {
  .ui.button.cancel-btn {
    background: $red-soft;
    color: $white;
    width: 10rem;
    height: 2rem;
  }
  .ui.button.save-btn {
    background: $blue;
    color: $white;
    width: 10rem;
    height: 2rem;
  }
  .title-input {
    width: 20.5rem;
  }
}

.message {
  border-radius: 0.5rem;
  width: fit-content;
  max-width: 25.5rem;
  background: $gray-very-light-v4;
  .person {
    font-size: 0.8rem;
    display: inline-flex;
    align-items: center;
    .time {
      font-size: 0.6rem;
      color: $gray-very-dark;
    }

    .link {
      color: $orange;
      font-style: italic;
      text-decoration: underline;
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  .text {
    font-size: 0.8rem;
    word-wrap: break-word;
    white-space: pre-line;
    font-family: 'Urbanist', 'Roboto', sans-serif;
  }

  &.amendment-message {
    width: calc(100% - 0.75rem);
    max-width: none;

    .amendment-indicator {
      background: $white;
      height: 2rem;
      width: 2rem;
      border-radius: 1rem;
      border: 0.0714rem solid $black;
      font-weight: 900;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: default;

      &.linked {
        background: $orange;
        border: none;
        color: $white;
      }
    }

    .text {
      border-bottom: 0.0714rem solid $black;
      border-top: 0.0714rem solid $black;
    }
  }
  &.user-message {
    .person {
      .name {
        color: $pink;
      }
    }
  }
  &.other-user-message {
    position: relative;
    left: 0.85rem;
    .person {
      .name {
        color: $blue;
      }
    }
  }
}

.list-of-channels {
  overflow-y: auto;

  .resolve-channel {
    color: $green;
  }
}

.ui.modal.amendment-modal {
  width: 45rem;

  .ui.grid {
    .ui.input {
      width: 100%;
    }
  }

  .btn-row {
    display: flex;
    gap: 1rem;
  }
}

.ui.popup.visible.transaction-message-popup {
  .menu {
    list-style: none;

    li {
      font-size: 1rem;
      display: flex;
      align-items: center;

      &:hover:not([aria-disabled='true']) {
        background: $gray-very-light-v2;
        cursor: pointer;
      }

      &[aria-disabled='true'] {
        cursor: default;
        color: $gray;
      }

      svg {
        width: 1.2rem;
        height: 1.2rem;
      }
    }
  }
}

@media (min-width: $big-monitors-min-width) {
  .discussion-wrapper.inside-approvals .message-icon {
    top: 0rem !important;
  }
}
