@import 'assets/styles/_colors.scss';

.count-box {
  top: -0.1rem;
  position: relative;
  background-color: $orange-very-dark;
  border-color: $orange-very-dark;
  color: $white;
  border-radius: 0.28571429rem;
  font-size: 0.86rem;
  width: 1.5rem;
  font-weight: 700;
  display: inline-block;
  text-align: center;
}
