@import 'assets/styles/_colors.scss';

.date-cell-custom-header {
  margin: 0.714rem;

  .ui.button {
    max-height: 2rem;
    max-width: 8rem;
    margin-bottom: 0.9rem !important;
    text-transform: uppercase;

    &:hover {
      background: $orange;
      color: $white;
    }
  }

  .row {
    display: flex;
    justify-content: center;

    svg.iconify {
      &.left-arrow,
      &.right-arrow {
        top: -0.005rem;
        cursor: pointer;
      }
    }
  }
}
