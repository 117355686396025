@import 'assets/styles/_colors.scss';

.number-form {
  .ui.input::before {
    content: var(--unit);
    font-family: Urbanist;
    line-height: 140%;
    color: $blue-dark-grayish;
    font-style: normal;
    font-weight: 400;
    font-size: 0.8571rem;
    top: 30%;
    position: absolute;
    right: 1rem;
  }
  .ui.input {
    &.NUMBER {
      &::before {
        content: '';
      }
    }
    &.NUMBER_PERCENT {
      &::before {
        content: '%';
      }
    }
  }
}
