@import 'assets/styles/_colors.scss';

.notification-filter-section {
  border-top: 0.0714rem solid $gray-lighter;
  border-bottom: 0.0714rem solid $gray-lighter;
  height: 3.7142rem;
  display: flex;
  align-items: center;
  background-color: $gray-very-light-v4;
  padding: 0 0.5714rem;
  gap: 1.1428rem;
  position: sticky;
  top: 0;
  z-index: 2;

  & > .notification-filter {
    height: 2.35rem;
    .p-multiselect-label-container {
      display: flex;
      align-items: center;
    }
  }

  .archive,
  .delete {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.1rem 0.3rem;

    &.red {
      color: $red-soft;
    }

    &.orange {
      color: $orange;
    }

    &:hover {
      background-color: $white;
      font-weight: 600;

      svg {
        path {
          stroke-width: 0.05rem;
        }

        &.thicker {
          path {
            stroke-width: 0.25rem;
          }
        }
      }

      &.red {
        svg {
          path {
            stroke: $red-soft;
          }
        }
      }

      &.orange {
        svg {
          path {
            stroke: $orange;
          }
        }
      }
    }

    svg {
      position: relative;
      top: -0.1rem;
    }
  }

  .white-bg {
    background: $white;
  }
}
