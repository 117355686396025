@import 'assets/styles/_colors.scss';

.ui.button.translate-to-contract-btn {
  position: absolute;
  right: 0;
  margin: 0;
  z-index: 1;
}

.translation-report-container {
  max-height: 60vh;
  overflow-y: auto;
}

.not-available {
  background-color: $orange;
  color: $white;
  width: fit-content;
  padding: 0.5rem;
  position: relative;
  top: -1.5rem;
  border-radius: 0.5rem;
}

.contract-view-tab {
  .ui.grid > .row.transaction-link {
    display: none;
  }
  .ui.grid.general-form {
    margin-top: -1rem;
  }
}

.tabs.contract-page {
  .badge-container {
    width: fit-content;
  }
}

/* TO BE REMOVED */
.ui.button.ai-question-btn {
  width: fit-content;
  position: absolute;
  width: 10rem;
  right: 15rem;
  background: transparent;
}
