@import 'assets/styles/_colors.scss';

.notification-listing-section-row {
  display: flex;
  gap: 0.7857rem;

  .content {
    padding: 0.5714rem;
    position: relative;
    display: flex;
    flex-grow: 1;
    align-items: center;
    gap: 0.7857rem;

    &.unread {
      background: $orange-very-light-grayish;
      border-radius: 0.5714rem;

      .info-group {
        &::before {
          content: '';
          position: absolute;
          height: 0.4285rem;
          width: 0.4285rem;
          border-radius: 0.2142rem;
          background-color: $orange;
          top: 1.2rem;
        }

        .info-header {
          margin-left: 0.8571rem;
        }
      }
    }

    &:hover {
      background: $gray-very-light-v4;

      .table-actions-menu {
        .ui.button {
          background: $white !important;
        }
      }
    }

    .pointer {
      cursor: pointer;
    }

    .text {
      font-size: 1rem;
      font-weight: 400;
      color: $blue-desaturated-dark;

      &.bold {
        font-weight: 600;
      }

      &.bold-orange {
        font-weight: 700;
        color: $orange;
      }

      &.blackish {
        color: $blue-very-dark;
      }

      &.accent {
        color: $orange;
      }
    }

    .info-group {
      flex-grow: 1;

      .info-header {
        display: flex;
        align-items: center;
      }

      .info-details {
        margin-top: 0.8571rem;
        display: flex;
        align-items: center;
        gap: 0.8571rem;
      }
    }
  }
  .table-action-menu {
    background-color: $white;
  }
}

.notification-list-row-menu-item {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  padding: 0.3rem;
  padding-left: 1rem;

  &:hover {
    background: $gray-very-light-v4;
    cursor: pointer;
  }
}
