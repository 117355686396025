// Blue Variants
$blue: #2c66c0;
$blue-very-soft: #a6ccf7;
$blue-light-grayish: #dfe8f6;
$blue-light-grayish-v2: #dbecfe;
$blue-dark-grayish: #9ba2ac;
$blue-very-dark-grayish: #4f5c6e;
$blue-desaturated-dark: #677b97;
$blue-light: #407cd6;
$blue-dark: #0000ff;
$blue-very-dark: #041630;
$blue-royal: #1852ac;
$blue-greyish: #04163026; // rgba(4, 22, 48, 0.15)
$blue-saturated-dark: #01050cb2; // rgba(1, 5, 12, 0.70)

// Orange Variants
$orange: #e69701;
$orange-bright: #feb935;
$orange-very-dark: #f2711c;
$orange-light: #efbd5d;
$orange-soft: #f6dba6;
$orange-very-light-grayish: #fdf8ef;
$orange-light-grayish: #fbebcc;

// Green Variants
$green: #0fb239;
$green-soft: #65cd81;
$green-lime: #c3eccd;
$green-pale-lime: #deffde;
$green-very-soft-cyan-lime: #9fe0b0;
$green-light-grayish-cyan-lime: #e2f7e8;

// Red Variants
$red: #ff0000;
$red-very-dark-grayish: #615e5e;
$red-soft: #e94d58;
$red-light-soft: #df434e;
$red-very-pale: #ffd8db;
$red-light-grayish: #ecebeb;
$red-tomato: #ff6347;
$red-light: #ff7070cc; // rgba(255, 112, 112, 0.8)

// Violet Variants
$violet-vivid: #6d22ff;

// Magenta Variants
$magenta-desaturated: #c885c8;
$magenta-light: #ead0ea;

// Black Variants
$black: #000000;
$black-light: #8a8a8a14; // rgba(138, 138, 138, 0.08)
$black-shadow: #00000026; // rgba(0, 0, 0, 0.15)

// Gray Variants
$gray: #bdbdbd;
$gray-light: #d4d4d4;
$gray-light-v2: #bababa4d; // rgba(186, 186, 186, 0.3)
$gray-lighter: #e1e0e182; // rgba(225, 224, 225, 0.51)
$gray-whitish-light: #fcfcfc;
$gray-very-light: #eeeeee;
$gray-very-light-v2: #f2f2f2;
$gray-very-light-v3: #e0e0e0;
$gray-very-light-v4: #f8f8f8;
$gray-very-light-v5: #dfdddd29; // rgba(223, 221, 221, 0.16)
$gray-blackish: #1d1d1d;
$gray-dark: #828282;
$gray-dark-v2: #495057;
$gray-very-dark: #4f4f4f;
$gray-very-darker: #333333;
$grayish-magenta-light: #e1e0e1;
$gray-very-light-v6: #bbbbbb;
$gray-very-light-v7: #e0dcdc;

//Pink Variants
$pink: #b45bb4;
$pink-soft: #ead0ea;
$pink-bright: #e03997;

// White Variants
$white: #ffffff;
$white-smoke: #f5f5f5;

// Purple Variants
$purple: #9b359b;

// Yellow Variants
$yellow: #efd90f;
$yellow-light: #ffe70a66; // rgba(255, 231, 10, 0.4)

// Gold
$gold-harvest: #e6970199; // rgba(230, 151, 1, 0.6)

// Almond
$almond-blanched: #fbebcccc; // rgba(251, 235, 204, 0.8)

// Teal
$teal: #008080;
