.condition-panel {
  .p-panel-header {
    border-radius: 0.28571429rem;

    &:has(+ .p-toggleable-content) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .p-toggleable-content {
    .p-panel-content {
      padding: 0px;
    }
  }
}
