@import 'assets/styles/_colors.scss';

.ui.popup.tooltip {
  min-height: 0.714rem;
  max-height: 30rem;
  overflow-y: auto;
  background: $white;
  box-shadow: 0 0.286rem 1.143rem $blue-greyish;
  border-radius: 0.571rem;
  border: none;
  padding: 1.143rem;

  .content {
    min-height: 1rem;
    max-height: 20rem;
    min-width: 20rem;
    max-width: 40rem;
    padding-right: 0.5rem;
    overflow-y: auto;

    * {
      box-sizing: border-box;
      list-style-position: inside;
    }

    &::-webkit-scrollbar {
      width: 0.429rem;
    }

    &::-webkit-scrollbar-track {
      background: $orange-light-grayish;
    }

    &::-webkit-scrollbar-thumb {
      background: $orange;
      border-radius: 0.429rem;
      height: 5.214rem;
    }
  }
}
