@import 'assets/styles/_colors.scss';

.dashboard-tab {
  font-family: 'Urbanist';
  font-style: normal;

  .infobar-header {
    font-family: inherit;
    font-style: inherit;
    font-weight: 800;
    font-size: 2rem;
    line-height: 2.357rem;
    display: flex;
    align-items: center;
    color: $blue-very-dark;
  }
}
