@import 'assets/styles/_colors.scss';
@import 'assets/styles/_metrics.scss';

.approval-level {
  align-items: center;
  border-radius: 0.286rem;
  display: inline;
  display: flex;
  font-size: 0.929rem;
  font-style: normal;
  font-weight: 600;
  justify-content: center;
  letter-spacing: 0.02em;
  line-height: 1.1429rem;
  padding: 0.286rem;
  text-align: center;
  text-transform: uppercase;
  width: 5.7857rem;
}
