@import 'assets/styles/_colors.scss';
@import 'assets/styles/_metrics.scss';

.p-datatable {
  div.p-datatable-header {
    display: flex;
    align-items: center;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-width: 0.125rem;
    background: $white-smoke;
    border: 0.125rem solid $grayish-magenta-light;
    color: $blue-very-dark;
  }

  // Note that 'no-header' is a custom class to style the table top border radius when header is absent
  &.no-header {
    .p-datatable-thead {
      > tr > th {
        border-top: 0.125rem solid $grayish-magenta-light;

        &:first-of-type {
          border-top-left-radius: 0.5rem;
        }

        &:nth-last-child(2) {
          border-top-right-radius: 0.5rem;
          border-right: 0.125rem solid $grayish-magenta-light;
        }

        &:last-of-type {
          border: none;
        }
      }
    }
  }

  // Note that 'p-datatable-thead' is a class used in the prime-react DataTable component
  .p-datatable-thead {
    height: 4rem;

    > tr > th {
      padding: 0 1rem;

      &[role='columnheader'] {
        background: $white-smoke;
        color: $blue-very-dark;

        &.p-highlight {
          color: inherit;

          .p-sortable-column-icon {
            color: inherit;
          }
        }

        &.p-sortable-column {
          box-shadow: none;

          &:hover {
            background: $grayish-magenta-light;

            &.p-highlight {
              color: inherit;

              .p-sortable-column-icon {
                color: inherit;
              }
            }

            button:hover {
              background: $white-smoke;
              box-shadow: 0 0 0 0.2rem $white-smoke;
            }
          }
        }

        button {
          margin-left: 0.5rem;

          &.p-column-filter-menu-button-active {
            color: $orange;
            background: $orange-light-grayish;
          }

          &:focus {
            box-shadow: 0 0 0 0.2rem $white;
            background: $white;
          }
        }
      }

      &:first-child {
        border-left: 0.125rem solid $grayish-magenta-light;
      }

      &:last-child {
        border-right: 0.125rem solid $grayish-magenta-light;
        padding: 0 !important;
        margin: 0;
      }
    }
  }

  // Note that 'p-sortable-column' is a class used in the prime-react DataTable component
  .p-sortable-column {
    padding: 0;
  }

  // Note that 'p-datatable-header' is a class used in the prime-react DataTable component
  .p-datatable-header {
    background: $white-smoke;
    border: 0.125rem solid $grayish-magenta-light;
    align-items: center;
    color: $blue-very-dark;
    display: flex;
    font-weight: 700;
    height: 4rem;
    padding: 1rem 1rem;
  }

  // Note that 'p-dropdown' is a class used in the prime-react DataTable component
  .p-dropdown {
    min-width: 8rem;
    height: 1.5rem;

    .p-inputtext {
      padding: 0;
    }

    .p-dropdown-clear-icon {
      display: none;
    }
  }

  /* **************************************************************
   * Definition of the top header of the table, in which we have:
   *  - the number of results of the search
   *  - the toggler button to select the columns to display
   * **************************************************************/
  .inner-header {
    > .result-group {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      > .dropdown {
        > .menu.transition.visible {
          background: transparent;
          border: none;
          box-shadow: none;
          left: -12.857rem;

          > .item {
            position: relative;
            border: 0.071rem solid $gray-very-light-v3;
            &:not(:first-child) {
              border-top: none;
            }

            &:not(:last-child) {
              border-bottom: none;
            }

            > div {
              &:first-child {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 0.714rem;

                > span {
                  max-width: 6.071rem;
                  display: block;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
          }
        }
      }
    }
  }

  .inner-header {
    display: flex;
    flex: 1;
    justify-content: space-between;
  }

  .page-group,
  .inner-header {
    display: flex;
    align-items: center;

    > div.field-style {
      width: 15rem;
      max-height: 2.75rem;
    }

    > span {
      width: 1rem;
    }

    > button,
    .column-toggler-button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      font-size: 1.4rem;

      &.active {
        border-radius: 0.4rem;
        background-color: $gray-lighter;
      }

      &.disabled {
        color: $gray;
      }

      &:hover {
        border-radius: 0.4rem;
        background-color: $grayish-magenta-light;
      }
    }
  }

  .ui.button.drag-drop-toggle-btn {
    background-color: transparent;
    font-size: 1.4rem;
    &:hover {
      border-radius: 0.4rem;
      background-color: $grayish-magenta-light;
    }
  }
}

.p-datatable.p-datatable-scrollable {
  th.p-frozen-column {
    right: 0 !important;
    box-shadow: -8px 0px 5px 0px $gray-very-light-v7;
    box-shadow: -4px 0px 5px -3px $gray-very-light-v7;
  }
}
