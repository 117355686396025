@import 'assets/styles/_colors.scss';

.fullscreen-view {
  backdrop-filter: blur(0.1071rem);
  z-index: 1000;
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
}
