@import 'assets/styles/_colors.scss';

.field.field-style .pos-rel {
  .eye-icon {
    position: absolute;
    top: 0.1429rem;
    bottom: 0.1429rem;
    right: 0.214rem;
    width: 2.857rem;
    padding-left: 0.571rem;
    background-color: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    svg {
      color: $blue-very-dark;
      transform: scale(1.2);
      cursor: pointer;
    }
  }

  .input-unit {
    position: absolute;
    right: 1em;
    top: 0.87857143em;
    font-style: normal;
    font-weight: 400;
    font-size: 0.8571rem;
    line-height: 140%;
  }
  .input-unit-with-action {
    border: solid 1px red;
    position: absolute;
    right: 3em;
    top: 0.87857143em;
    font-style: normal;
    font-weight: 400;
    font-size: 0.8571rem;
    line-height: 140%;
  }
}
