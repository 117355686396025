@import 'assets/styles/_colors.scss';

.transaction-alerts {
  .alert {
    display: flex;

    .message {
      max-width: 50%;
    }
  }

  .no-alert {
    font-size: 1.3rem;
  }

  .field.field-style {
    width: 16rem;
    margin-bottom: 0;
  }
}

.ui.popup.transaction-alert-popup {
  max-width: 100rem;
  max-height: 50rem;
  overflow-y: auto;

  pre {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }
}

// TO BE REMOVED
.ai-question-answer-box {
  background: #f4f4f4;
  padding: 10px;
  border-radius: 5px;
}
