@import 'assets/styles/_colors.scss';

.parameters-listing {
  .param-icon {
    width: 1.143rem;
    height: 1.143rem;
  }
}

.p-multiselect-items-wrapper {
  .param-icon {
    position: relative;
    top: 0.2rem;
    left: 1rem;
    width: 1.143rem;
    height: 1.143rem;
  }
}
