@import 'assets/styles/_colors.scss';

.auth-header {
  display: flex;
  position: relative;
  align-items: center;

  .sericin-icon {
    width: 11.9286rem;
    height: 3.7857rem;
  }

  .info {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 140%;
    margin-left: auto;
    margin-right: 1rem;
    cursor: pointer;
  }
}
