@import 'assets/styles/_colors.scss';

$shadow-color: rgba(230, 230, 230, 1);

.contract-navigator-wrapper {
  display: grid;
  grid-template-columns: fit-content(100%) auto;
  grid-template-rows: 1fr;
  grid-template-areas: 'scroll-area add-new';
  column-gap: 2rem;
  padding: 1.5rem 0.3rem;

  & > :nth-child(1) {
    grid-area: scroll-area;
    position: relative;
    min-width: 0;

    .shadow {
      position: absolute;
      top: -0.5rem;
      bottom: -0.5rem;
      width: 2.5rem;
      opacity: 0;
      border-radius: 0.8571rem;

      &.left {
        background: linear-gradient(90deg, $shadow-color, transparent);
        left: -0.5rem;
        box-shadow: -10px 0px 18px -10px gray;
      }

      &.right {
        background: linear-gradient(270deg, $shadow-color, transparent);
        right: -0.5rem;
        box-shadow: 10px 0px 18px -10px grey;
      }
    }

    .navigator-container {
      display: flex;
      gap: 1rem;
      overflow-x: auto;
      flex-wrap: nowrap;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
        background: none;
      }

      &::-webkit-scrollbar-track-piece {
        background: none;
      }

      &::-webkit-scrollbar-track {
        background: none;
      }

      &::-webkit-scrollbar-thumb {
        background: none !important;

        &:hover {
          background: none;
        }
      }
    }
  }

  & > :nth-child(2) {
    grid-area: add-new;
  }

  .contract-item {
    min-height: 11.5714rem;
    height: 11.5714rem;
    min-width: 10.1428rem;
    width: 10.1428rem;
    border-radius: 0.8571rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 0.0714rem solid $gray-light;
    cursor: pointer;
    overflow-y: hidden;

    &.active {
      border: 0.15rem solid $orange;
      box-shadow: 0 0.286rem 1.143rem $blue-greyish;
    }

    &:hover {
      box-shadow: 0 0.286rem 1.143rem $blue-greyish;
    }

    &.add-item {
      border: 0.0714rem dashed $blue-dark-grayish;

      &:hover {
        box-shadow: none;
      }

      .content {
        padding-top: 2.4rem;
      }
    }

    .img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.5rem;
      z-index: 1;

      .icon-wrapper {
        height: 1.586rem;
        width: 1.586rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $orange;

        svg {
          color: $white;
          font-size: 1.2rem;
        }
      }
    }
  }
}
