@import 'assets/styles/_colors.scss';
@import 'assets/styles/_metrics.scss';

.nav-notication-container {
  display: flex;
  align-items: center;
  margin-right: 1.8857rem;
  position: relative;

  .count {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.75rem;
    background: $orange;
    position: absolute;
    top: 0.7rem;
    right: -0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    font-size: 0.7428rem;
    font-weight: 500;
    border: 0.0714rem solid $gray-blackish;
    z-index: 1;
    animation:
      growOut 0.3s ease-in,
      shrinkIn 0.3s ease-out;

    & + .bell-icon {
      top: 0.4rem;
    }
  }

  .bell-icon {
    color: $blue-dark-grayish;
    width: 1.5rem;
    height: 1.5rem;
    position: relative;
  }

  @keyframes growOut {
    0% {
      width: 0.9rem;
      height: 0.9rem;
    }
    100% {
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  @keyframes shrinkIn {
    0% {
      width: 1.8rem;
      height: 1.8rem;
    }
    100% {
      width: 0.9rem;
      height: 0.9rem;
    }
  }

  @media (min-width: $big-monitors-min-width) {
    margin-right: 3.1rem;

    .bell-icon {
      width: 1.8857rem;
      height: 1.8857rem;
    }

    .count {
      top: 1.3rem;
      right: -0.4rem;
    }
  }

  @media (min-width: $tv-device-min-width) {
    margin-right: 3.3rem;

    .count {
      top: 1.65rem;
      right: -0.4rem;
    }
  }
}
