@import 'assets/styles/_colors.scss';
@import 'assets/styles/_metrics.scss';

.stacking-plan-available-status-list {
  font-style: normal;
  font-weight: 600;
  font-size: 0.857rem;
  line-height: inherit;
  color: $blue-very-dark-grayish;

  .ui.horizontal.list > .status-filter-item {
    display: inline-flex;
    margin-left: 1rem;
    font-size: 1rem;
    align-items: center;

    .field {
      margin-bottom: 0;
    }
  }
}
