@import 'assets/styles/_colors.scss';

.card-column-header {
  padding: 0.25rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  margin: 1rem 0;

  .card-column-title {
    font-size: 1.286rem !important;
    padding-left: 0.357rem;
    font-weight: 600;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: initial;
  }
  & .hide {
    display: none;
  }

  & .show {
    display: flex;
  }

  &.hover-available:hover {
    background-color: $gray-very-light-v4;
  }

  & > div {
    display: flex;
    gap: 0.125rem;

    & > span {
      display: flex;
      height: 2.25rem;
      width: 2.25rem;

      justify-content: center;
      align-items: center;

      border-radius: 0.5rem;

      & > svg {
        color: $blue-very-dark-grayish;
        width: 1.143rem;
        height: 1.143rem;
        &.edit-button {
          margin-right: -0.857rem;
        }
      }

      &:hover {
        cursor: pointer;
        filter: brightness(0.9);
      }
    }
  }
}
