@import 'assets/styles/_colors.scss';

.lease-template {
  .ui.grid {
    margin-top: 0;
    > .row {
      padding-top: 0;
    }
  }

  .side-section-name-column {
    font-style: normal;
    font-weight: 700;
    font-size: 0.857rem;
    line-height: 1.5rem;
    text-transform: capitalize;
    color: $blue-desaturated-dark;
  }

  .side-section-value-column {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $blue-very-dark;
  }

  a {
    font-style: italic;
    cursor: pointer;
    font-weight: 400;
    font-size: 0.857rem;
    line-height: 1.357rem;
    text-decoration-line: underline;
    color: $blue-royal;
    svg {
      margin-left: 0.4rem;
    }
  }
}
