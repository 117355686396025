@import 'assets/styles/_colors.scss';

.ui.popup.doc-pop-up {
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.3rem;
  max-width: 50rem;
  height: fit-content;
  box-shadow: 0 0.286rem 1.143rem $blue-greyish;
  border-radius: 0.571rem;
  width: fit-content;
  position: relative;

  .content {
    min-width: fit-content;
  }

  .doc-content {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 0.2rem;
    display: flex;
    .doc-name {
      width: 8rem;
      display: block;
    }
  }

  .badge-container {
    margin-top: -0.15rem;
  }
}
