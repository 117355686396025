.page-layout.transaction-negotiation-page {
  padding: 0;

  .editor-container {
    padding: 0rem !important;
  }

  .tab-container .tab-container-header {
    margin-bottom: 2rem;
  }

  .footer {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
