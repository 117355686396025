@import 'assets/styles/_colors.scss';

.graph-container-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .graph-title {
    font-style: normal;
    font-weight: 500;
    font-size: 1.6em;
    line-height: 2.3571rem;
  }

  .no-data {
    flex-basis: 100%;
    margin-top: 3.5rem;
    align-self: center;
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
  }

  .graph {
    flex-basis: 100%;
    margin-bottom: 1rem;

    & > div {
      width: 100%;
      height: 13.9286rem;

      .graph-wrapper {
        width: 100%;
        height: 100%;

        canvas {
          width: 100% !important;
        }
      }

      .y-title {
        display: inline-block;
        font-family: 'Urbanist';
        font-style: normal;
        font-weight: 700;
        font-size: 0.8571rem;
        line-height: 1.214rem;
        text-align: right;
        color: $blue-dark-grayish;
        margin-bottom: 0;
        position: relative;
        top: -0.1429rem;
        left: 0;
      }
    }
  }
}

.graph-tooltip-container {
  min-width: 1rem;
  max-width: 48rem;
  min-height: 1rem;
  background: $white;
  box-shadow: 0 0.286rem 1.1429rem $blue-greyish;
  border-radius: 0.571rem;
  font-family: 'Urbanist';
  font-style: normal;
  padding: 1.1428rem;

  .no-data {
    font-size: 0.8rem;
  }

  @media (min-width: 720px) {
    max-width: 100rem;
  }
}
