@import 'assets/styles/_colors.scss';

.contract-event-dropdown {
  border-radius: 0.429rem;
  position: relative;
  max-width: 47%;

  .p-multiselect {
    width: 100%;
    .p-multiselect-trigger {
      width: 2rem;
    }
    .p-multiselect-label {
      padding: 0.75rem 0 0.75rem 0.75rem;
    }
  }

  .dropdown-input {
    display: flex;
    align-items: center;
    .ui.disabled.input {
      opacity: 1;
    }
    .ui.input > input {
      opacity: 1;
      width: 100%;
      border: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 0.857rem;
      line-height: 1.357rem;
      display: flex;
      align-items: center;
      color: $blue-very-dark;
    }
    svg {
      position: absolute;
      right: 0.143rem;
    }
  }
}

.contract-event-dropdown-label {
  margin-left: 1rem;
  padding: 0.143rem 0.429rem;
  font-size: 0.786rem;
  font-style: normal;
  font-weight: 600;
  border-radius: 0.3rem;
  text-transform: uppercase;
}
