@import 'assets/styles/_colors.scss';

.benchmark-page {
  .special-clause-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0.8571rem;
    border-radius: 0.571rem;
    height: 3.429rem;

    &.disabled {
      background: $gray-lighter;
    }

    span {
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.429rem;
      color: $blue-very-dark;
    }
  }
}
