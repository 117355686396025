@import 'assets/styles/_colors.scss';

.menu.transition.visible.settings-menu {
  box-shadow: 0 0.286rem 1.1429rem $blue-greyish !important;
  border-radius: 0.571rem !important;
  margin-top: 0.571rem !important;
  padding: 1.429rem !important;
  right: 1.071rem !important;
  width: 17.7143rem;

  .settings-menu-title {
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: $blue-dark-grayish;
  }

  a.support-link > div {
    margin: 0;
  }

  .list-of-entities {
    max-height: 14.286rem;
    margin-left: -0.286rem;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0.3571rem;
    }

    &::-webkit-scrollbar-track {
      background: $orange-light-grayish;
    }

    &::-webkit-scrollbar-thumb {
      background: $orange;
      border-radius: 0.429rem;
      height: 5.214rem;
    }
  }

  div.item[role='option'],
  a {
    padding: 0.58571429em 0 !important;

    &.link.item {
      padding: 0.58571429em 0 !important;
    }

    &.entity-item.item {
      position: relative;
      cursor: pointer;

      &:hover {
        background: $orange-very-light-grayish !important;
      }

      &:first-of-type {
        border-radius: 0;
      }

      .item-name {
        font-style: normal;
        font-weight: 700;
        font-size: 0.786rem;
        line-height: 120%;
        color: $blue-very-dark;
      }

      .user-role {
        font-style: normal;
        font-weight: 500;
        font-size: 0.714rem;
        line-height: 120%;
        color: $blue-desaturated-dark;
        margin: 0.1429rem 0;
      }

      svg {
        position: absolute;
        right: 0;
        top: 1rem;
        color: $orange;
      }
    }

    &.others.item {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 400 !important;
      font-size: 1rem !important;
      line-height: 140% !important;
      color: $blue-very-dark !important;

      &:hover {
        background-color: $orange-very-light-grayish !important;
      }

      svg {
        margin-right: 0.571rem;
        width: 1.1429rem;
        height: 1.1429rem;
        color: $blue-very-dark-grayish;

        &.user {
          position: relative;
          top: 0.2rem;
        }

        &.notification {
          width: 1.429rem;
          height: 1.429rem;
          margin-right: 0.3571rem;
          position: relative;
          left: -0.1429rem;
          top: 0.45rem;
        }
        &.logout {
          margin-right: 0.6429rem;
        }
      }
    }
  }
}
