@import 'assets/styles/_colors.scss';

.clause-index-container {
  display: flex;
  align-items: center;
  margin-top: 0.3rem;

  & > .clause-title {
    display: flex;
    align-items: center;

    & > .title-row {
      display: flex;
      flex-grow: 0;
      margin: 1rem 0;
      gap: 0.3rem;
      padding-left: 0.25rem;
      padding-right: 0.25rem;

      justify-content: center;
      align-items: center;

      height: 2.1rem;
      width: fit-content;
      min-width: 2.1rem;
      border-radius: 1rem;
      background-color: $orange-light-grayish;

      font-family: 'Urbanist';
      font-size: 1.2rem;
      z-index: 50;

      & > span {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $white;
        border: 0.1rem solid $orange;
        border-radius: 50%;
        width: 1.7rem;
        height: 1.7rem;
        aspect-ratio: 1/1;
      }
    }

    & > span {
      display: flex;
      align-items: center;

      height: 2.1rem;
      min-width: 2.1rem;
      background-color: $gray-very-light;
      border-radius: 1rem;

      padding-left: 2rem;
      padding-top: 0.1rem;
      padding-right: 0.75rem;
      margin-left: -1.5rem;

      font-weight: 600;

      z-index: 10;
    }

    .title-row-extended {
      min-width: fit-content;
      border-radius: 1rem;
    }
    .clause-node-name {
      position: relative;
      top: -0.76rem;
      left: 0.5rem;
    }
  }
}
