.forecasting-tab-period-select {
  display: flex;

  .date-select {
    display: flex;
    align-items: center;

    .date-cell {
      position: relative;
      margin-left: 0.5rem;
    }
  }
}
