@import 'assets/styles/_colors.scss';

.left-content {
  .ui.grid > .row.section-row {
    > .column.section-column {
      padding: 1.714rem;
      gap: 0.714rem;
      min-width: 18.286rem !important;
      height: 16.357rem;
      border-radius: 0.857rem;
      margin-right: 2.286rem;

      .section-header {
        font-style: normal;
        font-weight: 500;
        font-size: 1.857rem;
        line-height: 2.357rem;
        display: flex;
        align-items: center;
        margin-top: -2.4rem;
        margin-left: 2.5rem;
      }
      .section-content {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
      }
      .inner-icon {
        width: 1.714rem;
        height: 1.714rem;
        color: $orange;
      }
      .inner-image {
        position: relative;
        top: -0.4rem;
      }
      .ui.button.section-button {
        justify-content: center;
        align-items: center;
        padding: 0.714rem;
        gap: 0.286rem;
        width: 14.857rem;
        height: 2.857rem;
        border-radius: 0.286rem;
        background-color: transparent;
        font-family: 'Urbanist';
        font-style: normal;
        font-weight: 600;
        font-size: 0.857rem;
        text-transform: uppercase;
        position: absolute;
        top: 12rem;
      }
    }
  }
}
