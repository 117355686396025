@import 'assets/styles/_colors.scss';

.date-range-picker {
  flex-direction: row;
  align-items: center;

  .react-daterange-picker__wrapper {
    background: $gray-very-light;
    border-radius: 0.286rem;
    border: 0;
    padding: 0.429rem 0.571rem;
  }
  .react-daterange-picker__inputGroup {
    font-style: normal;
    font-weight: 600;
    font-size: 0.857rem;
    line-height: 1.357rem;
    text-transform: uppercase;
    color: $blue-very-dark-grayish;
  }
}
