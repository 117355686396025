@import 'assets/styles/_colors.scss';

.add-plus-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  width: fit-content;
  border-radius: 0.6rem;

  &.disabled {
    cursor: default;
    color: $gray !important;

    svg {
      color: $gray !important;
    }
  }

  &:not(.disabled):hover {
    background: $white-smoke;
  }

  svg {
    width: 1.6rem;
    height: 1.6rem;
  }
}
