@import 'assets/styles/_colors.scss';

.parameter-create-view-information-tab {
  .ui.grid,
  .row,
  .column {
    &.outlined.container {
      &.parameters-side {
        height: 22.5rem;
      }
      &.constrain-box {
        height: 24.7rem;
        .row {
          height: 1.429rem;
        }
      }
      &.guidelines-box {
        height: 32.5rem;
      }
    }
  }
  .table-wrapper.columns {
    height: 14rem;
    overflow: auto;
  }
}
