@import 'assets/styles/_metrics.scss';
@import 'assets/styles/_colors.scss';

.ui.modal.modal-template.parameter-changes-modal {
  width: 120rem !important;
  height: 50rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); /* Adjusts modal position to center it */
  & > .content > .description {
    height: 100%;
    padding: 1;
    .grid-container {
      height: 100%;
      > div {
        display: flex;
        flex-direction: column;
      }
      .col-4 {
        padding-top: 1rem;
      }
      .col-12 {
        left: 30%;
        top: 2%;
        position: relative;
        padding: 1rem;
        width: 43%;
        .message-type-selector {
          padding-top: 3rem;
        }
      }
    }
    .ui.card.changes-box {
      height: 100%;
      padding: 0;
      margin: 0;
      > .content {
        height: 100%;
        padding: 0.2rem;
        margin: 0.2rem;
        width: 100%;
        .header {
          color: $black;
          background-color: $gray-very-light-v4;
          top: 0;
          left: 0;
        }
        .ui.grid {
          width: 100%;
          .changes-table-column {
            padding-right: 0rem;
            width: 75% !important;
          }
          .message-box-content {
            .message {
              font-weight: 360;
            }
          }
        }
      }
    }
  }
}
