@import 'assets/styles/_colors.scss';

.clause-container {
  display: flex;
  flex: 1;
  flex-direction: column;

  & > div {
    display: flex;
    align-items: center;
    min-height: 4rem;
  }

  .ui.fitted.toggle.checkbox {
    margin-left: 1rem;
  }

  & > .field-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .field-style {
    width: 100%;

    .input-field {
      width: 75%;
    }
  }

  .multiple-row {
    margin-top: -1.5rem;
  }
}
