@import 'assets/styles/_colors.scss';

.ui.popup.assigned-users-tooltip {
  min-width: 11.214rem;
  max-width: 30rem;
  min-height: 0.714rem;
  max-height: 30rem;
  overflow-y: auto;
  box-shadow: 0 0.286rem 1.143rem $blue-greyish;
  border-radius: 0.571rem;
  padding: 1.143rem;

  &::-webkit-scrollbar {
    width: 0.429rem;
  }

  &::-webkit-scrollbar-track {
    background: $orange-light-grayish;
  }

  &::-webkit-scrollbar-thumb {
    background: $orange;
    border-radius: 0.429rem;
    height: 5.214rem;
  }

  .listing {
    list-style: none;

    .item {
      & > div {
        display: flex;
        align-items: center;
        padding: 0.286rem;
        cursor: default;
      }

      .top {
        .listing-name {
          font-weight: 600;
          font-size: 0.8571rem;
          line-height: 1rem;
          margin-left: 0.571rem;
        }
      }

      .middle,
      .bottom {
        font-weight: 400;
        font-size: 0.8571rem;
        line-height: 140%;
      }

      &:not(:last-of-type) {
        margin-bottom: 1.143rem;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      &:hover {
        background: $orange-light-grayish;
      }
    }
  }
}
