@import 'assets/styles/_colors.scss';

.ui.card.changes-box {
  width: 100%;
  margin-top: 1rem;
  background-color: $gray-very-light-v4;
  border-radius: 0.5rem;

  .ui.button.approval-right-btn {
    top: 0.4rem;
    right: 0.5rem;
  }
  .badge-container {
    position: absolute;
    top: -0.5rem;
    right: 1rem;
  }
  .date {
    margin-left: 3rem;
    font-size: 0.9rem;
  }

  table tbody {
    display: block;
  }
  table thead,
  table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  .person {
    position: relative;
    left: -0.5rem;
    margin-top: 1rem;
    .person-icon {
      font-size: 2.2rem;
      top: 0.2rem;
      position: relative;
    }
    .bold-person {
      font-size: 1rem;
      top: -1.1rem;
      position: relative;
    }
    .date {
      font-size: 0.8rem;
      position: absolute;
      left: 0.4rem;
      top: 1.3rem;
    }
  }
  .note-text {
    font-size: 0.857rem;
    color: $blue-very-dark-grayish !important;
  }

  .changes-table-column {
    padding-left: 0;
  }
  .submitter-info-row {
    margin-top: 1rem;
    background-color: $white;
    .header {
      font-size: 1.2rem;
      position: relative;
      top: 0.5rem;
      left: 0.5rem;
    }
  }
  .remarks-box {
    textarea {
      width: 100%;
      border: 0.071rem solid rgba(34, 36, 38, 0.15);
      padding: 0.5rem;
    }
    .btn {
      float: right;
      background: $orange;
      color: $white;
    }
  }
  &.discussion-box {
    .discussion {
      h2 {
        font-size: 1rem;
      }
      p {
        font-size: 1rem;
        color: $blue-very-dark-grayish;
        margin-top: 0.2rem;
        margin-bottom: 0.5rem;
        max-height: 4rem;
        overflow: auto;
      }
      .view-discussion {
        float: right;
        color: $blue-very-dark-grayish;
      }
    }
  }
  .submitter-info-column {
    padding: 0;
  }
  .group-status {
    position: absolute;
    top: -1.9rem;
    right: 1rem;
    font-size: 0.85rem;
  }
}

.discussion-table-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 40rem;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0.5rem;
}
