@import 'assets/styles/_colors.scss';

.ui.grid.spaces-units-filter {
  .ui.input.input-filter {
    height: 2.7rem;
  }
  .clear-fields {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.0714rem;

    svg {
      transform: scale(1.5);
    }

    .text {
      display: inline-block;
      margin-left: 0.6429rem;
      margin-top: 0.0714rem;
      font-weight: 600;
      font-size: 1rem;
      line-height: 120%;
      display: flex;
      align-items: center;
      position: relative;
      top: 0.06rem;
    }
  }
}
