@import 'assets/styles/_colors.scss';

.transaction-header {
  position: relative;
  top: 3rem;
  height: 4.5rem;

  .transaction-info-wrapper {
    flex: 1 !important;
    min-width: fit-content !important;
    padding-right: 1rem !important;

    .transaction-info {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      .breadcrumb {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-weight: bold;
      }

      .transaction-id {
        color: $blue-very-dark-grayish;
        font-size: 0.875rem;
      }
    }
  }

  .analytics-column {
    flex: 0 1 auto !important;
    min-width: fit-content !important;
    padding-top: 2rem !important;
    padding-right: 1rem !important;
  }

  .stats-wrapper {
    flex: 0 1 auto !important;
    min-width: fit-content !important;
    margin-left: auto !important;

    .stats-container {
      display: flex;
      gap: 2rem;
      justify-content: flex-end;
    }

    .stat-item {
      min-width: fit-content;
      text-align: center;

      .stat-value-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.25rem;

        .stat-value {
          font-weight: bold;
          white-space: nowrap;
        }

        .stat-percentage {
          font-size: 0.875rem;
          white-space: nowrap;

          &.positive {
            color: $green;
          }

          &.negative {
            color: $red;
          }
        }
      }

      .stat-label {
        color: $blue-very-dark-grayish;
        font-size: 0.75rem;
      }

      .positive {
        color: $green;
      }
    }
  }
}
