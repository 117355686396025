.ui.grid.category-parameters {
  position: relative;
  left: -1rem;
  .component-card-container {
    width: auto;
  }
  .ui.radio.checkbox label {
    white-space: normal;
  }
}

.parameter-groups-box {
  margin-bottom: 0rem;
  &:first-child {
    margin-top: 0.2rem;
  }
}

.card-parameters {
  .ui.button.btn.update-answers-save {
    min-width: auto;
    position: absolute;
    width: fit-content;
    top: 0.1rem;
    left: 22.5rem;
    &.reset {
      left: 28rem;
      height: auto;
      line-height: 100%;
      padding: 0.58rem 0.7rem;
    }
  }
}
