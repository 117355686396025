@import 'assets/styles/_colors.scss';
@import 'assets/styles/_metrics.scss';

.revision-information {
  .revision-label {
    font-weight: 700;
    font-size: 0.857rem;
    line-height: 1rem;
  }

  .ui.button.revision-history-btn {
    border-radius: 0.286rem;
    font-weight: 600;
    font-size: 0.857rem;
    line-height: 1.357rem;
    text-transform: uppercase;
    color: $gray;
    margin-left: 1rem;
    margin-top: 0.214rem;
    position: relative;
    top: 1.643rem;
    svg {
      width: 1.143rem;
      height: 1.143rem;
      position: relative;
      top: 0.25rem;
      margin-right: 0.542rem;
    }
  }

  .revision-last-modified-information {
    font-weight: 600;
    font-size: 0.714rem;
    line-height: 140%;
    display: flex !important;
    align-items: center;
    margin-left: -1.071rem;
    top: -0.143rem;
  }

  .line {
    border-bottom: 0.0714rem solid $grayish-magenta-light;
    width: 93%;
    position: absolute;
    bottom: -1.429rem;
    left: 1.071rem;
  }

  .ui.button.revision-information {
    justify-content: center;
    align-items: center;
    padding: 0.143rem 0.429rem;
    gap: 0.714rem;
    width: 5.786rem;
    height: 1.429rem;
    border-radius: 0.286rem;
    position: relative;
    top: -0.143rem;

    font-weight: 600;
    font-size: 0.929rem;
    line-height: 1.143rem;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
}
