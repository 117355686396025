@import 'assets/styles/_colors.scss';

.preview-side-menu-container {
  flex: 1;
  background-color: $white;
  overflow-y: auto;
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 0.371rem;
  }

  header {
    display: flex;
    height: 5rem;
    border-bottom: 0.2143rem solid $blue-very-dark-grayish;
    align-items: center;
  }

  .provision-guideline {
    margin-top: 1rem;
  }
}

.answer-buttons {
  display: flex;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  .ui.button.btn {
    min-width: 11rem;
  }
  .grey-bg.btn {
    margin-right: 0.8rem;
  }
}

.no-guidance {
  font-size: 1.2rem;
}

.table-of-content {
  text-align: left;

  .provision-box {
    color: $blue-very-dark-grayish;
    position: relative;
    margin-bottom: 0.5rem;
    cursor: pointer;

    &.bold {
      color: $black;
      font-weight: 800;
    }

    &.active {
      color: $orange;
      font-weight: 800;
    }
  }
}

.preview-side-menu-parameter-wrapper {
  > .preview-side-menu-table-title {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 0.5rem;
    font-weight: bold;
    span {
      font-weight: 200;
      &.table-index-undefined {
        color: $red;
      }
    }
  }
  > .accordion {
    margin: 1.2rem 0;
    > .ui.styled.accordion > .title:first-child {
      display: flex;
      align-items: center;
      > svg {
        padding-right: 0.5rem;
        width: 2rem;
      }
      > span {
        width: 100%;
        padding-left: 0.5rem;
      }
    }
  }
}

// following is to hide discussion in the 'Policy Preview, Information Tab'
.tabs .ui.secondary.pointing.menu .item.hide-discussion {
  display: none;
}

.ui.button.btn.send-to-tenant {
  width: fit-content;
  position: absolute;
  top: -4rem;
  right: 0;
}
