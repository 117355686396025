@import 'assets/styles/_colors.scss';

/* Note that 'p-column-filter-overlay' is a class used in the prime-react DataTable component.
 * It corresponds to the filters associated to each column.
 */
.p-column-filter-overlay {
  .p-dropdown {
    &:hover {
      border-color: $orange !important;
    }

    &.p-inputwrapper.p-focus {
      box-shadow: 0 0 0 0.2rem $orange-light-grayish;
      border-color: $orange;
    }
  }

  .p-multiselect {
    &:hover {
      border-color: $orange !important;
    }

    &.p-inputwrapper.p-focus {
      box-shadow: 0 0 0 0.2rem $orange-light-grayish;
      border-color: $orange;
    }
  }

  .p-inputtext:enabled {
    &:hover {
      border-color: $orange;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem $orange-light-grayish;
      border-color: $orange;
    }
  }

  .p-column-filter-add-rule {
    button {
      color: $orange;

      &:enabled:hover {
        color: $orange;
        background: $orange-very-light-grayish;
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem $orange-light-grayish;
      }
    }
  }

  .p-column-filter-buttonbar {
    .p-button {
      &:nth-of-type(1) {
        color: $orange;

        &:enabled:hover {
          color: $orange;
          background: $orange-very-light-grayish;
        }
      }

      &:nth-of-type(2) {
        background: $orange;
        border-color: $orange;
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem $orange-light-grayish;
      }
    }
  }
}

/* Note that 'p-dropdown-panel' is a class used in the prime-react DataTable component.
 * It corresponds to the dropdown menus used in filters associated to each column.
 */
.p-dropdown-panel {
  .p-dropdown-items-wrapper {
    .p-dropdown-items {
      .p-dropdown-item {
        &.p-highlight {
          color: $orange;
          background: $orange-light-grayish;
        }
      }
    }
  }
}

/* Note that 'p-multiselect-panel' is a class used in the prime-react DataTable component.
 * It corresponds to the multiselector used in filters associated to each column.
 */
.p-multiselect-panel {
  .p-checkbox {
    .p-checkbox-box {
      &:hover {
        border-color: $orange !important;
      }

      &.p-highlight {
        &,
        &:hover {
          background: $orange !important;
          border-color: $orange !important;
        }
      }
    }
  }
}

/* Note that 'p-datepicker' is a class used in the prime-react DataTable component.
 * It corresponds to the date picket used in filters associated date columns.
 */
.p-datepicker {
  .p-datepicker-header {
    button {
      &.p-datepicker-prev,
      &.p-datepicker-next {
        &:focus {
          box-shadow: 0 0 0 0.2rem $orange-light-grayish;
        }

        &:enabled:hover {
          background-color: $orange-light-grayish;
          color: $orange;
        }
      }
    }

    .p-datepicker-title {
      .p-datepicker-month,
      .p-datepicker-year {
        &.p-link:enabled:hover {
          color: $orange;
        }

        &:focus {
          box-shadow: 0 0 0 0.2rem $orange-light-grayish;
        }
      }
    }
  }

  span {
    &.p-monthpicker-month,
    &.p-yearpicker-year {
      &.p-highlight,
      &:hover {
        color: $orange !important;
        background: $orange-light-grayish !important;
      }
    }
  }

  .p-datepicker-calendar {
    .p-highlight {
      background: $orange-light-grayish;
      color: $orange;
    }

    td {
      &.p-datepicker-today {
        span {
          background: $orange-light-grayish;
          color: $orange;
        }
      }

      &:not(.p-datepicker-other-month) {
        span:hover {
          background: $orange-light-grayish !important;
          color: $orange !important;
        }
      }
    }
  }
}
