@import 'assets/styles/_colors.scss';

.run-box {
  position: relative;
  top: -0.5rem;
  cursor: pointer;
  display: inline-block;

  .play-box {
    width: 2.286rem;
    height: 2.286rem;
    border-radius: 0.286rem;
    padding: 0.143rem 0.429rem 0.143rem 0.143rem;
  }
  &.disabled-run {
    pointer-events: none;
    opacity: 0.3;
  }
}

.text-to-run {
  font-weight: 600;
  position: relative;
  top: -0.714rem;
  left: 0.571rem;
}

.result-box {
  min-height: 10.714rem;
  border-radius: 0.429rem 0.429rem 0 0;
  .result-box-title {
    border-bottom: 0.071rem solid $grayish-magenta-light;
    border-radius: 0.429rem 0.429rem 0 0;
    padding: 0.714rem;
    h2 {
      font-size: 1rem;
    }
  }
  .result-text {
    font-size: 0.857rem;
    padding: 0.429rem 0.714rem;
  }
}

.ui.grid > .row > .column.function-run {
  .field-style,
  .ui.grid {
    &:not(:last-of-type) {
      margin-bottom: 1.5rem !important;
    }
  }

  .ui.grid .input-column {
    margin: 0;
    padding: 0;

    &:not(:last-of-type) {
      padding-right: 1rem;
    }
  }
}
