@import 'assets/styles/_colors.scss';

.guidelines-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;

  .columns {
    background: $white;
    border: 0.1rem solid $grayish-magenta-light;
    box-sizing: border-box;
    border-radius: 0.7rem;
    cursor: pointer;

    .title {
      display: flex;
      align-items: center;
      gap: 1rem;

      h3 {
        font-size: 1.1rem;
      }
    }
  }
}

.ui.popup.guidelines-popup {
  .content {
    max-height: 31rem;
    min-height: 1rem;
    width: 29rem;
    overflow-y: auto;

    * {
      box-sizing: border-box;
      list-style-position: inside;
    }

    &::-webkit-scrollbar {
      width: 0.429rem;
    }

    &::-webkit-scrollbar-track {
      background: $orange-light-grayish;
    }

    &::-webkit-scrollbar-thumb {
      background: $orange;
      border-radius: 0.429rem;
      height: 5.214rem;
    }
  }
}
