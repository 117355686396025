@import 'assets/styles/_colors.scss';

.events-timeline {
  .timeline-wrapper {
    overflow: visible;
    width: 100%;
    margin-bottom: 3rem;
    min-width: 21.429rem;
  }
  .timeline-container {
    width: 100%;
    height: 100%;
    position: relative;
    min-height: 7rem;
  }
  .timeline-main-line {
    position: absolute;
    left: 25%;
    height: 100%;
    top: 0.4rem;
  }

  .timeline-circle-point {
    position: absolute;
    width: 1.1428rem;
    height: 1.1428rem;
    border-radius: 0.5714rem;
    transform: translateX(-0.5rem);
    left: 25%;
  }
  .timeline-text-box {
    position: absolute;
    min-width: 2rem;
    min-height: 2rem;
    padding: 0.4285rem;
    border-radius: 0.4285rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 140%;
    color: $blue-dark-grayish;
    text-transform: uppercase;
  }
  .date {
    left: -0.2rem;
    font-style: normal;
    font-weight: 700;
    font-size: 0.786rem;
    line-height: 1rem;
    text-transform: capitalize;
    color: $blue-desaturated-dark;
  }
  .event {
    left: 30%;
    width: 25%;
    font-style: normal;
    font-weight: 600;
    font-size: 0.786rem;
    line-height: 120%;

    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
  .description {
    left: 55%;
    font-style: italic;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.357rem;
    text-decoration-line: underline;
  }
}
