@import 'assets/styles/_colors.scss';

.provisions-use-display {
  display: flex;
  flex-direction: column;
  align-self: center;

  &.display_in_row {
    flex-direction: row;
    gap: 2rem;
    max-height: 25rem;

    .list-column {
      .table-wrapper {
        max-height: 93%;
        overflow-y: auto;
      }
    }
  }

  .loader-container {
    height: 6.5rem;

    .ui.active.loader {
      &::after {
        border-color: $orange transparent transparent;
      }
    }
  }

  h4 {
    font-size: 1rem;
  }

  .list-column {
    width: 100%;
    position: relative;

    .table-title {
      display: flex;
      align-items: center;
      background-color: $white;
    }

    .table-wrapper {
      table.ui.table.dashboard-table.provision-use {
        width: 100%;

        thead {
          position: sticky;
          top: 0;
          z-index: 1;
          th {
            background-color: $gray-very-light-v3;
          }
        }
      }
    }
  }
}
