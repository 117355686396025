.ui.modal.parameters-search-modal {
  width: 90vw;

  .param-search-container {
    height: 83vh;

    .card-view-container {
      height: 94%;
    }
  }
}
