@import 'assets/styles/_colors.scss';

.ui.modal.provision-info-modal {
  height: fit-content !important;
  .right-text {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 0.857rem;
    line-height: 1.357rem;
  }
  .left-header {
    font-size: 1rem;
  }
  .history-btn {
    border-radius: 0.286rem;
    width: fit-content !important;
    font-weight: 600;
    font-size: 0.857rem;
    line-height: 2.343rem;
    text-transform: uppercase;
    position: relative;
    top: -1rem;
    left: 3.5rem;
    height: 2.343rem !important;
    cursor: pointer;
    svg {
      font-size: 1.3rem !important;
      position: relative;
      top: 0.4rem;
    }
    .text {
      position: relative;
      top: 0rem;
      left: 0.5rem;
    }
  }
  .vertical-line {
    border-left: 0.071rem solid $blue-dark-grayish;
    height: 2.143rem;
    margin-left: 3rem;
    margin-top: -3rem;
    position: relative;
    top: 1.3rem;
    left: -0.6rem;
  }
  .date-of-version {
    margin-top: -1rem;
  }
}
