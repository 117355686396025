@import 'assets/styles/_colors.scss';
.ui.grid.multi-choice {
  div.fields.single-group,
  div.fields.multi-group {
    display: block;
  }

  .multi-group {
    .choice-row {
      &:not(:last-of-type) {
        margin-bottom: 0.6rem;
      }
    }
  }

  .choice-row {
    display: flex;
    align-items: center;

    &:first-of-type {
      margin-top: 0.6rem;
    }

    svg {
      margin-right: 0.7rem;
      margin-left: 0.5rem;
      cursor: pointer;
    }
  }

  .left-options-column {
    width: 50%;
  }
  .right-options-column {
    top: 0.55rem;
  }
  .input-options {
    margin-top: -0.5rem;
    margin-bottom: -1.5rem;
  }
}
