@import 'assets/styles/_colors.scss';

.add-floors {
  display: inline-flex;
  cursor: pointer;
  align-items: center;

  p {
    font-size: 1rem;
    line-height: 1.429rem;
    position: relative;
    top: 0.0714rem;
  }
}
