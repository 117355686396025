@import 'assets/styles/_colors.scss';

.ui.breadcrumb.custom {
  position: relative;
  display: flex;
  align-items: center;

  .breadcrumb-main {
    font-style: normal;

    &.medium-size {
      font-weight: 700;
      font-size: 2.2857rem;
    }

    &.small-size {
      font-weight: 600;
      font-size: 1.571rem;
    }

    &:hover:not(.disabled) {
      text-decoration: underline;
    }

    &.disabled {
      cursor: default;
    }
  }

  .icon.divider.bread-crumb-divider {
    &.medium-size {
      font-size: 1.571rem;
    }

    &.small-size {
      font-size: 1.271rem;
    }
  }
}
