@import 'assets/styles/_colors.scss';

.ui.selection.dropdown-atom-select-filter {
  height: 100%;
  width: 100%;
  font-weight: 400;
  line-height: 140%;
  font-size: 0.8571rem;
  border: 0.0714rem solid $grayish-magenta-light;
  border-radius: 0.429rem;
  color: $blue-very-dark;

  i.dropdown.icon {
    top: 1.05em;

    &:not(.clear)::before {
      content: url('../../assets/images/svg/dropdown-arrow-down.svg');
    }
  }

  .active.item.selected {
    background-color: $orange-very-light-grayish;
  }

  .item {
    background: white;

    &:first-of-type {
      border-top-left-radius: 0.571rem;
      border-top-right-radius: 0.571rem;
    }

    &:last-of-type {
      border-bottom-left-radius: 0.571rem;
      border-bottom-right-radius: 0.571rem;
    }

    &:hover {
      background: $orange-very-light-grayish;
    }
  }

  &.visible,
  &.visible:hover {
    border: 0.0714rem solid $orange;

    i.dropdown.icon {
      transform: rotate(180deg);
    }
  }

  &.loading {
    i.dropdown.icon::before {
      content: '';
      border-color: $orange-light-grayish;
    }

    i.dropdown.icon::after {
      border-color: $orange transparent transparent;
    }
  }

  &.multiple {
    height: auto;
    min-height: 2.714rem;

    .label,
    .active {
      background: $gray-very-light;
      color: $blue-desaturated-dark;
    }
  }

  .visible.menu.transition {
    margin-top: 0.0714rem;
    border: none;

    .selected.item,
    .item:hover {
      background-color: $orange-very-light-grayish;
    }

    &::-webkit-scrollbar {
      width: 0.429rem;
    }

    &::-webkit-scrollbar-track {
      background: $orange-light-grayish;
    }

    &::-webkit-scrollbar-thumb {
      background: $orange;
      border-radius: 0.429rem;
      height: 5.2143rem;
    }
  }
}
