@import 'assets/styles/_colors.scss';

.ui.popup.password-pop-up {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0.8571rem;
  width: 8.571rem;
  height: 7.6429rem;
  box-shadow: 0 0.286rem 0.571rem $blue-greyish;
  border-radius: 0.286rem;

  .content {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8571rem;
    line-height: 140%;
    display: flex;
    align-items: center;
    color: $blue-very-dark;
    flex: none;
    order: 1;
    flex-grow: 0;
    position: relative;
    left: 1.071rem;
    width: inherit;

    ul {
      margin-top: 0;
      padding-left: 0;
    }

    li::marker {
      color: $blue-dark-grayish;
    }
  }
}
