@import 'assets/styles/_colors.scss';

.guidance-popup-trigger {
  border: 0.071rem solid $grayish-magenta-light;
  border-radius: 0.571rem;
  margin-bottom: 0.714rem;
  height: 3.143rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
  width: fit-content;
  width: 8.3rem;
  top: 0.5rem;
  position: relative;
}

.ui.popup.guidance-popup {
  max-width: 42.8571rem;
  max-height: 28.5714rem;
  min-height: 1rem;

  .editor-to-html.guidance-popup-html {
    max-height: 26.7rem;
  }
}

.guidelines-header {
  position: relative;
  top: 0.3rem;
  margin-bottom: 0;
}

.guideline-without-title {
  display: inline-flex;
  img {
    top: 0.4rem;
  }

  > div:nth-of-type(2) {
    margin-left: -0.8rem;
  }
}
