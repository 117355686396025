@import 'assets/styles/_colors.scss';

.date-select {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;

    color: $blue-very-dark-grayish;
    margin-right: 1rem;
  }
}
