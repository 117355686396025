@import 'assets/styles/_colors.scss';

/*
 * Transition is used in the Dropdown semantic-ui-react component
 * Used for updating the styling of the dropdown items
 */
.ui.active.visible.dropdown.form-field-dropdown {
  .visible.menu.transition {
    border-radius: 0.571rem !important;
  }
}

// primeflex library is adding margin bottom to field which is not required
.field.field-style.select-atom-field {
  margin-bottom: 0;
}
