@import 'assets/styles/_colors.scss';

.ui.modal.modal-template {
  &.parameters {
    width: 43.5rem;
    height: 27.5rem;
  }

  &.parameters-group {
    min-height: 27.5rem;
    min-width: 93.5rem;

    .btn-row {
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
      .ui.button {
        margin: initial;
      }
    }

    .guidelines {
      font-size: 0.8571rem;
    }
  }

  .parameters-modal-form {
    margin-left: -1rem;
    margin-top: -1rem;
  }

  .btn-row {
    left: -0.4rem;
    margin-top: 0.4rem;
  }
}
