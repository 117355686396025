@import 'assets/styles/_colors.scss';

.list-of-docs {
  position: relative;
  left: 1.2rem;
  font-size: 1.2rem;
  li {
    color: $blue;
  }
  a {
    text-decoration: underline;
    color: $blue;
  }
}
