.page-not-found {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Urbanist';
  min-height: 40vh;

  h1 {
    font-size: 3.5714rem;
  }

  div {
    font-size: 1rem;
  }
}
