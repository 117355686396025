.ui.modal.modal-template.changes-table-modal {
  width: 50rem;
  .field.field-style {
    label {
      font-weight: 600;
    }
    textarea {
      width: 100%;
      border: 0.071rem solid rgba(34, 36, 38, 0.15);
      padding: 0.5rem;
    }
  }

  .approval-item-response-table {
    .ui.button {
      width: auto !important;
    }
  }
}
