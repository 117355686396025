@import 'assets/styles/_colors.scss';

.formatters-inputs-list {
  .multi-choice {
    display: flex;
    margin-top: 1rem;

    .field.field-style,
    .dropdown-select-wrapper {
      width: 30% !important;
    }

    p {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.429rem;
      color: $blue-very-dark;
      top: 0.714rem;
      position: relative;
      margin-right: 1.143rem;
    }
  }

  .add-choice {
    position: relative;
    top: 0.857rem;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.429rem;
    cursor: pointer;

    svg {
      top: 0.264rem;
      position: relative;
    }
  }

  .delete-btn {
    border-radius: 0.429rem;
  }
}
