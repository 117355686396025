@import 'assets/styles/_colors.scss';

.file-select {
  &:hover {
    background: $white !important;

    .choose-text {
      font-weight: 700;
    }
  }

  .file-uploaded {
    display: none;
  }

  .choose-text {
    display: flex;
    align-items: center;

    svg {
      margin-right: 0.5rem;
    }
  }

  .file-text-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: default;

    .file-text {
      width: 10.9574rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .file-cancel {
      height: 1.4rem;
      width: 1.4rem;
      border-radius: 0.7rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 0 0.2rem $red-very-pale;
      }

      svg {
        color: $red-soft;
        transform: scale(1.3);
        cursor: pointer;
      }
    }
  }
}
