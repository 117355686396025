@import 'assets/styles/_colors.scss';
@import 'assets/styles/_metrics.scss';

.parameters-column-definition-content {
  .row.scroll-column-row {
    overflow-x: auto;
    flex-wrap: nowrap;
    white-space: nowrap;
    width: 100%;
    border: solid 5px blue;

    &::-webkit-scrollbar {
      width: 1em;
      height: 0.571rem;
    }
  }

  .parameters-column-definition-content-container {
    .inner-content {
      border-radius: 0.44rem !important;

      &::-webkit-scrollbar-track {
        background: $grayish-magenta-light;
        border-radius: 0.429rem;
        transform: matrix(0, 1, 1, 0, 0, 0);
      }

      &::-webkit-scrollbar-thumb {
        background: $orange;
        border-radius: 0.429rem;
        transform: matrix(0, 1, 1, 0, 0, 0);
      }

      &::-webkit-scrollbar-thumb:window-inactive {
        background: $orange;
      }

      .column-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .title-icons {
        width: 1.143rem;
        height: 1.643rem;
      }

      .answer-type {
        font-weight: 700;
        font-size: 0.8771rem;
        line-height: 1rem;
        color: $blue-very-dark;
      }

      .param-link {
        font-size: 1rem;
        line-height: 1rem;
        color: $blue-dark;
      }

      .param-type {
        font-size: 0.8571rem;
        width: 1.43rem;
        height: 1.43rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
      }

      .conditions-group {
        .condition-show-ground {
          width: inherit;
        }

        .condition-trash {
          margin-left: auto;
          left: 0;
          margin-right: 1rem;
        }

        .condition-inner {
          width: auto;
          display: block;

          > div {
            margin-top: 1rem;
          }

          .ui.fluid.dropdown.dropdown-operator {
            margin-left: 0;
            margin-right: 0;
          }

          .condition-trash {
            margin-right: 0;
          }
        }

        .add-row > span {
          left: auto;
          margin-right: 1.7rem;
        }
      }
    }

    &.add-column-box {
      .grid.outlined.container {
        min-height: 24rem;
      }
    }
  }
}

.no-column {
  position: relative;
  top: -2.5rem;
  width: 70%;
}
