@import 'assets/styles/_colors.scss';

.graph-tooltip-container {
  .inner-html.upcoming-events {
    max-height: 16rem;
    overflow-y: auto;
    padding-right: 0.5rem;

    .item-title {
      font-weight: 600;
      font-size: 0.8571rem;
      line-height: 140%;
      color: $blue-very-dark;
    }

    &::-webkit-scrollbar {
      width: 0.429rem;
    }

    &::-webkit-scrollbar-track {
      background: $orange-light-grayish;
    }

    &::-webkit-scrollbar-thumb {
      background: $orange;
      border-radius: 0.429rem;
      height: 5.214rem;
    }

    .section {
      padding: 0.5rem;

      .title {
        display: flex;
        align-items: center;

        .bullet {
          width: 0.4285rem;
          height: 0.4285rem;
          border-radius: 0.214rem;
        }

        h3 {
          line-height: 1rem;
          display: flex;
          margin: 0;
          padding: 0;
          margin-left: 0.6rem;

          span {
            margin-left: 0.3rem;
          }
        }
      }

      .list {
        list-style: none;

        li {
          margin-top: 0.8rem;

          & > span.text {
            display: inline-flex;
            align-items: center;
            font-weight: 400;
            font-size: 0.8571rem;
            line-height: 140%;
            color: $blue-desaturated-dark;

            &:not(:last-of-type) {
              margin-right: 1rem;
            }

            .box {
              display: flex;
              justify-content: center;
              align-items: center;
              min-width: 1rem;
              min-height: 1rem;
              padding: 0.1rem 0.3rem;
              margin: 0 0.5rem;
              font-weight: 600;
              border-radius: 0.286rem;
              font-size: 0.8571rem;

              &.green {
                color: $green;
                background: $green-light-grayish-cyan-lime;
              }

              &.orange {
                background: $orange-light-grayish;
                color: $orange;
              }
            }

            a {
              color: $orange;
              text-decoration: underline;
            }
          }
        }
      }

      &:hover {
        background: $orange-light-grayish;
      }

      &:not(:first-of-type) {
        margin-top: 1.2rem;
      }
    }
  }
}
