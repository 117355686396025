@import 'assets/styles/_colors.scss';

.entity-creator-layout {
  display: grid;
  height: 100vh;

  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'navbar'
    'main';

  .ui.fixed.navbar-landlord {
    grid-area: navbar;
    position: relative !important;
  }

  & > main {
    grid-area: main;
    overflow-y: auto;

    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'auto header'
      'aside section';

    & > aside {
      grid-area: aside;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      border-right: 0.0714rem solid $grayish-magenta-light;
    }

    & > header {
      grid-area: header;

      border-left: 0.0714rem solid $grayish-magenta-light;
    }

    & > section {
      grid-area: section;
      overflow-y: auto;

      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr auto;
      grid-template-areas:
        'main'
        'footer';

      & > main {
        grid-area: main;
        overflow-y: auto;
      }

      & footer {
        grid-area: footer;
      }
    }
  }
}
