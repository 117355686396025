.tab-container {
  // border: solid 1px purple; // <- Please don't remove until the page layout ticket is finished
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  margin-top: 0.75rem;
  height: 1rem;

  // TODO: For some reason the sticky property is not working in the Tab Layout. Please Investigate
  // TODO: I suspect is either something to do with the Top Navbar or the page layout structure imposed to force one scrollbar.
  .tab-container-header-sticky {
    position: sticky;
    top: 1px;
  }

  .page-with-nested-tab-title {
    font-size: 1.3rem !important;
    line-height: 2.071rem !important;
  }

  .tab-container-header {
    // border: solid 5px yellow; // <- Please don't remove until the page layout ticket is finished
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.75rem;

    .tab-container-header-title {
      display: flex;
      //flex: 1;
      justify-content: flex-start;
      align-items: center;
      // border: solid 1px red; // <- Please don't remove until the page layout ticket is finished

      h3 {
        font-size: 1.571rem !important;
        line-height: 2.071rem !important;
      }
    }

    .tab-container-header-toolbar {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      // border: solid 1px black; // <- Please don't remove until the page layout ticket is finished

      .ui.button.btn {
        display: inline-flex;
      }
    }
  }

  .tab-container-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 1rem;
    // border: solid 5px grey; // <- Please don't remove until the page layout ticket is finished
  }
}
