/* Screen sizes for different devices */

/* ----- Widths Begin ----- */

// Small devices (mobile phones, 576px and up)
$small-device-min-width: 576px;

// Medium devices (tablets, 768px and up)
$medium-device-min-width: 768px;

// Large devices (desktops, 992px and up)
$large-device-min-width: 992px;

// X-Large Devices (large desktops, 1200px and up)
$xl-device-min-width: 1200px;
$xl-mid-device-min-width: 1300px;

// X-Large MISC Cases
// Auth and lease dashboard graph cases
$xl-device-min-width-misc-1: 1234px;
$xl-device-min-width-misc-2: 1370px;
$xl-device-min-width-misc-3: 1241px;
$xl-device-min-width-misc-4: 1250px;

// XX-Large Devices (larger desktops, 1400px and up)
$xxl-device-min-width: 1400px;
$xxl-device-min-width-misc-1: 1444px;
$xxl-device-min-width-misc-2: 1445px;

// TODO-Large Devices (Extra large desktops, 1500px and up)
$xxxl-device-min-width: 1500px;
$xxxl-device-min-width-v2: 1920px;

// Big Monitors (2160px, and up)
$big-monitors-min-width: 2160px;
$big-monitors-max-width: 2499px;

// TV devices (2500px, and up)
$tv-device-min-width: 2500px;

/* ----- Widths End ----- */

/* ----- Heights Begin ----- */

// Small Device height
$small-device-min-height: 700px;
$small-device-case-1-min-height: 795px;

// Medium Device height
$medium-device-min-height: 857px;

// Extra large device
$xxl-device-min-height: 1300px;

/* ----- Heights End ----- */

/* ----- Standard Paddings & Margin Begin ----- */

// global padding
.pm-none {
  padding: 0 !important;
  margin: 0 !important;
}

/* ----- Standard Paddings & Margine End ----- */

/* ----- Standard Paddings Begin ----- */

// global padding
.p-none {
  padding: 0 !important;
}

.p-xxs {
  padding: 0.25rem !important;
}

.p-xs {
  padding: 0.5rem !important;
}

.p-s {
  padding: 0.75rem !important;
}

.p-sm {
  padding: 1rem !important;
}

.p-m {
  padding: 1.25rem !important;
}

.p-ml {
  padding: 1.5rem !important;
}

.p-l {
  padding: 1.75rem !important;
}

.p-xl {
  padding: 2rem !important;
}

.p-xxl {
  padding: 3rem !important;
}

// paddings top
.p-t-none {
  padding-top: 0rem !important;
}

.p-t-xxs {
  padding-top: 0.25rem !important;
}

.p-t-xs {
  padding-top: 0.5rem !important;
}

.p-t-s {
  padding-top: 0.75rem !important;
}

.p-t-sm {
  padding-top: 1rem !important;
}

.p-t-m {
  padding-top: 1.25rem !important;
}

.p-t-ml {
  padding-top: 1.5rem !important;
}

.p-t-l {
  padding-top: 1.75rem !important;
}

.p-t-xl {
  padding-top: 2rem !important;
}

.p-t-xxl {
  padding-top: 3rem !important;
}

// paddings bottom
.p-b-none {
  padding-bottom: 0rem !important;
}

.p-b-xxs {
  padding-bottom: 0.25rem !important;
}

.p-b-xs {
  padding-bottom: 0.5rem !important;
}

.p-b-s {
  padding-bottom: 0.75rem !important;
}

.p-b-sm {
  padding-bottom: 1rem !important;
}

.p-b-m {
  padding-bottom: 1.25rem !important;
}

.p-b-ml {
  padding-bottom: 1.5rem !important;
}

.p-b-l {
  padding-bottom: 1.75rem !important;
}

.p-b-xl {
  padding-bottom: 2rem !important;
}

.p-b-xxl {
  padding-bottom: 3rem !important;
}

.p-b-xxxl {
  padding-bottom: 4rem !important;
}

// paddings left
.p-l-none {
  padding-left: 0 !important;
}

.p-l-xxs {
  padding-left: 0.25rem !important;
}

.p-l-xs {
  padding-left: 0.5rem !important;
}

.p-l-s {
  padding-left: 0.75rem !important;
}

.p-l-sm {
  padding-left: 1rem !important;
}

.p-l-m {
  padding-left: 1.25rem !important;
}

.p-l-ml {
  padding-left: 1.5rem !important;
}

.p-l-l {
  padding-left: 1.75rem !important;
}

.p-l-xl {
  padding-left: 2rem !important;
}

.p-l-xxl {
  padding-left: 3rem !important;
}

// paddings right
.p-r-none {
  padding-right: 0rem !important;
}

.p-r-xxs {
  padding-right: 0.25rem !important;
}

.p-r-xs {
  padding-right: 0.5rem !important;
}

.p-r-s {
  padding-right: 0.75rem !important;
}

.p-r-sm {
  padding-right: 1rem !important;
}

.p-r-m {
  padding-right: 1.25rem !important;
}

.p-r-ml {
  padding-right: 1.5rem !important;
}

.p-r-l {
  padding-right: 1.75rem !important;
}

.p-r-xl {
  padding-right: 2rem !important;
}

.p-r-xxl {
  padding-right: 3rem !important;
}

/* ----- Standard Paddings End ----- */

/* ----- Standard Margins Begin ----- */

// global margin
.m-none {
  margin: 0rem !important;
}

// margins top

.m-t-clear-xl {
  margin-top: -2rem !important;
}

.m-t-clear-sm {
  margin-top: -1rem !important;
}

.m-t-clear-xs {
  margin-top: -0.5rem !important;
}

.m-t-none {
  margin-top: 0 !important;
}

.m-t-xxs {
  margin-top: 0.25rem !important;
}

.m-t-xs {
  margin-top: 0.5rem !important;
}

.m-t-s {
  margin-top: 0.75rem !important;
}

.m-t-sm {
  margin-top: 1rem !important;
}

.m-t-m {
  margin-top: 1.25rem !important;
}

.m-t-ml {
  margin-top: 1.5rem !important;
}

.m-t-l {
  margin-top: 1.75rem !important;
}

.m-t-xl {
  margin-top: 2rem !important;
}

.m-t-xxl {
  margin-top: 3rem !important;
}

.m-t-auto {
  margin-top: auto !important;
}

// margins bottom
.m-b-none {
  margin-bottom: 0rem !important;
}

.m-b-xxs {
  margin-bottom: 0.25rem !important;
}

.m-b-xs {
  margin-bottom: 0.5rem !important;
}

.m-b-s {
  margin-bottom: 0.75rem !important;
}

.m-b-sm {
  margin-bottom: 1rem !important;
}

.m-b-m {
  margin-bottom: 1.25rem !important;
}

.m-b-ml {
  margin-bottom: 1.5rem !important;
}

.m-b-l {
  margin-bottom: 1.75rem !important;
}

.m-b-xl {
  margin-bottom: 2rem !important;
}

.m-b-xxl {
  margin-bottom: 3rem !important;
}

.m-b-auto {
  margin-bottom: auto !important;
}

// margins left

.m-l-clear-xs {
  margin-left: -0.5rem !important;
}

.m-l-clear-xl {
  margin-left: -2rem !important;
}

.m-l-none {
  margin-left: 0rem !important;
}

.m-l-auto {
  margin-left: auto !important;
}

.m-l-xxs {
  margin-left: 0.25rem !important;
}

.m-l-xs {
  margin-left: 0.5rem !important;
}

.m-l-s {
  margin-left: 0.75rem !important;
}

.m-l-sm {
  margin-left: 1rem !important;
}

.m-l-m {
  margin-left: 1.25rem !important;
}

.m-l-ml {
  margin-left: 1.5rem !important;
}

.m-l-l {
  margin-left: 1.75rem !important;
}

.m-l-xl {
  margin-left: 2rem !important;
}

.m-l-xxl {
  margin-left: 3rem !important;
}

// margins right

.m-r-clear-xs {
  margin-right: -0.5rem !important;
}

.m-r-none {
  margin-right: 0rem !important;
}

.m-r-auto {
  margin-right: auto !important;
}

.m-r-xxs {
  margin-right: 0.25rem !important;
}

.m-r-xs {
  margin-right: 0.5rem !important;
}

.m-r-s {
  margin-right: 0.75rem !important;
}

.m-r-sm {
  margin-right: 1rem !important;
}

.m-r-m {
  margin-right: 1.25rem !important;
}

.m-r-ml {
  margin-right: 1.5rem !important;
}

.m-r-l {
  margin-right: 1.75rem !important;
}

.m-r-xl {
  margin-right: 2rem !important;
}

.m-r-xxl {
  margin-right: 3rem !important;
}
/* ----- Standard Margins End ----- */

.t-xs {
  top: 0.5rem !important;
}

.t-m {
  top: 1rem !important;
}

.r-m {
  right: 1rem !important;
}
