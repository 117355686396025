@import 'assets/styles/_colors.scss';

.message-box-content {
  .date {
    display: flex;
    flex-direction: row;
    color: $gray-dark;
  }
  .date:before,
  .date:after {
    content: '';
    flex: 1 1;
    border-bottom: 0.071rem solid $gray-light;
    margin: auto;
  }
  .date:before {
    margin-right: 0.714rem;
  }
  .date:after {
    margin-left: 0.714rem;
  }
  .person {
    margin-top: 0 !important;
    .name {
      margin-left: 0.5rem;
    }
  }
}
