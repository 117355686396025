@import 'assets/styles/_colors.scss';

.events-filter-form {
  .label {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    display: flex;
    align-items: center;
    color: $blue-very-dark-grayish;
  }
  .date-field {
    margin: 1rem 0;
  }
  .dropdown-field {
    margin: 1rem 0;
    display: flex;
    gap: 1rem;
    width: 100%;
    overflow: hidden;
  }
}
