.discussion-wrapper.inside-approvals {
  height: 6rem;
  margin-top: 0.8rem;
  .message-icon {
    position: relative;
    top: -6rem;
    width: 2.4rem;
    height: 2.4rem;
  }
  .message-box .text-box {
    font-size: 1rem;
  }
}

.list-of-messages {
  overflow-y: auto;
  max-height: 18rem;
  &.large {
    max-height: 32rem;
  }
}
