@import 'assets/styles/_colors.scss';

// Global css for react date picker used in DateCell and DateInput atoms
.react-datepicker-wrapper {
  input {
    border: none;
    font-family: Urbanist;
    font-style: normal;
    font-weight: 500;
    font-size: 0.8571rem;
    line-height: 120%;
    color: $gray-very-darker;
    width: 6.7857rem;
    margin-left: -0.286rem;
    &:focus {
      outline: none;
    }
  }
}

// Calender popup wrapper
.react-datepicker-popper {
  z-index: 100;
}

.react-datepicker__navigation-icon::before {
  border-color: $blue-very-dark;
}

.react-datepicker__current-month {
  color: $blue-very-dark;
  margin-top: 1.214rem;
  margin-bottom: 1rem;
}

.react-datepicker__navigation {
  top: 1.214rem;
}

// Selected date css
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  border: 0.0714rem solid $orange;
  background: transparent;
  border-radius: 1.786rem;
  width: 1.714rem;
  height: 1.714rem;
  color: $blue-very-dark;
  &:hover {
    border: 0.0714rem solid $orange;
    background: transparent;
    color: $blue-very-dark;
    border-radius: 1.786rem;
  }
  &:focus {
    border: 0.0714rem solid $orange;
    background: transparent;
    color: $blue-very-dark;
    border-radius: 1.786rem;
  }
}

// Pop up calender class
.react-datepicker {
  font-family: 'Urbanist';
  font-style: normal;
  font-size: 0.8571rem;
  text-align: center;
  color: $blue-very-dark;
  box-shadow: 0 0.286rem 1.1429rem $blue-greyish;
  border-radius: 0.571rem;
  border: none;

  // Point to input field
  .react-datepicker__triangle {
    display: none;
  }
  // Date picker calender header
  .react-datepicker__header {
    background-color: white;
    border: none;

    // Calender day names
    .react-datepicker__day-names {
      -moz-user-select: -moz-none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -o-user-select: none;
      user-select: none;
      .react-datepicker__day-name {
        color: $gray;
      }
    }

    // Header navigation button to change month
    .left-arrow {
      left: -0.4286rem;
      color: $blue-very-dark;
      height: 1.1429rem;
      width: 1.1429rem;
      position: relative;
    }

    // Header navigation button to change month
    .right-arrow {
      left: 0.6429rem;
      color: $blue-very-dark;
      height: 1.1429rem;
      width: 1.1429rem;
      position: relative;
    }

    // Header select for choose month and year
    select {
      border: none;
      font-family: 'Urbanist';
      padding: 0 !important;
      font-style: normal;
      font-weight: 700;
      font-size: 0.8571rem;
      line-height: 1rem;
      color: $blue-very-dark;
      width: 4.7rem !important;
      -moz-user-select: -moz-none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -o-user-select: none;
      user-select: none;
      // Header select for choose year
      &.year-dropdown {
        margin-left: 0.714rem;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

// Calender day numbers
.react-datepicker__day {
  width: 1.714rem;
  height: 1.714rem;
  font-weight: 500;
  &:hover {
    color: $white;
    background: $orange;
    border-radius: 1.786rem;
  }
}

// Point to input field
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
  border-bottom-color: $white;
}
