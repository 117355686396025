@import 'assets/styles/_colors.scss';

.component-card-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $white;
  border-radius: 1.1rem;
  width: inherit;
  &:hover {
    box-shadow:
      -2px 4px 13px -7px $black,
      2px 0px 8px -7px $black,
      5px 0px 8px 3px $black-light;
  }

  &.clickable {
    cursor: pointer;
  }
  &:not(:first-child) {
    margin-top: -0.4rem;
  }
}
