@import 'assets/styles/_colors.scss';

.transaction-provision-tab {
  .card-component {
    z-index: 1;
    position: relative;
  }

  .ui.button.btn.add-remove {
    min-width: 2rem;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    z-index: 5;
  }
  .transaction-provision-section {
    border-radius: 0.857rem;
    padding: 2rem 0 0 2rem;
    margin-right: 0;
    margin-left: 0;
    width: 100%;
    height: 100%;
    background-color: $gray-whitish-light;
    min-height: 35rem;

    .icon-wrapper-add {
      height: 1.286rem;
      width: 1.286rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $orange;

      svg {
        color: $white;
        font-size: 1.2rem;
      }
    }

    .provisions-title {
      font-weight: 700;
      font-size: 1.414rem;
      line-height: 2.214rem;
      display: flex;
      align-items: center;
      position: relative;
      top: -0.6rem;

      .catalogue-close-button {
        margin-left: auto;
        margin-right: 3rem;
        border-radius: 50%;
        padding: 0.6rem 1rem;
        border-radius: 50%;
        padding: 1rem 1.2rem;
        font-size: 2rem;
      }
    }

    .cross-btn-parent {
      display: block;
    }

    .cross-btn {
      position: absolute;
      top: 1.5rem;
      right: 2.2rem;
      font-size: 2rem;
      color: $blue-very-dark-grayish;
      z-index: 5;
    }
    .component-card-container {
      margin-top: -0.7rem;

      &:first-of-type {
        margin-top: 0;
      }
    }

    .card-body {
      min-height: 2rem;
    }

    .component-card-body.ui.grid {
      padding: 0.4rem 0.4rem;
      min-height: 3rem;
      font-size: 1rem;

      .row {
        .column {
          &.five {
            text-wrap: balance;
          }
          &.eleven {
            font-weight: bolder;
            white-space: break-spaces;
          }
        }
      }
    }

    .card-component {
      height: fit-content;
      margin-top: -3rem;
      overflow: hidden;
    }

    .card-header .card-header-template {
      width: 100%;
      .title-template {
        width: inherit;
        overflow: visible;

        svg {
          font-size: 1.5rem;
        }

        .column-custom-template {
          display: inline-flex;
          width: 99%;
          .name {
            font-size: 1.143rem !important;
            text-transform: capitalize;
            font-weight: 600;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: initial;
          }

          .card-header-icon-mandatory {
            width: 1.8rem;
            margin: 0 0.4rem;
          }
          .action-button {
            font-weight: normal;
            display: inline-flex;
            span {
              align-self: center;
            }

            .remove-provision-icon {
              display: none;
              margin-left: 0.5rem;

              svg {
                font-size: 1.5rem;
              }
            }
            &:hover {
              .remove-provision-icon {
                display: block !important;
              }
            }
          }
        }
      }
    }
  }
  .action-menu {
    display: flex;
    position: absolute;
    top: -3.571rem;
    right: 0;
    .btn {
      margin-left: 1rem;
    }
  }

  .card-column-header .card-column-title {
    display: none;
  }
  .card-column-container {
    margin-bottom: 4rem;
  }

  .provisions-box {
    position: relative;
    top: -1rem;
  }
}
