@import 'assets/styles/_colors.scss';

.special-checkbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.857rem 0.786rem;
  border-radius: 0.571rem;
  border-radius: 0.429rem;
  height: 2.72rem;

  &.disabled {
    background: $gray-lighter;
  }

  .ui.checkbox {
    position: relative;
    top: 0.25rem;
  }
  span {
    color: $blue-very-dark;
    font-weight: 600;
    font-size: 0.857rem;
  }
  svg {
    margin-left: 0.286rem;
  }
  .required-indicator {
    color: $orange;
    font-weight: 700;
    font-size: 1.286rem;
    position: relative;
    left: 0.214rem;
    top: 0;
  }
}
