@import 'assets/styles/_colors.scss';
@import 'assets/styles/_metrics.scss';

/* **********************************************************
 * Navbar height for different screen sizes // TODO Remove need for defined height by Adebayo Ajibade
 * **********************************************************/
$nav-height: 3.571rem;
$nav-height-2: 5rem;
$nav-height-3: 5.714rem;

.ui.menu.navbar-landlord {
  height: $nav-height;

  .navbar-env {
    position: relative;
    left: -1.429rem;
    .env-content {
      position: relative;
      top: 0.071rem;
      height: 1.643rem;
      border-radius: 0.286rem;
      font-weight: 600;
      font-size: 0.929rem;
      align-items: center;
      text-transform: uppercase;
      padding: 0.143rem 0.857rem;
    }
  }

  /* **********************************************************
 * Definition of the classes related to the active dots
 *  -> active dots indicating the navigation
 * **********************************************************/
  .active-menu {
    position: relative;
    &[active='false'] {
      display: none;
    }
    &.active-menu-position {
      top: 1.571rem;
      left: 0.786rem;
    }
  }

  .item {
    font-weight: 500;
    font-size: 1.1429rem;
    line-height: 120%;
    color: $gray;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0 0.3571rem;
    padding-right: 0.8571rem;
    padding-left: 0.8571rem;
    &:hover {
      color: white;
    }
    &[settings='true'] {
      margin-left: -0.036rem;
      margin-right: 0.536rem;
    }
  }

  .ui.dropdown.setup-dropdown {
    padding-left: 0;
    padding-right: 0;
    box-shadow: 0 0.286rem 1.143rem $blue-greyish;
    &[active='true'] {
      color: white;
      font-weight: bold;
    }

    .menu {
      left: -8.429rem;
      min-width: 15.5rem;
      margin-top: 0.357rem;
      .item {
        padding-left: 1.714rem !important;
        padding-top: 1.143rem !important;
        padding-bottom: 1.143rem !important;

        &:last-of-type {
          padding-bottom: 1.429rem !important;
        }
      }
    }
    .active-menu-position {
      top: 2.071rem;
    }

    .icon-dropdown {
      width: 1.143rem;
      height: 1.143rem;
      position: relative;
      left: 0.67rem;
    }
  }

  .navbar-icon {
    padding-right: 1.786rem;
    padding-left: 2.857rem;
  }

  .user-profile-pic {
    margin-top: 0.571rem;
    border-radius: 1.429rem;
    width: 2.357rem;
    height: 2.357rem;
    cursor: pointer;
  }

  .user-details {
    display: block;
    min-width: 7.143rem;
    max-width: 10.7143rem;
    width: fit-content;
    margin-left: 0.714rem;
    margin-top: -0.4286rem;

    .user-name {
      font-weight: bold;
      font-size: 0.8571rem;
      line-height: 120%;
      margin-top: 0.714rem;

      &[no-entity='true'] {
        margin-top: 0.714rem;
      }
    }

    .user-type {
      font-weight: 500;
      font-size: 0.8571rem;
      line-height: 120%;
      margin-top: -0.714rem;
    }
  }
  .user-profile {
    padding-left: 0.3571rem;
  }

  .right.menu.user-profile {
    margin-left: 0 !important;
  }

  .dropdown-btn {
    padding-left: 0;
    i {
      color: $gray-very-light-v2;
    }
  }
  .vertical-line {
    height: 2.143rem;
    margin-top: 0.6429rem;
    margin-right: 1.2857rem;
  }

  .active.item {
    background: transparent;
    font-weight: bold;
    font-size: 1.1429rem;
    line-height: 120%;
    color: $white;
  }
  .dropdown-btn:hover {
    background: transparent;
  }
}

@media (min-width: $big-monitors-min-width) and (max-width: $big-monitors-max-width) {
  .ui.menu.navbar-landlord {
    height: $nav-height-2;
    .navbar-icon {
      img {
        transform: scale(1.8);
      }
      padding-right: 7.143rem;
      padding-left: 6.4286rem;
    }
    .item {
      font-size: 1.857rem;
    }
    .active.item {
      font-size: 1.857rem;
    }
    .user-details {
      min-width: 14.286rem;
      max-width: 17.857rem;
      .user-name {
        font-size: 1.429rem;
        &[no-entity='true'] {
          margin-top: 1.286rem;
        }
      }
      .user-type {
        font-size: 1.429rem;
        margin-top: -1.4286rem;
      }
    }
    .vertical-line {
      height: 3.429rem;
      margin-right: 1.7rem;
    }
    .user-profile-pic {
      transform: scale(1.6);
      margin-top: 1.286rem;
      margin-right: 1.071rem;
    }
    .dropdown-btn {
      margin-top: -0.5rem;
    }
    .navbar-env .env-content {
      height: 2.3rem;
      font-size: 1.2rem;
    }
    .active-menu-position {
      top: 2.357rem !important;
    }
    .admin-env {
      left: -4rem !important;
      position: relative;
    }
  }
}

@media (min-width: $xl-device-min-width) and (max-width: $big-monitors-min-width) {
  .ui.menu.navbar-landlord .navbar-icon {
    padding-right: $nav-height-3;
  }

  .ui.menu.navbar-landlord .admin-env {
    left: -6.5rem;
  }
}

// big monitor

@media (min-width: $big-monitors-min-width) {
  .ui.menu.navbar-landlord .ui.dropdown.setup-dropdown .menu {
    min-width: 23.2rem;
  }
  .layout.details-view .breadcrumb-wrapper {
    margin-top: 2.3rem;
  }
  .env-content {
    height: 2.4rem !important;
  }
}

@media (min-width: $tv-device-min-width) {
  .ui.menu.navbar-landlord {
    height: $nav-height-3;
    .navbar-icon {
      img {
        transform: scale(2);
      }
      padding-right: 29.2857rem;
      padding-left: 7.857rem;
    }

    .ui.dropdown.setup-dropdown .menu {
      min-width: 24.6rem;
    }

    .navbar-env {
      left: -23rem;
      .env-content {
        height: 2.7rem !important;
        font-size: 1.4rem;
      }
    }

    .active-menu-position {
      top: 2.5rem !important;
    }
    .item {
      font-size: 2rem;
    }
    .active.item {
      font-size: 2rem;
    }
    .user-details {
      min-width: 14.286rem;
      max-width: 17.857rem;
      .user-name {
        font-size: 1.571rem;
        margin-top: 1.071rem;
      }
      .user-type {
        font-size: 1.571rem;
        margin-top: -1.4286rem;
      }
    }
    .vertical-line {
      height: 3.429rem;
      margin-top: 1.071rem;
    }
    .user-profile-pic {
      transform: scale(1.8);
      margin-top: 1.571rem;
      margin-right: 1.071rem;
    }
    .dropdown-btn {
      margin-top: -0.5rem;
    }
  }
}
