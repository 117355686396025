@import 'assets/styles/_colors.scss';

.ui.modal.export-modal {
  border-radius: 0.571rem;
  .content {
    border-radius: 0.571rem;
    background: $white;
    box-shadow: 0 0.286rem 0.571rem $blue-greyish;
    border-radius: 0.571rem;

    .description {
      display: flex;
      align-items: center;

      h4 {
        margin: 0;
      }

      svg {
        color: $green;
        transform: scale(1.8);
        margin-left: 0.571rem;
      }
    }

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 1.286rem;
      line-height: 1.643rem;
      color: $blue-very-dark;
    }

    .text {
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
      color: $blue-very-dark;
      margin: 0;
      margin-top: 1.714rem;
      margin-bottom: 2.857rem;
    }

    .btn-container {
      margin-top: 2.2857rem;
      display: flex;
      justify-content: space-between;
    }
  }
}
