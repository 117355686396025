@import 'assets/styles/_colors.scss';

.editor-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  border-top: 0.0714rem solid $grayish-magenta-light;
  background-color: $gray-very-light;
  gap: 1rem;
  height: 1rem;
  position: relative;

  .preview-sidebar-menu-tab {
    display: flex;
    flex-direction: column;
    flex: 0 0 30%;
    max-width: 30%;
    background-color: $white;

    .ui.segment.active.tab {
      padding: 0 1rem !important;
    }

    &.negotiation-tab {
      max-width: 50%;
      flex: 0 0 50%;
    }
  }

  // Color of the button to open the dropdown select menu
  .preview-menu-dropdown.ui.dropdown.dropdown-menu-atom {
    div.default.text {
      color: $blue-very-dark-grayish;
    }
    &:focus svg {
      color: $blue-very-dark-grayish;
    }
  }
}

.editor-top-left {
  position: absolute;
  top: 1rem;
  left: 1rem;
  min-width: 4rem !important;
}

#provision-editor-btn-bar {
  position: absolute;
  right: 0;
  top: -3.7rem;
  margin: 0;
  display: flex;
  column-gap: 1.5rem;
}

.body {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2rem 1rem;
  gap: 1rem;
}

.bl {
  height: 28.5714rem;
  width: 28.5714rem;
}

.white-block {
  min-height: 28.5714rem;
  background-color: $white;
  border-radius: 0.5rem;
  padding: 1rem;
}

.tox .tox-tbtn__select-label {
  margin-left: 0.5rem !important;
}
