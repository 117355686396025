@import 'assets/styles/_colors.scss';

.ui.image.avatar-image,
.avatar-image-icon {
  &.outlined {
    border-width: 0.1429rem;
    border-style: solid;
    border-color: $orange;
    border-image: initial;
  }
  &.mini-size {
    width: 1.8rem;
    height: 1.8rem;
    border-width: 0.0714rem;
  }
  &.small-size {
    width: 2.4rem;
    height: 2.4rem;
    border-width: 0.0714rem;
  }
  &.medium-size {
    width: 3rem;
    height: 3rem;
  }
  &.circular {
    border-radius: 500rem;
  }
}
