@import 'assets/styles/_colors.scss';

.characteristics-box {
  display: flex;
  flex-flow: wrap;
  min-width: 10rem;
  .characteristic-inner {
    padding: 0.143rem 0.429rem;
    gap: 0.714rem;
    background: $gray-very-light;
    border-radius: 0.286rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
