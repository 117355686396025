@import 'assets/styles/_colors.scss';

.space-table-container {
  .ui.selection.dropdown {
    min-width: 11rem !important;
  }

  .contains-delete-btn {
    position: relative;

    &.no-click {
      pointer-events: none;
      img,
      svg {
        display: none;
      }
      input {
        pointer-events: none;
      }
    }
  }
}

.space-units-listing {
  &.disabled-inputs .p-datatable-tbody {
    pointer-events: none;
  }

  .ui.input > input,
  .ui.selection.dropdown {
    margin: 0;
    border: none;
    color: $blue-very-dark;
  }
  .ui.input > input {
    width: 100%;
  }
  // Note that 'p-datatable-tfoot' is a class used in the prime-react DataTable component
  // => the foot row is used for 'totals' in some columns. Sets the background of those
  //    'totals' columns in white.
  .p-datatable-tfoot {
    .total-cell {
      background: $white;
      line-height: 1.21428571em;
      padding: 1.3rem 2rem;
      font-size: 1rem;
    }
  }
  .ui.input input:focus {
    background: none;
    border: none;
    color: $blue-very-dark;
  }
  .p-datatable > .p-datatable-wrapper {
    overflow-y: visible;
    overflow-x: auto;
  }

  .form-field {
    width: inherit;
  }
  .width-full {
    width: 100%;
  }
  .p-datatable .p-dropdown {
    height: 2.5rem;
    background: 0;
    border: 0;
    .p-inputtext {
      justify-content: left;
      align-items: center;
    }
    .p-dropdown-label {
      font-weight: 400;
    }
  }
}

.floor-area {
  display: flex;
  .required {
    margin-left: 0.286rem;
    top: 0.1429rem;
  }

  .info-icon-area {
    margin-left: 0.286rem;
    margin-top: -0.1071rem;
  }
}
