@import 'assets/styles/_colors.scss';

$container-height: 2.35rem;
$bar-height: 1.5rem;
$axis-width: 0.2rem;

.ui.grid.graph-without-line.occupancy-variance {
  margin-top: 3rem;
}

.graph-tooltip-container {
  .inner-html.occupancy-variance {
    width: 100%;
    color: $blue-very-dark;

    .title {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.4285rem;
      border-bottom: 0.071rem solid $gray-light;
      padding-bottom: 1rem;
    }

    h3 {
      font-weight: 400;
      font-size: 0.8571rem;
      line-height: 140%;
      color: $blue-very-dark;
      padding: 0;
      margin: 0;
      padding-bottom: 1rem;
    }

    .revenues {
      width: 100%;
      border-bottom: 0.071rem solid $gray-light;
      font-style: normal;
      font-weight: 400;
      font-size: 0.8571rem;
      line-height: 140%;
      color: $blue-very-dark;
      padding-bottom: 1rem;
    }

    .units-list {
      margin-top: 1rem;
      max-height: 14rem;
      overflow-y: auto;
      padding: 0.1rem 0.5rem 0.1rem 0;

      &::-webkit-scrollbar {
        width: 0.429rem;
      }

      &::-webkit-scrollbar-track {
        background: $orange-light-grayish;
      }

      &::-webkit-scrollbar-thumb {
        background: $orange;
        border-radius: 0.429rem;
        height: 5.214rem;
      }

      li.revenues {
        padding-bottom: 0rem;
        border: none;
        list-style: none;

        span.bullet {
          font-weight: bolder;
          position: relative;
          top: -0.2rem;
          font-size: 1.4rem;
          margin-right: 0.5rem;
        }
      }
    }

    .item {
      margin-left: 0.6rem;
    }

    .minus {
      &::before {
        content: '-';
      }
    }

    .plus {
      &::before {
        content: '+';
      }
    }

    .red {
      color: $red;
      &::before {
        content: '';
      }
    }

    .green {
      color: $green;
    }

    .table-container {
      max-height: 16rem;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 0.429rem;
      }

      &::-webkit-scrollbar-track {
        background: $orange-light-grayish;
      }

      &::-webkit-scrollbar-thumb {
        background: $orange;
        border-radius: 0.429rem;
        height: 5.214rem;
      }

      table.ui.table.dashboard-table.occupancy-variance {
        border-bottom: none;
        border-right: none;
        border-left: none;
        margin: 0 !important;

        thead {
          th {
            position: sticky;
            top: 0;
            z-index: 2;
            background-color: $gray-very-light-v3;
          }
        }

        th,
        td {
          color: $blue-very-dark;
          font-size: 0.8571rem !important;
          line-height: 1rem;
          border: none;

          a {
            text-decoration: underline;
            font-style: italic;
          }

          &.red {
            color: $red;
          }

          &.green {
            color: $green;
          }
        }

        td {
          &.bar-cell {
            padding: 0;
            margin: 0;

            .bar-container {
              height: $container-height;
              position: relative;
              display: flex;
              align-items: center;

              .axis-line {
                position: absolute;
                height: 100%;
                border-left: $axis-width solid $gray-very-light-v6;
                left: calc(50% - ($axis-width / 2));
              }

              .bar {
                height: $bar-height;
                width: 100%;

                .green-bar,
                .red-bar {
                  height: inherit;
                }

                .red-bar {
                  background: $red;
                  border-radius: 0.2rem 0 0 0.2rem;
                  margin-left: auto;
                  margin-right: 0;
                }

                .green-bar {
                  background: $green;
                  border-radius: 0 0.2rem 0.2rem 0;
                }
              }
            }
          }

          .box {
            display: inline;
            padding: 0.286rem;
            min-width: 5.7857rem;
            background: $gray-very-light;
            border-radius: 0.286rem;
            display: flex;
            align-items: center;
            justify-content: center;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            font-style: normal;
            font-weight: 600;
            font-size: 0.8rem;
            line-height: 0.9429rem;
            color: $blue-very-dark-grayish;
            text-align: center;

            &.green {
              color: $green;
              background: $green-light-grayish-cyan-lime;
            }

            &.red {
              background: $red-very-pale;
              color: $red-soft;
            }
          }

          &.align-right {
            text-align: right;
          }
        }

        tr {
          &:first-of-type {
            td.bar-cell {
              .axis-line {
                top: calc(($container-height - $bar-height) / 2);
              }
            }
          }

          &:last-of-type {
            td.bar-cell {
              .axis-line {
                bottom: calc(($container-height - $bar-height) / 2);
              }
            }
          }
        }
      }
    }
  }
}
