@import 'assets/styles/_colors.scss';

.chatbot-container {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  gap: 0.5rem;

  &__modal {
    display: none;
    width: 600px;
    max-width: 100vw;
    height: 400px;
    border-radius: 1rem;
    border-width: 1px;
    background-color: $white;
    padding: 1rem;
    box-shadow:
      0 1px 3px 0 rgb(0 0 0 / 0.1),
      0 1px 2px -1px rgb(0 0 0 / 0.1);
    flex-direction: column;
    transition:
      width 0.5s ease-in,
      height 0.5s ease-in;

    &--isOpen {
      display: flex;
    }

    &--isExpanded {
      width: 85vw;
      height: 80vh;
    }
  }

  & .icon {
    width: 1.25rem; /* 20px */
    height: 1.25rem; /* 20px */
  }
}

.chatbot-modal-inner {
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0 1rem;
    color: $blue;

    h4 {
      font-weight: 700;
    }
  }

  &__header-action {
    display: flex;
    gap: 0.5rem;
    color: $gray-dark;

    & > div {
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    overflow: hidden;
  }

  &__action {
    margin-top: 1rem;

    & form {
      display: flex;
      gap: 0.5rem;

      & input {
        flex: 1;
        width: 100%;
        padding: 0.5rem;
        border-width: 1px;
        border-radius: 0.5rem;
        border-color: $gray-light;
        outline: none;
      }

      & button {
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        background-color: $orange;
        color: $white;
        border: none;
        cursor: pointer;
        outline: none;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

.message-list {
  flex: 1;
  overflow-y: auto;
  padding: 0.5rem;

  &__item {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    gap: 1rem;
  }

  &__date {
    display: flex;
    justify-content: center;
    color: $gray-dark;
    position: relative;
    z-index: 1;
    font-size: 0.8rem;

    &:before {
      border-top: 1px solid $gray-light;
      content: '';
      margin: 0 auto; /* this centers the line to the full width specified */
      position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
      top: calc(50% - 1px); // border height
      left: 0;
      right: 0;
      bottom: 0;
      width: 40%;
      max-width: 220px;
      z-index: -1;
    }

    span {
      background: $white;
      padding: 0 1rem;
    }
  }

  & .message-end-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    font-size: 0.8rem;
    color: $gray-dark;
  }
}

.message-item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 0.75rem;

  &:not(:first-child) {
    margin-top: 0.5rem; /* 8px */
  }

  &--bot,
  &--loading {
    justify-content: flex-start;
  }

  &__icon {
    min-width: 2rem; /* 32px */
    height: 2rem; /* 32px */
    border-radius: 50%;
    background-color: $orange;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    max-width: 70%;
    width: fit-content;
    padding: 0.5rem 1rem;
    border-radius: 1.5rem;
    background-color: $gray-lighter;

    &--loading {
      flex: unset;
      max-width: unset;
    }
  }

  & .message-item-header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 0.5rem;
    padding-bottom: 0.5rem;

    &__name {
      font-size: 1rem;
      line-height: 1.6;
      color: $violet-vivid;

      &--bot {
        color: $blue;
      }
    }

    &__time {
      font-size: 0.8rem;
      line-height: 1.6;
      color: $gray-dark;
    }
  }

  .tenancy-link {
    color: $blue-dark;
  }

  .rofo-option-content {
    .badge-container {
      background-color: $white;
    }
  }
}
