@import 'assets/styles/_colors.scss';

.card-body {
  padding: 0.4rem 0.4rem;
  min-height: 4rem;
  font-size: 1rem;
  color: $blue-desaturated-dark;
  b {
    color: $blue-very-dark-grayish;
  }
}
