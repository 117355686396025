@import 'assets/styles/_colors.scss';

.dashboard-action-menu-item {
  padding: 0.7rem 1.5rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  justify-content: flex-start;
  line-height: 1.5rem;
  color: $blue-very-dark-grayish;
  cursor: pointer;

  a {
    color: inherit;
    text-decoration: none;
  }
}

.dashboard-action-menu-item:hover {
  background: $orange-very-light-grayish;
}
