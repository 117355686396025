@import 'assets/styles/_colors.scss';
@import 'assets/styles/_metrics.scss';

.analytics-dashboard-header {
  display: flex;
  justify-content: space-between;

  .header-left {
    display: flex;
    align-items: center;

    .ui.dropdown {
      width: max-content;
      height: 4rem;
      font-weight: bold;
      font-size: 2.2857rem;
      line-height: 130%;
      font-family: inherit;
      text-align: center;
      color: $blue-very-dark;
      border-radius: 0.429rem;
      box-shadow: none;
      background-color: $white;
      display: flex;
      align-items: center;

      .down-arrow {
        position: relative;
        top: 0.1rem;
        left: 0.6rem;
        transition:
          transform 0.3s ease,
          opacity 0.1s ease;
      }

      &.active {
        .down-arrow {
          transform: rotate(180deg);
        }
      }

      .item {
        padding: 0.429rem 1.1429rem !important;
        svg {
          position: absolute;
          top: 0.429rem;
          left: 12.6643rem;
          color: $blue-very-dark-grayish !important;
        }
        .portfolio-icon {
          left: 1.55rem;
        }
        span.portfolio {
          margin-left: 1.786rem;
        }
      }

      .default.text {
        color: $blue-very-dark;
      }

      span {
        color: $blue-very-dark;
        font-style: normal;
        font-weight: 400;
        font-size: 0.8571rem;
        line-height: 140%;
        color: $blue-very-dark-grayish;

        &:nth-of-type(2) {
          margin-left: 0.8571rem;
        }
      }

      .menu {
        border-color: transparent;
        border: none;
        box-shadow: 0 0.286rem 1.1429rem $blue-greyish;
        border-radius: 0.571rem;
        margin-top: 0.0714rem;
        padding-top: 0.714rem;
        padding-bottom: 0.714rem;
        width: 100%;

        .initial-menu-icon {
          left: 5.6643rem;
        }

        .selected.item {
          background: $orange-very-light-grayish;
        }

        & > .item {
          border-top: 0;
        }

        .item:hover {
          background: $orange-very-light-grayish;
        }
      }

      &:focus {
        border: 0.0714rem solid $orange;
        svg {
          color: $orange;
        }
      }
    }
  }

  .header-right {
    display: flex;
    align-items: center;

    .ui.dropdown.export {
      width: 13.429rem;
      height: 2.143rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.8571rem 0.714rem 0.714rem 0.429rem;
      gap: 0.486rem;
      background: $blue-very-dark;
      border: 0.0714rem solid $blue-very-dark;
      border-radius: 0.286rem;
      font-weight: 600;
      font-size: 0.8571rem;
      text-transform: uppercase;
      color: $white;

      .default.text {
        color: $white;
      }

      &:focus {
        border: none;
        svg {
          color: $white;
        }
      }

      span {
        margin-left: 2.571rem;
        display: flex;
        align-items: center;

        svg {
          margin-right: 0.4rem;
        }
      }
      .menu {
        width: 15.143rem;
        margin-top: 0.571rem;
        left: -1.4286rem;
      }
    }
  }
}
