@import 'assets/styles/_colors.scss';

.upload-doc-box {
  width: 19.9rem;
  height: 7rem;
  margin-right: 0.8rem;
  text-align: center;
  background: $white;
  border: 0.071rem dashed $gray;
  border-radius: 0.857rem;
  .upload-doc-text {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 120%;
    color: $blue-very-dark-grayish;
    left: -0.5rem;
    position: relative;
    .sign-icon {
      width: 1.286rem;
      height: 1.286rem;
      color: $orange;
      position: relative;
      top: 1.7rem;
      left: -5rem;
      &[check-offer='true'] {
        left: -3.8rem;
      }
    }
    .file-name {
      left: 1.8rem;
      position: relative;
    }
  }
  .drag-drop {
    font-size: 0.857rem;
    color: $blue-very-dark-grayish;
    position: relative;
    top: 0.6rem;
  }
}

.pdf-main {
  display: flex;
  color: $blue-very-dark-grayish;
  cursor: pointer;

  .inner-text {
    position: relative;
    top: 1.2rem;
    left: 1rem;
    font-weight: 600;
  }
  .cross-icon {
    position: relative;
    top: 1.5rem;
    left: 2rem;
    width: 0.929rem;
    height: 0.929rem;
  }
  &.add-space {
    margin-top: 1rem;
  }
  &.pdf-list {
    margin-bottom: 1rem;
  }
}

.action-btns {
  .ui.button.btn {
    width: auto !important;
    &.grey-outline {
      position: absolute;
      left: 1rem;
    }
    &.grey-bg {
      position: relative;
      left: 2rem;
    }
  }
}
