@import 'assets/styles/_colors.scss';

.ui.accordion.parameter-groups-box.custom-accordion {
  width: 100%;
  padding: 0.05rem 0.05rem 0.05rem 0.5rem !important;
  .title {
    padding: 0.2em 0;
  }

  .parameters-group-questions-form {
    .title {
      padding: 0.5rem !important;
      padding-bottom: 0 !important;
    }

    .custom-accordion.parameter-questions {
      margin-top: 0.3rem;
      margin-bottom: 0.3rem;
      &.first-entry {
        margin-top: 1rem;
      }
      &.answer-exists {
        border: 0.071rem solid $red-light;
      }
    }
  }
}

.count-zero {
  position: relative;
  left: 1.5rem;
}
