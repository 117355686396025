@import 'assets/styles/_colors.scss';

.pdf-box {
  width: 3.429rem;
  height: 3.429rem;
  border-radius: 0.286rem;
  svg {
    width: 2.641rem;
    height: 2.692rem;
  }
}
