@import 'assets/styles/_colors.scss';

.parameters-questions-clear-data {
  color: $red-soft;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  display: block;
  width: fit-content;
}
