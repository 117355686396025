@import 'assets/styles/_colors.scss';

.card-header {
  display: flex;
  height: 3rem;
  align-items: center;
  padding: 0 1rem;
  background-color: $gray-very-light;
  color: $blue-very-dark;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  width: inherit;

  &.beige {
    background-color: $orange-soft;
  }

  &.lightblue {
    background-color: $blue-light-grayish;
  }

  &.lightpink {
    background-color: $magenta-light;
  }

  &.lightgreen {
    background-color: $green-lime;
  }

  &.lightgray {
    background-color: $gray-very-light;
  }

  .card-header-template {
    display: flex;
    flex: 1;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    width: inherit;

    .title-template {
      font-size: 1.143rem !important;
      text-transform: capitalize;
      font-weight: 600;
      color: $red;
      width: calc(100% - 1rem);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: initial;

      & > span {
        color: $blue-very-dark-grayish;
        padding: 0 0.25rem;
      }
    }

    & .version {
      font-size: 0.857rem;
      margin: 0.3rem 0.429rem 0 0.429rem;
      color: $gray-very-dark;
      font-weight: 600;
    }

    & > span {
      display: flex;

      align-items: center;
      height: 100%;

      & > svg {
        margin: 0 0.5rem;
      }
    }

    .card-header-icon-mandatory {
      background-color: $gray-dark;
      color: $white;
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 50%;
      text-align: center;
      margin-left: 0.2rem;
      font-weight: bold;
      display: inline-block;
      .text-m {
        position: relative;
        top: 0.1rem;
      }
    }

    .card-component-icon {
      width: 2rem;
      background-color: $green-very-soft-cyan-lime;
    }
  }

  .invisible {
    visibility: hidden;
  }
}
