@import 'assets/styles/_colors.scss';

.data-table {
  .p-paginator {
    background: $white-smoke;
    justify-content: left;

    &.p-paginator-bottom {
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }

    .p-dropdown {
      margin-left: 0;
      height: 1.7142rem;

      &:hover {
        border-color: $orange !important;
      }

      &.p-inputwrapper.p-focus {
        box-shadow: none;
        border-color: $orange;
      }
    }

    button.p-paginator-element {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.4285rem;
      border-radius: 0;
      min-width: 1.7142rem;
      height: 1.7142rem;
      color: $blue-very-dark;
      border-radius: 0.286rem;

      &:enabled:hover,
      &.p-highlight {
        background: $grayish-magenta-light;
        color: $blue-very-dark;
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem $grayish-magenta-light;
      }

      &:nth-of-type(1) {
        margin-left: auto;
      }
    }
  }
}
