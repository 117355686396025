@import 'assets/styles/_colors.scss';
@import 'assets/styles/_metrics.scss';

.floor-characteristics {
  position: relative;
  top: -6.4rem;
  .ui.selection.dropdown {
    i {
      top: 0.6rem !important;
    }
  }
}

//define height of the 'Main description' section
.section-main-description {
  height: 25rem;
}

.space-and-units-section {
  &.ui.grid.spaces-table {
    margin-top: -0.8rem !important;
  }

  .ui.input {
    font-weight: 400;
    font-size: 0.8571rem;
    line-height: 140%;
    color: $blue-very-dark;
    box-sizing: border-box;
    border-radius: 0.429rem;
    height: 2rem;
    display: flex;
    input {
      &:focus {
        background: $orange-very-light-grayish;
        border: 0.0714rem solid $orange;
        color: $blue-very-dark;
      }
    }
  }

  .ui.disabled.input {
    opacity: 1 !important;
    background: $gray-lighter !important;
    & > input {
      background: $gray-lighter !important;
    }
  }

  .param-icon {
    position: relative;
    top: -1.543rem;
    left: 0.9rem;
    &.red {
      transform: scale(1.2);
    }
  }
  .ui.grid > .row > .column {
    &.revision-date {
      position: relative;
      top: 0.6rem;
    }
    &.number-of-units-box {
      width: 4rem;
      .number-of-units {
        position: relative;
        left: -4rem;
        width: 4rem;
      }
    }
    &.checkbox-box {
      text-align: right;
    }
  }
}

.floor-name-header {
  position: relative;
  top: -4rem;
  left: 7rem;
  display: inline;
}
