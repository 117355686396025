@import 'assets/styles/_colors.scss';

.post-completion {
  overflow-y: clip;

  .d-flex {
    float: right;
  }

  .box-flex {
    margin-bottom: 1.5rem;
  }

  .doc-boxes {
    top: 3rem;
    border: 0;
    left: -2rem;
    margin-bottom: 2rem;
  }

  .list-of-boxes {
    width: 90%;

    > .row > .column.inner-column {
      width: auto;
    }
  }

  .required-indicator {
    color: $orange;
    position: relative;
    font-weight: 700;
    font-size: 1.286rem;
    top: 0.0714rem;
    margin-left: 0.2rem;
  }

  .ui.accordion.approval-accordian {
    margin-top: 0;
  }

  .upload-acc-docs {
    padding-bottom: 1rem;
  }

  .start-post-completion-btn {
    float: right;
  }

  .finalized-badge {
    margin-left: 0.7rem;
    top: 0.2rem;
    position: relative;
  }

  .post-completion-warning-badge {
    top: 0.2rem;
    position: relative;
  }
}
