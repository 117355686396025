@import 'assets/styles/_colors.scss';

.ui.dropdown.dropdown-menu-atom {
  background-color: $blue-very-dark-grayish;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.929rem 1.643rem;
  width: 17.5rem;
  height: 2.429rem;
  border-radius: 0.286rem;
  font-weight: 700;
  font-size: 0.8571rem;
  line-height: 120%;
  text-transform: uppercase;

  div.text.default {
    color: $white;
  }

  svg {
    margin-left: 1rem;
    transition:
      transform 0.1s ease,
      opacity 0.1s ease;
  }

  &:focus {
    border: none;

    svg {
      color: $white;
      transform: rotate(180deg);
    }
  }

  .menu {
    width: 100%;
    margin-top: 0.571rem;

    .item {
      &:first-of-type {
        margin-top: 0.714rem;
      }

      &:last-of-type {
        margin-bottom: 0.714rem;
      }
    }
  }
}
