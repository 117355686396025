@import 'assets/styles/_colors.scss';

.policy-regulations {
  .label-row {
    display: flex;
    justify-content: space-between;

    label {
      font-size: 0.8571rem;
      color: $blue-very-dark;
      position: relative;
      font-weight: 600;
    }
  }

  .listing {
    overflow-y: auto;
    max-height: 19.14rem;

    li {
      display: flex;
      align-items: center;
      gap: 1rem;
      position: relative;

      .field.field-style {
        width: 100%;
      }

      &:not(:last-of-type) {
        margin-bottom: 0.5rem;
      }

      * {
        margin-bottom: 0 !important;
      }

      span.sn {
        position: absolute;
      }

      .delete-icon {
        background: $white-smoke;
        padding: 0.4rem;
        cursor: pointer;
        border-radius: 0.4rem;
        display: flex;
        align-items: center;

        svg {
          transform: scale(1.3);
        }
      }
    }
  }

  .regulations-add-row {
    display: flex;
    font-size: 0.8571rem;
    cursor: pointer;
  }
}
