@import 'assets/styles/_colors.scss';

.document-body {
  background: white;
  min-height: 50rem;
  .grabbable:first-of-type {
    margin-top: 2rem;
  }
  .sequence-item {
    border-radius: 0.857rem 0.857rem 0 0;
    font-style: normal;
    font-weight: 600;
    font-size: 1.143rem;
    line-height: 1.714rem;
    color: $blue-very-dark;
    margin: 1rem 2rem 1rem 2rem;
    padding: 0.643rem 1.143rem;
    .provision-icons {
      display: inline-block;
      float: right;
      .check-icon {
        position: relative;
        left: -2.3rem;
        top: 0.1rem;
      }
    }
    .mandatory-icon {
      background-color: $blue-very-dark-grayish;
      color: $white;
      text-align: center;
      font-weight: bold;
      display: inline-block;
      font-size: 1rem;
      position: absolute;
      right: 6.2rem;
    }
    .drag-icon {
      float: right;
      color: $blue-very-dark-grayish;
      width: 1.714rem;
      height: 1.714rem;
    }
  }
}
