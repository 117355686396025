@import 'assets/styles/_colors.scss';
@import 'assets/styles/_metrics.scss';

.auth-layout {
  position: absolute;
  inset: 0;
  display: flex;

  .auth-left-panel,
  .auth-right-panel {
    width: 50%;
  }

  .auth-left-panel {
    background: $gray-blackish url('../../assets/images/png/auth-left.png') no-repeat center center;
    background-size: cover;
  }

  .auth-right-panel {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;

    .form-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
      align-items: center;
    }
  }
}
