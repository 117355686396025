/* Default button style is
 *   - height: 2.143rem;
 *   - rounded corner
 */

.ui.button.btn {
  height: 2.143rem;
  border-radius: 0.286rem;
  min-width: 13.2857rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 0.8571rem;
  line-height: 120%;
  text-transform: uppercase;

  svg {
    margin-left: 0.429rem;
    margin-right: 0.429rem;
  }

  &.invite-user {
    width: 18.286rem !important;
  }
}
