@import 'assets/styles/_colors.scss';
@import 'assets/styles/_metrics.scss';

.date-cell {
  display: flex;
  background: $gray-very-light-v4;
  border-radius: 0.286rem;
  padding: 0.429rem 0.571rem;
  justify-content: center;
  align-items: center;
  width: 7.857rem;
  height: 2.714rem;
  position: relative;
  top: 0rem;

  &[not-empty='true'] {
    width: 5.714rem;
    span {
      margin-left: -0.0714rem;
    }
  }

  &[type='read'] {
    margin-left: 0;
    span {
      margin-top: 0.0714rem;
    }
    .choose {
      left: -0.0714rem;
      .icon {
        margin-left: 3.571rem;
        top: -0.0714rem;
      }
    }
  }

  // Wrapper for date picker element
  .react-datepicker-wrapper {
    width: 50%;
    margin-left: -2.1429rem;
    top: 0.0714rem;
    position: relative;
  }

  .react-datepicker-wrapper input {
    background: transparent;
    color: $blue-very-dark-grayish;
    font-weight: 600;
  }

  .choose {
    margin-bottom: 0;
    margin-top: 0.1286rem;
    color: $gray;
    width: 100%;
    min-width: 6.7857rem;
    position: relative;
    left: -0.571rem;

    .icon {
      position: absolute;
      margin-left: 3.286rem;
    }
  }

  svg {
    position: relative;
    left: 2.2857rem;
    top: -0.0214rem;
    width: 1.1429rem;
    height: 1.1429rem;
    color: $blue-very-dark-grayish;
  }
}
