@import 'assets/styles/_colors.scss';

.ui.grid.graph-without-line {
  width: 100%;
  margin: 0;
  padding: 1.714rem;
  box-sizing: border-box;
  border-radius: 0.8571rem;

  .row {
    min-height: 15rem;
  }

  .ui.active.loader.graph-loader {
    z-index: 1;
    &::after {
      border-color: $orange transparent transparent;
    }
  }
}
