@import 'assets/styles/_colors.scss';
@import 'assets/styles/_metrics.scss';

.p-datatable {
  // Note that 'p-datatable-footer' is a class used in the prime-react DataTable component
  .p-datatable-footer {
    border: 0.125rem solid $blue-light-grayish;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-top: none;
  }

  .p-dropdown {
    .p-inputtext {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .p-datatable-footer {
    height: 4rem;
    display: flex;
    align-items: center;
  }
}
