@import 'assets/styles/_colors.scss';

.premise-floor-row {
  .col-3 {
    width: 22%;
  }
  .delete-btn {
    display: flex;
    border-radius: 0.429rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    position: absolute;
    bottom: 0.5rem;

    svg {
      transform: scale(1.3);
    }
  }
}

.superscript-btn-col {
  position: relative;

  .ui.button.superscript-btn {
    z-index: 1;
    position: absolute;
    top: 0.3rem;
    right: 0;
    padding: 0.3rem;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.286rem;
    font-weight: 500;
    font-size: 0.8571rem;
    text-transform: uppercase;
  }
}
