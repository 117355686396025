@import 'assets/styles/_colors.scss';

.table-column-toggler-menu {
  border-radius: 0.429rem;
  position: relative;

  .dropdown-list {
    right: 0;
    top: 3rem;
    position: absolute;
    z-index: 1;
    width: 16.929rem;
    padding: 0.3rem;
    background: $white;
    width: 12rem;
    box-shadow: 0 0.286rem 1.143rem $blue-greyish;
    border-radius: 0.571rem;
    max-height: 27.429rem;
    overflow: auto;

    .dropdown-label {
      font-style: normal;
      font-weight: 700;
      font-size: 0.857rem;
      line-height: 1rem;
      color: $blue-very-dark;
    }
    .ui.grid > .row {
      padding: 0.4rem 0;
    }
  }
}
