@import 'assets/styles/_colors.scss';
@import 'assets/styles/_metrics.scss';

.custom-image-input {
  .btn-photo {
    display: flex;
    align-items: center;
    margin-top: 1.1429rem;
    cursor: pointer;

    &.no-cursor {
      cursor: default;
    }

    svg {
      transform: scale(1.4);
    }

    .text {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.429rem;
      margin-left: 0.714rem;
    }
  }
}

.ui.image.circular.uploaded-photo {
  height: 11.14rem;
  width: 11.14rem;
  border-radius: 5.5rem;
  object-fit: cover;
  object-position: center;
  box-sizing: border-box;

  svg {
    width: 4.1429rem;
    height: 4.1429rem;
    position: relative;
    top: 3.286rem;
    left: 3.286rem;
  }
}
