@import 'assets/styles/_colors.scss';
#tooltip {
  .provisions-list {
    list-style: none;

    .item {
      & > div {
        display: flex;
        align-items: center;
        padding: 0.286rem;
        cursor: default;
        font-weight: 500;
        font-size: 0.8571rem;
        line-height: 1rem;
        color: $blue-very-dark;
      }

      &:not(:last-of-type) {
        margin-bottom: 1.143rem;
      }

      &:hover {
        background: $orange-light-grayish;
      }

      span {
        font-weight: 500;
        font-size: 0.8rem;
        color: $blue-very-dark;
      }
    }
  }
}
