@import 'assets/styles/_colors.scss';

.configure-tenant-modal {
  height: 42rem;
  .approval-type {
    .field.option-1 {
      margin-bottom: 0.3rem;
    }
    .option-2 {
      label {
        margin-top: -1rem;
      }
    }
  }

  .exit-btn {
    display: none;
  }

  .notes-text-box > .field {
    .pos-rel {
      margin-top: 0.45rem;
    }
    > label {
      margin-top: 0.45rem;
    }
  }

  .ui.grid > .row.approval-text-row {
    display: block;
    margin-top: 1.5rem;
    .approval-text {
      font-weight: 600;
      font-size: 0.857rem;
      color: $blue-desaturated-dark;
      padding-bottom: 0;
    }
  }

  .editor-to-html {
    overflow: auto;
    min-height: 18rem;
    max-height: 20rem;
    height: auto;
    .modification-notes {
      height: auto;
      margin-top: 1rem;
      th {
        width: 6rem;
      }
    }
  }
}

.ui.modal.modal-template {
  &.approval {
    width: 43.5rem;
    min-height: 7.5rem;
    position: relative;
  }
  &.large {
    width: 55%;

    .ui.button {
      margin: 0 0;
    }
  }
  &.configure-tenant-modal {
    textarea {
      height: 15rem;
    }
    .cover-message {
      margin-top: -1rem;
    }
  }

  .btn-row {
    gap: 1rem;
    flex-wrap: nowrap;
    justify-content: center;
  }
}
