@import 'assets/styles/_colors.scss';

.dropdown-card-menu-atom {
  .dropdown-card-menu-icon {
    vertical-align: sub;
  }

  .menu.dropdown-card-menu-options {
    width: 9.8rem;
    left: -6.857rem;

    .card-header-menu-options-checkbox input[disabled] ~ label:after {
      color: $orange;
      cursor: pointer;
    }

    .ui.disabled.checkbox label {
      opacity: 1;
    }

    .item[role='option'] {
      color: $blue-very-dark !important;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;

      &:not(:last-of-type) {
        border-bottom: 0.0714rem solid $grayish-magenta-light;
      }

      svg {
        margin-top: -0.1429rem;
        margin-right: 0.714rem;
        color: $orange;
        position: relative;

        &.bin {
          color: $red-soft;
        }

        &.second {
          top: 0.11rem;
        }

        &.third {
          top: 0.16rem;
        }
      }
    }

    hr {
      border-top: 0.0714rem solid $grayish-magenta-light;
    }
  }
}
