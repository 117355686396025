@import 'assets/styles/_colors.scss';

.duration-content-wrapper {
  display: flex;

  .duration-content-label {
    display: block;
    font-size: 0.8571rem;
    line-height: 1rem;
    padding-bottom: 0.429rem;
    padding-left: 0.1rem;
  }
}
