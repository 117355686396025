@import 'assets/styles/_colors.scss';
@import 'assets/styles/_metrics.scss';

.p-datatable {
  display: block;

  // Note that 'p-column-filter-menu' is a class used in the prime-react DataTable component
  // it corresponds to the filter button on the columns
  .p-column-filter-menu {
    margin-left: unset;
  }

  // Note that 'p-datatable-tbody' is a class used in the prime-react DataTable component
  .p-datatable-tbody {
    > tr {
      > td {
        text-align: left;
        border: 0.125rem solid $blue-light-grayish;
        height: 4rem;
        min-height: 2rem;
        padding: 0 1rem;

        > img {
          height: 4rem;
        }

        &:last-child {
          padding: 0 !important;
          margin: 0;
        }
      }
    }
  }

  .p-dropdown {
    background-color: $grayish-magenta-light;
    padding-left: 0.4rem;

    .p-dropdown-trigger {
      font-weight: 600;
      color: $blue-very-dark;
    }

    .p-dropdown-label {
      font-weight: 600;
      color: $blue-very-dark;
    }
  }
}

.p-datatable.p-datatable-scrollable {
  td.p-frozen-column {
    right: 0 !important;
    box-shadow: -2px 0px 11px -3px $gray-very-light-v7;
  }
}

tr:hover {
  background-color: $orange-light-grayish !important;
}
