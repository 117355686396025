@import 'assets/styles/_colors.scss';

.ui.dropdown.export-dropdown {
  .export-dropdown-button {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.429rem;
    color: $blue-very-dark-grayish;
    cursor: pointer;
    padding: 0.5rem;

    &:hover {
      background: $white-smoke;
      border-radius: 0.571rem;
    }

    svg.export-icon {
      position: relative;
      top: 0.214rem;
      color: inherit;
      margin-left: 0.1429rem;
    }
  }

  .visible.menu.transition {
    border: none;
    min-width: 15.143rem;
    margin-top: 0.286rem;
    box-shadow: 0 0.286rem 1.1429rem $blue-greyish;
    border-radius: 0.571rem;

    .item {
      line-height: 140%;
      font-size: 0.8571rem;

      .xlsx-option {
        display: flex;
        align-items: center;

        .xlsx-icon {
          margin-right: 1rem;
        }
      }
    }
  }

  .template-download {
    border-radius: 0.571rem;
    padding: 0.7857rem 1.1428rem;
    cursor: default;

    .datepicker-wrapper div,
    div {
      align-items: center;
    }

    .field.field-style {
      flex-grow: 0.5;

      .ui.selection.dropdown[role='listbox'] {
        top: -0.1rem;

        .visible.menu.transition {
          top: 2.7rem !important;
          left: 0;
          right: 0;

          div[role='option'].item {
            padding: 0.5rem !important;

            &:hover {
              background-color: $orange-very-light-grayish !important;
            }
          }
        }
      }
    }
  }
}
